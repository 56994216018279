import * as React from 'react';

const SvgAbstractCurves = (props) => (
  <svg viewBox="0 0 162 1070" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M154.268 19.816 157.638 3 161 19.816l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="M141.565 19.816 148.011 3l6.445 16.816-6.445 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="M122.575 19.816 132.164 3l9.597 16.816-9.597 16.816-9.589-16.816ZM97.055 19.816 109.766 3l12.703 16.816-12.703 16.816-12.71-16.816ZM64.946 19.816 80.996 3l16.059 16.816-16.059 16.816-16.05-16.816ZM7.733 19.816l-3.37 16.816L1 19.816 4.363 3l3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="M20.429 19.816 13.99 36.632 7.544 19.816 13.99 3l6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 19.816-9.59 16.816-9.596-16.816L29.837 3l9.59 16.816ZM64.946 19.816l-12.71 16.816-12.704-16.816L52.235 3l12.71 16.816ZM97.055 19.816 80.996 36.632l-16.05-16.816L80.996 3l16.059 16.816ZM154.268 52.637l3.37-16.816L161 52.637l-3.362 16.808-3.37-16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 52.637 6.446-16.816 6.446 16.816-6.446 16.808-6.446-16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 52.637 9.589-16.816 9.597 16.816-9.597 16.808-9.589-16.808ZM97.055 52.637l12.711-16.816 12.703 16.816-12.703 16.808-12.71-16.808ZM64.946 52.637l16.05-16.816 16.059 16.816-16.058 16.808-16.05-16.808ZM7.732 52.637l-3.37 16.808L1 52.637 4.362 35.82l3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="M20.429 52.637 13.99 69.445 7.545 52.637 13.99 35.82l6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 52.637-9.59 16.808-9.596-16.808 9.597-16.816 9.59 16.816ZM64.946 52.637l-12.71 16.808-12.704-16.808L52.235 35.82l12.71 16.816ZM97.055 52.637 80.997 69.445l-16.05-16.808 16.05-16.816 16.058 16.816ZM154.268 86.307l3.37-16.816L161 86.307l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 86.307 6.446-16.816 6.446 16.816-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 86.307 9.589-16.816 9.597 16.816-9.597 16.816-9.589-16.816ZM97.055 86.307l12.711-16.816 12.703 16.816-12.703 16.816-12.71-16.816ZM64.946 86.307l16.05-16.816 16.059 16.816-16.058 16.816-16.05-16.816ZM7.732 86.307l-3.37 16.816L1 86.307l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 86.307-6.439 16.816-6.445-16.816 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 86.307-9.59 16.816-9.596-16.816 9.597-16.816 9.59 16.816ZM64.946 86.307l-12.71 16.816-12.704-16.816 12.703-16.816 12.71 16.816ZM97.055 86.307l-16.058 16.816-16.05-16.816 16.05-16.816 16.058 16.816ZM154.268 119.128l3.37-16.816L161 119.128l-3.362 16.809-3.37-16.809Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 119.128 6.446-16.816 6.446 16.816-6.446 16.809-6.446-16.809Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 119.128 9.589-16.816 9.597 16.816-9.597 16.809-9.589-16.809ZM97.055 119.128l12.711-16.816 12.703 16.816-12.703 16.809-12.71-16.809ZM64.946 119.128l16.05-16.816 16.059 16.816-16.058 16.809-16.05-16.809ZM7.732 119.128l-3.37 16.809L1 119.128l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 119.128-6.439 16.809-6.445-16.809 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 119.128-9.59 16.809-9.596-16.809 9.597-16.816 9.59 16.816ZM64.946 119.128l-12.71 16.809-12.704-16.809 12.703-16.816 12.71 16.816ZM97.055 119.128l-16.058 16.809-16.05-16.809 16.05-16.816 16.058 16.816ZM154.268 152.799l3.37-16.816L161 152.799l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 152.799 6.446-16.816 6.446 16.816-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 152.799 9.589-16.816 9.597 16.816-9.597 16.816-9.589-16.816ZM97.055 152.799l12.711-16.816 12.703 16.816-12.703 16.816-12.71-16.816ZM64.946 152.799l16.05-16.816 16.059 16.816-16.058 16.816-16.05-16.816ZM7.732 152.799l-3.37 16.816L1 152.799l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 152.799-6.439 16.816-6.445-16.816 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 152.799-9.59 16.816-9.596-16.816 9.597-16.816 9.59 16.816ZM64.946 152.799l-12.71 16.816-12.704-16.816 12.703-16.816 12.71 16.816ZM97.055 152.799l-16.058 16.816-16.05-16.816 16.05-16.816 16.058 16.816ZM154.268 185.619l3.37-16.816L161 185.619l-3.362 16.817-3.37-16.817Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 185.619 6.446-16.816 6.446 16.816-6.446 16.817-6.446-16.817Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 185.619 9.589-16.816 9.597 16.816-9.597 16.817-9.589-16.817ZM97.055 185.619l12.711-16.816 12.703 16.816-12.703 16.817-12.71-16.817ZM64.946 185.619l16.05-16.816 16.059 16.816-16.058 16.817-16.05-16.817ZM7.732 185.619l-3.37 16.817L1 185.619l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 185.619-6.439 16.817-6.445-16.817 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 185.619-9.59 16.817-9.596-16.817 9.597-16.816 9.59 16.816ZM64.946 185.619l-12.71 16.817-12.704-16.817 12.703-16.816 12.71 16.816ZM97.055 185.619l-16.058 16.817-16.05-16.817 16.05-16.816 16.058 16.816ZM154.268 219.29l3.37-16.809L161 219.29l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 219.29 6.446-16.809 6.446 16.809-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 219.29 9.589-16.809 9.597 16.809-9.597 16.816-9.589-16.816ZM97.055 219.29l12.711-16.809 12.703 16.809-12.703 16.816-12.71-16.816ZM64.946 219.29l16.05-16.809 16.059 16.809-16.058 16.816-16.05-16.816ZM7.732 219.29l-3.37 16.816L1 219.29l3.362-16.809 3.37 16.809Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 219.29-6.439 16.816-6.445-16.816 6.445-16.809 6.439 16.809Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 219.29-9.59 16.816-9.596-16.816 9.597-16.809 9.59 16.809ZM64.946 219.29l-12.71 16.816-12.704-16.816 12.703-16.809 12.71 16.809ZM97.055 219.29l-16.058 16.816-16.05-16.816 16.05-16.809 16.058 16.809ZM154.268 252.111l3.37-16.816L161 252.111l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 252.111 6.446-16.816 6.446 16.816-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 252.111 9.589-16.816 9.597 16.816-9.597 16.816-9.589-16.816ZM97.055 252.111l12.711-16.816 12.703 16.816-12.703 16.816-12.71-16.816ZM64.946 252.111l16.05-16.816 16.059 16.816-16.058 16.816-16.05-16.816ZM7.732 252.111l-3.37 16.816L1 252.111l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 252.111-6.439 16.816-6.445-16.816 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 252.111-9.59 16.816-9.596-16.816 9.597-16.816 9.59 16.816ZM64.946 252.111l-12.71 16.816-12.704-16.816 12.703-16.816 12.71 16.816ZM97.055 252.111l-16.058 16.816-16.05-16.816 16.05-16.816 16.058 16.816ZM154.268 285.743l3.37-16.816L161 285.743l-3.362 16.808-3.37-16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 285.743 6.446-16.816 6.446 16.816-6.446 16.808-6.446-16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 285.743 9.589-16.816 9.597 16.816-9.597 16.808-9.589-16.808ZM97.055 285.743l12.711-16.816 12.703 16.816-12.703 16.808-12.71-16.808ZM64.946 285.743l16.05-16.816 16.059 16.816-16.058 16.808-16.05-16.808ZM7.732 285.743l-3.37 16.808L1 285.743l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 285.743-6.439 16.808-6.445-16.808 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 285.743-9.59 16.808-9.596-16.808 9.597-16.816 9.59 16.816ZM64.946 285.743l-12.71 16.808-12.704-16.808 12.703-16.816 12.71 16.816ZM97.055 285.743l-16.058 16.808-16.05-16.808 16.05-16.816 16.058 16.816ZM154.268 318.556l3.37-16.808L161 318.556l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 318.556 6.446-16.808 6.446 16.808-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 318.556 9.589-16.808 9.597 16.808-9.597 16.816-9.589-16.816ZM97.055 318.556l12.711-16.808 12.703 16.808-12.703 16.816-12.71-16.816ZM64.946 318.556l16.05-16.808 16.059 16.808-16.058 16.816-16.05-16.816ZM7.732 318.556l-3.37 16.816L1 318.556l3.362-16.808 3.37 16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 318.556-6.439 16.816-6.445-16.816 6.445-16.808 6.439 16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 318.556-9.59 16.816-9.596-16.816 9.597-16.808 9.59 16.808ZM64.946 318.556l-12.71 16.816-12.704-16.816 12.703-16.808 12.71 16.808ZM97.055 318.556l-16.058 16.816-16.05-16.816 16.05-16.808 16.058 16.808ZM154.268 352.234l3.37-16.816L161 352.234l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 352.234 6.446-16.816 6.446 16.816-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 352.234 9.589-16.816 9.597 16.816-9.597 16.816-9.589-16.816ZM97.055 352.234l12.711-16.816 12.703 16.816-12.703 16.816-12.71-16.816ZM64.946 352.234l16.05-16.816 16.059 16.816-16.058 16.816-16.05-16.816ZM7.732 352.234l-3.37 16.816L1 352.234l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="M20.429 352.234 13.99 369.05l-6.445-16.816 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 352.234-9.59 16.816-9.596-16.816 9.597-16.816 9.59 16.816ZM64.946 352.234l-12.71 16.816-12.704-16.816 12.703-16.816 12.71 16.816ZM97.055 352.234 80.997 369.05l-16.05-16.816 16.05-16.816 16.058 16.816ZM154.268 385.055l3.37-16.816L161 385.055l-3.362 16.808-3.37-16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 385.055 6.446-16.816 6.446 16.816-6.446 16.808-6.446-16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 385.055 9.589-16.816 9.597 16.816-9.597 16.808-9.589-16.808ZM97.055 385.055l12.711-16.816 12.703 16.816-12.703 16.808-12.71-16.808ZM64.946 385.055l16.05-16.816 16.059 16.816-16.058 16.808-16.05-16.808ZM7.732 385.055l-3.37 16.808L1 385.055l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 385.055-6.439 16.808-6.445-16.808 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 385.055-9.59 16.808-9.596-16.808 9.597-16.816 9.59 16.816ZM64.946 385.055l-12.71 16.808-12.704-16.808 12.703-16.816 12.71 16.816ZM97.055 385.055l-16.058 16.808-16.05-16.808 16.05-16.816 16.058 16.816ZM154.268 418.725l3.37-16.816L161 418.725l-3.362 16.817-3.37-16.817Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 418.725 6.446-16.816 6.446 16.816-6.446 16.817-6.446-16.817Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 418.725 9.589-16.816 9.597 16.816-9.597 16.817-9.589-16.817ZM97.055 418.725l12.711-16.816 12.703 16.816-12.703 16.817-12.71-16.817ZM64.946 418.725l16.05-16.816 16.059 16.816-16.058 16.817-16.05-16.817ZM7.732 418.725l-3.37 16.817L1 418.725l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 418.725-6.439 16.817-6.445-16.817 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 418.725-9.59 16.817-9.596-16.817 9.597-16.816 9.59 16.816ZM64.946 418.725l-12.71 16.817-12.704-16.817 12.703-16.816 12.71 16.816ZM97.055 418.725l-16.058 16.817-16.05-16.817 16.05-16.816 16.058 16.816ZM154.268 451.546l3.37-16.816L161 451.546l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 451.546 6.446-16.816 6.446 16.816-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 451.546 9.589-16.816 9.597 16.816-9.597 16.816-9.589-16.816ZM97.055 451.546l12.711-16.816 12.703 16.816-12.703 16.816-12.71-16.816ZM64.946 451.546l16.05-16.816 16.059 16.816-16.058 16.816-16.05-16.816ZM7.732 451.546l-3.37 16.816L1 451.546l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 451.546-6.439 16.816-6.445-16.816 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 451.546-9.59 16.816-9.596-16.816 9.597-16.816 9.59 16.816ZM64.946 451.546l-12.71 16.816-12.704-16.816 12.703-16.816 12.71 16.816ZM97.055 451.546l-16.058 16.816-16.05-16.816 16.05-16.816 16.058 16.816ZM154.268 485.217l3.37-16.809L161 485.217l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 485.217 6.446-16.809 6.446 16.809-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 485.217 9.589-16.809 9.597 16.809-9.597 16.816-9.589-16.816ZM97.055 485.217l12.711-16.809 12.703 16.809-12.703 16.816-12.71-16.816ZM64.946 485.217l16.05-16.809 16.059 16.809-16.058 16.816-16.05-16.816ZM7.732 485.217l-3.37 16.816L1 485.217l3.362-16.809 3.37 16.809Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 485.217-6.439 16.816-6.445-16.816 6.445-16.809 6.439 16.809Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 485.217-9.59 16.816-9.596-16.816 9.597-16.809 9.59 16.809ZM64.946 485.217l-12.71 16.816-12.704-16.816 12.703-16.809 12.71 16.809ZM97.055 485.217l-16.058 16.816-16.05-16.816 16.05-16.809 16.058 16.809ZM154.268 518.038l3.37-16.816L161 518.038l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 518.038 6.446-16.816 6.446 16.816-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 518.038 9.589-16.816 9.597 16.816-9.597 16.816-9.589-16.816ZM97.055 518.038l12.711-16.816 12.703 16.816-12.703 16.816-12.71-16.816ZM64.946 518.038l16.05-16.816 16.059 16.816-16.058 16.816-16.05-16.816ZM7.732 518.038l-3.37 16.816L1 518.038l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 518.038-6.439 16.816-6.445-16.816 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 518.038-9.59 16.816-9.596-16.816 9.597-16.816 9.59 16.816ZM64.946 518.038l-12.71 16.816-12.704-16.816 12.703-16.816 12.71 16.816ZM97.055 518.038l-16.058 16.816-16.05-16.816 16.05-16.816 16.058 16.816ZM154.268 551.708l3.37-16.808L161 551.708l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 551.708 6.446-16.808 6.446 16.808-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 551.708 9.589-16.808 9.597 16.808-9.597 16.816-9.589-16.816ZM97.055 551.708l12.711-16.808 12.703 16.808-12.703 16.816-12.71-16.816ZM64.946 551.708l16.05-16.808 16.059 16.808-16.058 16.816-16.05-16.816ZM7.732 551.708l-3.37 16.816L1 551.708 4.362 534.9l3.37 16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 551.708-6.439 16.816-6.445-16.816L13.99 534.9l6.439 16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 551.708-9.59 16.816-9.596-16.816 9.597-16.808 9.59 16.808ZM64.946 551.708l-12.71 16.816-12.704-16.816L52.235 534.9l12.71 16.808ZM97.055 551.708l-16.058 16.816-16.05-16.816 16.05-16.808 16.058 16.808ZM154.268 584.529l3.37-16.809L161 584.529l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 584.529 6.446-16.809 6.446 16.809-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 584.529 9.589-16.809 9.597 16.809-9.597 16.816-9.589-16.816ZM97.055 584.529l12.711-16.809 12.703 16.809-12.703 16.816-12.71-16.816ZM64.946 584.529l16.05-16.809 16.059 16.809-16.058 16.816-16.05-16.816ZM7.732 584.529l-3.37 16.816L1 584.529l3.362-16.809 3.37 16.809Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 584.529-6.439 16.816-6.445-16.816 6.445-16.809 6.439 16.809Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 584.529-9.59 16.816-9.596-16.816 9.597-16.809 9.59 16.809ZM64.946 584.529l-12.71 16.816-12.704-16.816 12.703-16.809 12.71 16.809ZM97.055 584.529l-16.058 16.816-16.05-16.816 16.05-16.809 16.058 16.809ZM154.268 618.207l3.37-16.816L161 618.207l-3.362 16.808-3.37-16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 618.207 6.446-16.816 6.446 16.816-6.446 16.808-6.446-16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 618.207 9.589-16.816 9.597 16.816-9.597 16.808-9.589-16.808ZM97.055 618.207l12.711-16.816 12.703 16.816-12.703 16.808-12.71-16.808ZM64.946 618.207l16.05-16.816 16.059 16.816-16.058 16.808-16.05-16.808ZM7.732 618.207l-3.37 16.808L1 618.207l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 618.207-6.439 16.808-6.445-16.808 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 618.207-9.59 16.808-9.596-16.808 9.597-16.816 9.59 16.816ZM64.946 618.207l-12.71 16.808-12.704-16.808 12.703-16.816 12.71 16.816ZM97.055 618.207l-16.058 16.808-16.05-16.808 16.05-16.816 16.058 16.816ZM154.268 651.02l3.37-16.808L161 651.02l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 651.02 6.446-16.808 6.446 16.808-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 651.02 9.589-16.808 9.597 16.808-9.597 16.816-9.589-16.816ZM97.055 651.02l12.711-16.808 12.703 16.808-12.703 16.816-12.71-16.816ZM64.946 651.02l16.05-16.808 16.059 16.808-16.058 16.816-16.05-16.816ZM7.732 651.02l-3.37 16.816L1 651.02l3.362-16.808 3.37 16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 651.02-6.439 16.816-6.445-16.816 6.445-16.808 6.439 16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 651.02-9.59 16.816-9.596-16.816 9.597-16.808 9.59 16.808ZM64.946 651.02l-12.71 16.816-12.704-16.816 12.703-16.808 12.71 16.808ZM97.055 651.02l-16.058 16.816-16.05-16.816 16.05-16.808 16.058 16.808ZM154.268 684.698l3.37-16.816L161 684.698l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 684.698 6.446-16.816 6.446 16.816-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 684.698 9.589-16.816 9.597 16.816-9.597 16.816-9.589-16.816ZM97.055 684.698l12.711-16.816 12.703 16.816-12.703 16.816-12.71-16.816ZM64.946 684.698l16.05-16.816 16.059 16.816-16.058 16.816-16.05-16.816ZM7.732 684.698l-3.37 16.816L1 684.698l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 684.698-6.439 16.816-6.445-16.816 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 684.698-9.59 16.816-9.596-16.816 9.597-16.816 9.59 16.816ZM64.946 684.698l-12.71 16.816-12.704-16.816 12.703-16.816 12.71 16.816ZM97.055 684.698l-16.058 16.816-16.05-16.816 16.05-16.816 16.058 16.816ZM154.268 717.519l3.37-16.816L161 717.519l-3.362 16.809-3.37-16.809Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 717.519 6.446-16.816 6.446 16.816-6.446 16.809-6.446-16.809Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 717.519 9.589-16.816 9.597 16.816-9.597 16.809-9.589-16.809ZM97.055 717.519l12.711-16.816 12.703 16.816-12.703 16.809-12.71-16.809ZM64.946 717.519l16.05-16.816 16.059 16.816-16.058 16.809-16.05-16.809ZM7.732 717.519l-3.37 16.809L1 717.519l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 717.519-6.439 16.809-6.445-16.809 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 717.519-9.59 16.809-9.596-16.809 9.597-16.816 9.59 16.816ZM64.946 717.519l-12.71 16.809-12.704-16.809 12.703-16.816 12.71 16.816ZM97.055 717.519l-16.058 16.809-16.05-16.809 16.05-16.816 16.058 16.816ZM154.268 751.19l3.37-16.816L161 751.19l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 751.19 6.446-16.816 6.446 16.816-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 751.19 9.589-16.816 9.597 16.816-9.597 16.816-9.589-16.816ZM97.055 751.19l12.711-16.816 12.703 16.816-12.703 16.816-12.71-16.816ZM64.946 751.19l16.05-16.816 16.059 16.816-16.058 16.816-16.05-16.816ZM7.732 751.19l-3.37 16.816L1 751.19l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 751.19-6.439 16.816-6.445-16.816 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 751.19-9.59 16.816-9.596-16.816 9.597-16.816 9.59 16.816ZM64.946 751.19l-12.71 16.816-12.704-16.816 12.703-16.816 12.71 16.816ZM97.055 751.19l-16.058 16.816-16.05-16.816 16.05-16.816 16.058 16.816ZM154.268 784.01l3.37-16.816L161 784.01l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 784.01 6.446-16.816 6.446 16.816-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 784.01 9.589-16.816 9.597 16.816-9.597 16.816-9.589-16.816ZM97.055 784.01l12.711-16.816 12.703 16.816-12.703 16.816-12.71-16.816ZM64.946 784.01l16.05-16.816 16.059 16.816-16.058 16.816-16.05-16.816ZM7.732 784.01l-3.37 16.816L1 784.01l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 784.01-6.439 16.816-6.445-16.816 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 784.01-9.59 16.816-9.596-16.816 9.597-16.816 9.59 16.816ZM64.946 784.01l-12.71 16.816-12.704-16.816 12.703-16.816 12.71 16.816ZM97.055 784.01l-16.058 16.816-16.05-16.816 16.05-16.816 16.058 16.816ZM154.268 817.635l3.37-16.809L161 817.635l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 817.635 6.446-16.809 6.446 16.809-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 817.635 9.589-16.809 9.597 16.809-9.597 16.816-9.589-16.816ZM97.055 817.635l12.711-16.809 12.703 16.809-12.703 16.816-12.71-16.816ZM64.946 817.635l16.05-16.809 16.059 16.809-16.058 16.816-16.05-16.816ZM7.732 817.635l-3.37 16.816L1 817.635l3.362-16.809 3.37 16.809Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 817.635-6.439 16.816-6.445-16.816 6.445-16.809 6.439 16.809Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 817.635-9.59 16.816-9.596-16.816 9.597-16.809 9.59 16.809ZM64.946 817.635l-12.71 16.816-12.704-16.816 12.703-16.809 12.71 16.809ZM97.055 817.635l-16.058 16.816-16.05-16.816 16.05-16.809 16.058 16.809ZM154.268 850.456l3.37-16.816L161 850.456l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 850.456 6.446-16.816 6.446 16.816-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 850.456 9.589-16.816 9.597 16.816-9.597 16.816-9.589-16.816ZM97.055 850.456l12.711-16.816 12.703 16.816-12.703 16.816-12.71-16.816ZM64.946 850.456l16.05-16.816 16.059 16.816-16.058 16.816-16.05-16.816ZM7.732 850.456l-3.37 16.816L1 850.456l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 850.456-6.439 16.816-6.445-16.816 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 850.456-9.59 16.816-9.596-16.816 9.597-16.816 9.59 16.816ZM64.946 850.456l-12.71 16.816-12.704-16.816 12.703-16.816 12.71 16.816ZM97.055 850.456l-16.058 16.816-16.05-16.816 16.05-16.816 16.058 16.816ZM154.268 884.134l3.37-16.816L161 884.134l-3.362 16.808-3.37-16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 884.134 6.446-16.816 6.446 16.816-6.446 16.808-6.446-16.808Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 884.134 9.589-16.816 9.597 16.816-9.597 16.808-9.589-16.808ZM97.055 884.134l12.711-16.816 12.703 16.816-12.703 16.808-12.71-16.808ZM64.946 884.134l16.05-16.816 16.059 16.816-16.058 16.808-16.05-16.808ZM7.732 884.134l-3.37 16.808L1 884.134l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 884.134-6.439 16.808-6.445-16.808 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 884.134-9.59 16.808-9.596-16.808 9.597-16.816 9.59 16.816ZM64.946 884.134l-12.71 16.808-12.704-16.808 12.703-16.816 12.71 16.816ZM97.055 884.134l-16.058 16.808-16.05-16.808 16.05-16.816 16.058 16.816ZM154.268 916.947l3.37-16.809L161 916.947l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 916.947 6.446-16.809 6.446 16.809-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 916.947 9.589-16.809 9.597 16.809-9.597 16.816-9.589-16.816ZM97.055 916.947l12.711-16.809 12.703 16.809-12.703 16.816-12.71-16.816ZM64.946 916.947l16.05-16.809 16.059 16.809-16.058 16.816-16.05-16.816ZM7.732 916.947l-3.37 16.816L1 916.947l3.362-16.809 3.37 16.809Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 916.947-6.439 16.816-6.445-16.816 6.445-16.809 6.439 16.809Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 916.947-9.59 16.816-9.596-16.816 9.597-16.809 9.59 16.809ZM64.946 916.947l-12.71 16.816-12.704-16.816 12.703-16.809 12.71 16.809ZM97.055 916.947l-16.058 16.816-16.05-16.816 16.05-16.809 16.058 16.809ZM154.268 950.625l3.37-16.816L161 950.625l-3.362 16.816-3.37-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 950.625 6.446-16.816 6.446 16.816-6.446 16.816-6.446-16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 950.625 9.589-16.816 9.597 16.816-9.597 16.816-9.589-16.816ZM97.055 950.625l12.711-16.816 12.703 16.816-12.703 16.816-12.71-16.816ZM64.946 950.625l16.05-16.816 16.059 16.816-16.058 16.816-16.05-16.816ZM7.732 950.625l-3.37 16.816L1 950.625l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 950.625-6.439 16.816-6.445-16.816 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 950.625-9.59 16.816-9.596-16.816 9.597-16.816 9.59 16.816ZM64.946 950.625l-12.71 16.816-12.704-16.816 12.703-16.816 12.71 16.816ZM97.055 950.625l-16.058 16.816-16.05-16.816 16.05-16.816 16.058 16.816ZM154.268 983.446l3.37-16.816L161 983.446l-3.362 16.804-3.37-16.804Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 983.446 6.446-16.816 6.446 16.816-6.446 16.804-6.446-16.804Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 983.446 9.589-16.816 9.597 16.816-9.597 16.804-9.589-16.804ZM97.055 983.446l12.711-16.816 12.703 16.816-12.703 16.804-12.71-16.804ZM64.946 983.446l16.05-16.816 16.059 16.816-16.058 16.804-16.05-16.804ZM7.732 983.446l-3.37 16.804L1 983.446l3.362-16.816 3.37 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 983.446-6.439 16.804-6.445-16.804 6.445-16.816 6.439 16.816Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 983.446-9.59 16.804-9.596-16.804 9.597-16.816 9.59 16.816ZM64.946 983.446l-12.71 16.804-12.704-16.804 12.703-16.816 12.71 16.816ZM97.055 983.446l-16.058 16.804-16.05-16.804 16.05-16.816 16.058 16.816ZM154.268 1017.12l3.37-16.82 3.362 16.82-3.362 16.81-3.37-16.81Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 1017.12 6.446-16.82 6.446 16.82-6.446 16.81-6.446-16.81Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 1017.12 9.589-16.82 9.597 16.82-9.597 16.81-9.589-16.81ZM97.055 1017.12l12.711-16.82 12.703 16.82-12.703 16.81-12.71-16.81ZM64.946 1017.12l16.05-16.82 16.059 16.82-16.058 16.81-16.05-16.81ZM7.732 1017.12l-3.37 16.81L1 1017.12l3.362-16.82 3.37 16.82Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 1017.12-6.439 16.81-6.445-16.81 6.445-16.82 6.439 16.82Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 1017.12-9.59 16.81-9.596-16.81 9.597-16.82 9.59 16.82ZM64.946 1017.12l-12.71 16.81-12.704-16.81 12.703-16.82 12.71 16.82ZM97.055 1017.12l-16.058 16.81-16.05-16.81 16.05-16.82 16.058 16.82ZM154.268 1049.94l3.37-16.82 3.362 16.82-3.362 16.81-3.37-16.81Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m141.565 1049.94 6.446-16.82 6.446 16.82-6.446 16.81-6.446-16.81Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m122.575 1049.94 9.589-16.82 9.597 16.82-9.597 16.81-9.589-16.81ZM97.055 1049.94l12.711-16.82 12.703 16.82-12.703 16.81-12.71-16.81ZM64.946 1049.94l16.05-16.82 16.059 16.82-16.058 16.81-16.05-16.81ZM7.732 1049.94l-3.37 16.81L1 1049.94l3.362-16.82 3.37 16.82Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m20.429 1049.94-6.439 16.81-6.445-16.81 6.445-16.82 6.439 16.82Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="m39.426 1049.94-9.59 16.81-9.596-16.81 9.597-16.82 9.59 16.82ZM64.946 1049.94l-12.71 16.81-12.704-16.81 12.703-16.82 12.71 16.82ZM97.055 1049.94l-16.058 16.81-16.05-16.81 16.05-16.82 16.058 16.82Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
  </svg>
);

export default SvgAbstractCurves;
