import * as React from 'react';

const Svg99 = (props) => (
  <svg viewBox="0 0 264 264" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="#fff" d="M0 0h263.49v263.49H0z" />
    <g
      style={{
        mixBlendMode: 'luminosity'
      }}>
      <path
        d="M162.08 81h-60.16C90.366 81 81 90.366 81 101.92v60.16c0 11.554 9.366 20.92 20.92 20.92h60.16c11.554 0 20.92-9.366 20.92-20.92v-60.16C183 90.366 173.634 81 162.08 81Z"
        fill="#000"
      />
      <path
        d="M130.694 124.187a16.32 16.32 0 1 0-32.64 0v.582a16.317 16.317 0 0 0 12.597 15.942l-9.057 13.831a.676.676 0 0 0 .561 1.02h11.138a.82.82 0 0 0 .673-.367l.367-.561 13.668-20.828a16.315 16.315 0 0 0 2.703-9.037v-.582h-.01Zm-11.108 2.897-.459.694a5.988 5.988 0 0 1-10.771-3.581 5.986 5.986 0 0 1 5.988-5.987 5.986 5.986 0 0 1 5.987 5.987 5.905 5.905 0 0 1-.745 2.887ZM165.997 124.187a16.324 16.324 0 0 0-16.32-16.32 16.32 16.32 0 0 0-16.32 16.32v.582a16.316 16.316 0 0 0 12.607 15.942l-9.068 13.831a.694.694 0 0 0-.089.333.672.672 0 0 0 .085.334.674.674 0 0 0 .565.353h11.139a.805.805 0 0 0 .673-.367l.367-.561 13.678-20.828a16.323 16.323 0 0 0 2.703-9.037v-.582h-.02Zm-11.098 2.897-.459.694a5.99 5.99 0 0 1-6.672 2.081 5.987 5.987 0 0 1-4.099-5.662 5.986 5.986 0 0 1 5.987-5.987 5.988 5.988 0 0 1 5.988 5.987 6.01 6.01 0 0 1-.745 2.887Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default Svg99;
