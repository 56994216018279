import * as React from 'react';

const SvgPier = (props) => (
  <svg viewBox="0 0 264 264" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="#fff" d="M0 0h263.49v263.49H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M197.895 150.149c-1.254-1.232-1.878-2.792-1.878-4.682 0-1.926.617-3.487 1.851-4.682 1.234-1.196 2.78-1.793 4.641-1.793 1.897 0 3.453.597 4.67 1.793 1.214 1.195 1.821 2.756 1.821 4.682 0 1.887-.617 3.448-1.851 4.682-1.234 1.233-2.78 1.851-4.64 1.851-1.824 0-3.363-.618-4.614-1.851Zm-19.656-28.472c-1.18-1.044-2.932-1.565-5.258-1.565h-6.285v11.89h6.285c2.323 0 4.076-.512 5.258-1.537 1.18-1.025 1.772-2.484 1.772-4.378 0-1.897-.59-3.365-1.772-4.41Zm-5.358 18.971h-6.182v10.809h-11.223V111h18.137c3.591 0 6.708.606 9.353 1.821 2.644 1.213 4.684 2.949 6.122 5.201 1.436 2.255 2.153 4.904 2.153 7.948 0 2.929-.671 5.481-2.013 7.658-1.342 2.177-3.259 3.882-5.754 5.116l8.559 12.715h-11.884l-7.268-10.811Zm-24.575 2.009v8.802h-31.955V111h31.331v8.956h-20.109v6.793h17.614v8.494h-17.614v7.412h20.733v.002ZM96.087 111h11.223v40.457H96.087V111Zm-19.322 19.465c1.18-1.025 1.773-2.484 1.773-4.378 0-1.894-.59-3.365-1.773-4.407-1.18-1.045-2.932-1.566-5.257-1.566h-6.285v11.89h6.285c2.323-.002 4.075-.514 5.257-1.539Zm4.919-17.639c2.664 1.218 4.719 2.956 6.164 5.216 1.445 2.26 2.168 4.916 2.168 7.967 0 3.051-.723 5.698-2.168 7.938-1.445 2.24-3.5 3.969-6.164 5.187-2.665 1.217-5.803 1.826-9.42 1.826h-7.041v10.499H54V111h18.265c3.616 0 6.754.609 9.419 1.826Z"
      fill="#000"
    />
  </svg>
);

export default SvgPier;
