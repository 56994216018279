import * as React from 'react';

const SvgQuote = (props) => (
  <svg viewBox="0 0 65 59" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 0v28.305h12.082c0 4.534-.855 8.394-2.563 11.58C7.81 42.948 4.637 45.276 0 46.869V59c3.783-.49 7.261-1.593 10.434-3.308 3.295-1.838 6.102-4.105 8.42-6.801 2.32-2.696 4.089-5.82 5.31-9.374 1.342-3.553 1.952-7.352 1.83-11.396V0H0Zm38.99 0v28.305h12.082c0 4.534-.854 8.394-2.562 11.58-1.709 3.063-4.882 5.391-9.52 6.984V59c3.784-.49 7.262-1.593 10.435-3.308 3.295-1.838 6.102-4.105 8.42-6.801 2.32-2.696 4.089-5.82 5.31-9.374 1.342-3.553 1.952-7.352 1.83-11.396V0H38.99Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default SvgQuote;
