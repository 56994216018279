import React from 'react';

import { Close } from 'components/icons';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule Modal
 * 
 * Modal
 */
function Modal (props) {
  const {
    className = "",
    dark = false,
    onClick,
    children,
    ...other
  } = props;

  return <div
    className={`${css["molecule__modal-container"]} ${className}`}
    {...other}
  >
    <div className={css["content"]} data-theme={dark}>
      <button onClick={onClick} title="Fechar" className={css["btn"]}>
        <Close/>
      </button>
      <div className={css["info"]}>
        {children}
      </div>
    </div>
  </div>;
}

export default Modal;
