import * as React from 'react';

const SvgEyxoPlus = (props) => (
  <svg viewBox="0 0 130 132" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m122.137 126.255.043 5.67h-5.75v-5.67h-5.17v-5.501h5.17v-5.67h5.707v5.67h5.166v5.501h-5.166ZM65.789 83.355a17.577 17.577 0 1 1-26.26 1.588L26.254 71.666 4.025 93.896l-2.989-2.988 22.229-22.23L1 46.407l2.988-2.988 22.264 22.27L44.94 47.003 29.42 31.484a17.577 17.577 0 1 1 2.989-2.988l15.52 15.526L67.95 23.993l2.988 2.992-41.698 41.693 13.277 13.277a17.576 17.576 0 0 1 23.271 1.4ZM26.406 28.47 7.763 9.825A13.352 13.352 0 0 0 26.406 28.47ZM28.02 8.212a13.354 13.354 0 0 0-17.27-1.375L29.396 25.48A13.352 13.352 0 0 0 28.02 8.212ZM62.8 86.344a13.353 13.353 0 1 0-18.883 18.884A13.353 13.353 0 0 0 62.8 86.344Z"
      fill="#CBCFCE"
    />
  </svg>
);

export default SvgEyxoPlus;
