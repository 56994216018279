import * as React from 'react';

const SvgLinkedin = (props) => (
  <svg viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 5.848A5.848 5.848 0 0 1 5.848 0h58.297A5.845 5.845 0 0 1 70 5.848v58.297A5.849 5.849 0 0 1 64.149 70h-58.3A5.848 5.848 0 0 1 0 64.149v-58.3ZM27.707 26.69h9.479v4.76c1.368-2.736 4.868-5.199 10.128-5.199 10.083 0 12.472 5.45 12.472 15.45v18.525H49.582V43.98c0-5.695-1.368-8.909-4.843-8.909-4.82 0-6.825 3.465-6.825 8.91v16.245H27.707V26.69Zm-17.5 33.1h10.207V26.25H10.207v33.54Zm11.668-44.478a6.566 6.566 0 0 1-11.255 4.735 6.564 6.564 0 1 1 11.255-4.735Z"
      fill="#CBCFCE"
    />
  </svg>
);

export default SvgLinkedin;
