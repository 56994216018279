import * as React from 'react';

const SvgTiktok = (props) => (
  <svg viewBox="0 0 264 264" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="#fff" d="M0 0h263.49v263.49H0z" />
    <path
      d="M151.869 94.054a15.638 15.638 0 0 1-5.24-5.628A15.509 15.509 0 0 1 144.724 81h-11.281l-.019 44.867a9.36 9.36 0 0 1-1.771 5.125 9.469 9.469 0 0 1-4.325 3.305 9.55 9.55 0 0 1-5.444.388 9.5 9.5 0 0 1-4.756-2.658 9.386 9.386 0 0 1-2.49-4.821 9.343 9.343 0 0 1 .603-5.385 9.415 9.415 0 0 1 3.495-4.161 9.52 9.52 0 0 1 5.227-1.558c.949.004 1.893.15 2.797.435v-11.429a20.762 20.762 0 0 0-2.797-.201c-11.447 0-20.758 9.242-20.758 20.606a20.561 20.561 0 0 0 6.089 14.562 20.865 20.865 0 0 0 14.669 6.045c11.446 0 20.757-9.242 20.757-20.607v-22.751a26.956 26.956 0 0 0 15.693 5.012V96.575a15.683 15.683 0 0 1-8.544-2.521ZM79 158.756h18.17l-1.668 5.161h-4.71v19.081H84.92v-19.083l-5.915.011-.004-5.17ZM126.938 158.756h18.591l-1.668 5.161h-5.126v19.081h-5.883v-19.083l-5.914.011v-5.17ZM98.279 166.466h5.827l-.007 16.53h-5.785l-.035-16.53ZM106.416 158.688h5.819v11.289l5.766-5.618h6.948l-7.295 7.028 8.171 11.608h-6.412l-5.453-8.045-1.725 1.663V183h-5.819v-24.312ZM165.589 158.688h5.819v11.289l5.767-5.618h6.95l-7.296 7.028L185 182.995h-6.412l-5.453-8.045-1.725 1.663V183h-5.819l-.002-24.312ZM101.19 164.577c1.619 0 2.932-1.303 2.932-2.911 0-1.607-1.313-2.91-2.932-2.91-1.62 0-2.932 1.303-2.932 2.91a2.921 2.921 0 0 0 2.932 2.911ZM153.256 163.206c-1.971 0-3.898.58-5.537 1.668a9.9 9.9 0 0 0-3.671 4.44 9.825 9.825 0 0 0-.568 5.716 9.87 9.87 0 0 0 2.728 5.066 9.991 9.991 0 0 0 5.103 2.708c1.934.382 3.938.186 5.759-.563a9.953 9.953 0 0 0 4.473-3.644 9.844 9.844 0 0 0 1.68-5.497 9.857 9.857 0 0 0-2.919-6.996 10.005 10.005 0 0 0-7.048-2.898Zm0 14.704a4.876 4.876 0 0 1-2.692-.81 4.822 4.822 0 0 1-1.785-2.159 4.783 4.783 0 0 1 1.051-5.242 4.873 4.873 0 0 1 5.28-1.043 4.84 4.84 0 0 1 2.175 1.772 4.788 4.788 0 0 1-.603 6.073 4.86 4.86 0 0 1-3.426 1.409Z"
      fill="#000"
    />
  </svg>
);

export default SvgTiktok;
