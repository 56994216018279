/* eslint-disable max-len */
import React from 'react';
import App from 'next/app';
import Script from 'next/script';
import getConfig from 'next/config';
import { ToastContainer } from 'react-toastify';

import { ParallaxProvider } from 'react-scroll-parallax';

import Layout from 'utils/hocs/layout';
import withData from 'utils/apollo';
import packageJson from 'package.json';

import 'utils/styles/_reset.scss';
import 'utils/styles/_fonts.scss';

import 'react-toastify/dist/ReactToastify.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const { publicRuntimeConfig } = getConfig();

console.log(
  '%cViewer version: v' + packageJson.version,
  'color: blue; font-size: 20px; background-color: white;'
);

class TheApp extends App {
  state = {
    readyForAds: false
  };

  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        {/*
          https://nextjs.org/docs/messages/next-script-for-ga
          https://nextjs.org/docs/messages/no-script-in-document-page
          https://nextjs.org/docs/basic-features/script#strategy
        */}
        <Script id="google-analytics" strategy="afterInteractive">
          {`(function(w,d,s,l,i){
            w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${publicRuntimeConfig.GA_TRACKING_ID}');`}
        </Script>
        <iframe
          title="GTM"
          src={`https://www.googletagmanager.com/ns.html?id=${publicRuntimeConfig.GA_TRACKING_ID}`}
          height="0"
          width="0"
          style={{display:'none', visibility:'hidden'}}
        />
        <ParallaxProvider>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </ParallaxProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          style={{ fontFamily: "AdobeCaslonPro", fontSize: '1.6rem' }}
        />
      </>
    );
  }
}

// Wraps all components in the tree with the data provider
export default withData(TheApp);
