import * as React from 'react';

const SvgInstagram = (props) => (
  <svg viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M47.481 35.5A11.931 11.931 0 0 0 35.5 23.519 11.931 11.931 0 0 0 23.519 35.5 11.931 11.931 0 0 0 35.5 47.481 11.932 11.932 0 0 0 47.481 35.5Zm6.213 0c0 10.206-7.988 18.194-18.194 18.194S17.306 45.706 17.306 35.5 25.294 17.306 35.5 17.306 53.694 25.294 53.694 35.5Zm4.881-19.081c0 2.662-1.775 4.437-4.438 4.437-2.662 0-4.437-1.775-4.437-4.437 0-2.663 1.775-4.438 4.438-4.438 2.662 0 4.437 2.219 4.437 4.438ZM35.5 6.212c-5.325 0-16.419-.443-20.856 1.332-3.107 1.331-5.769 3.994-6.657 7.1-1.774 4.437-1.33 15.531-1.33 20.856 0 5.325-.444 16.419 1.33 20.856.888 3.106 3.55 5.769 6.657 6.657 4.437 1.774 15.975 1.33 20.856 1.33 4.881 0 16.419.444 20.856-1.33 3.106-1.332 5.325-3.55 6.657-6.657 1.774-4.881 1.33-15.975 1.33-20.856 0-4.881.444-16.419-1.33-20.856-.888-3.107-3.55-5.769-6.657-6.657C51.92 5.77 40.825 6.213 35.5 6.213ZM71 35.5v14.644c0 5.325-1.775 10.65-5.769 15.087C61.238 69.225 55.913 71 50.144 71H20.856c-5.325 0-10.65-1.775-15.087-5.769C2.219 61.238 0 55.913 0 50.144V20.856C0 15.087 2.219 9.762 5.769 5.77 10.206 2.219 15.53 0 20.856 0h29.288c5.325 0 10.65 1.775 15.087 5.769C68.781 9.762 71 15.088 71 20.856V35.5Z"
      fill="#CBCFCE"
    />
  </svg>
);

export default SvgInstagram;
