import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom MenuButton
 * 
 * <!-- TODO: add a description here! -->
 */
function MenuButton (props) {
  const {
    className = "",
    onClick,
    isOpen = false,
    ...other
  } = props;

  return <button
    className={`${css["atom__menu-button-container"]} ${className}`}
    title={isOpen ? 'Fechar menu' : 'Abrir menu'}
    onClick={onClick}
    data-menu={isOpen}
    {...other}
  >
    <span />
    <span />
    <span />
  </button>;
}

export default MenuButton;
