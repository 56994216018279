import React from 'react';

import Typography from 'components/atoms/typography';
import Button from 'components/atoms/button';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule Cookies
 * 
 * Information about cookies
 */
function Cookies (props) {
  const {
    className = "",
    areCookiesConsented = false,
    handleAcceptCookies,
    ...other
  } = props;

  return <div
    className={`${css["molecule__cookies-container"]} ${className}`}
    data-visible={areCookiesConsented}
    {...other}
  >
    <Typography className={css["desc"]}>
      Este website utiliza cookies a fim de oferecer à você uma melhor
      experiência de navegação, otimizando a usabilidade da nossa plataforma.
      Ao seguir navegando por aqui, entendemos que você está de acordo.
      Para mais informações, consulte a nossa Política de Privacidade.
    </Typography>
    <Button
      rounded
      alt="Aceitar e Fechar"
      onClick={handleAcceptCookies}
      className={css["btn"]}
      textColor="gray"
    >
      Aceitar e Fechar
    </Button>
  </div>;
}

export default Cookies;
