import * as React from 'react';

const SvgPlayButtonGroup = (props) => (
  <svg viewBox="0 0 167 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m7.825 21.588 13.77-13.764A48.56 48.56 0 0 0 7.84 21.588h-.014ZM1.45 35.994l34.543-34.57c-.946.245-1.886.51-2.82.817L2.269 33.147c-.307.967-.572 1.9-.817 2.847ZM0 45.473 45.473 0c-.722.04-1.444.095-2.165.163L.163 43.308A57.887 57.887 0 0 0 0 45.473ZM.225 53.284 53.285.224c-.613-.061-1.226-.123-1.839-.163L.054 51.445c.048.613.11 1.226.17 1.84ZM1.471 60.067l58.57-58.57-1.614-.388L1.056 58.48c.143.511.279 1.05.415 1.587ZM3.466 66.123 66.123 3.467a42.25 42.25 0 0 0-1.451-.559l-61.75 61.75c.163.484.347.968.544 1.465ZM6.082 71.523 71.523 6.081c-.429-.245-.865-.483-1.3-.68L5.373 70.208c.225.45.464.885.709 1.314ZM9.228 76.406 76.406 9.227a45.705 45.705 0 0 0-1.171-.83L8.397 75.233c.259.422.545.783.831 1.172ZM12.872 80.792l67.92-67.92c-.347-.32-.68-.64-1.049-.954L11.918 79.743c.314.354.634.681.954 1.049ZM16.992 84.708l67.716-67.716c-.3-.36-.613-.722-.933-1.076l-67.859 67.86c.354.32.715.633 1.076.932ZM21.596 88.154l66.572-66.572a46.053 46.053 0 0 0-.817-1.191L20.404 87.337c.375.28.776.58 1.192.817ZM26.683 91.082l64.38-64.4c-.225-.442-.45-.885-.682-1.32L25.348 90.393c.45.245.893.463 1.335.688ZM32.356 93.44 93.44 32.357c-.163-.497-.34-.988-.53-1.478l-62.03 62.03c.49.19.98.367 1.477.53ZM38.683 95.142l56.459-56.459a44.279 44.279 0 0 0-.354-1.648L37.035 94.788c.545.13 1.097.245 1.648.354ZM45.862 96 96 45.86c0-.633-.068-1.26-.123-1.886L43.975 95.877c.627.055 1.253.095 1.887.123ZM54.286 95.638l41.387-41.387c.095-.749.177-1.498.238-2.247L52.04 95.877a63.673 63.673 0 0 0 2.247-.239ZM64.958 92.969 92.97 64.957a54.887 54.887 0 0 0 1.022-3.037l-32.07 32.07c1.021-.306 2.036-.626 3.037-1.021Z"
      fill="#fff"
    />
    <mask id="PlayButtonGroup_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.782 95.224c25.92 0 46.932-21.012 46.932-46.931 0-25.92-21.012-46.932-46.932-46.932s-46.93 21.012-46.93 46.932 21.01 46.93 46.93 46.93Zm.048-2.629c24.441 0 44.255-19.813 44.255-44.255 0-24.442-19.814-44.255-44.255-44.255-24.442 0-44.255 19.813-44.255 44.255 0 24.442 19.813 44.255 44.255 44.255Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.782 95.224c25.92 0 46.932-21.012 46.932-46.931 0-25.92-21.012-46.932-46.932-46.932s-46.93 21.012-46.93 46.932 21.01 46.93 46.93 46.93Zm.048-2.629c24.441 0 44.255-19.813 44.255-44.255 0-24.442-19.814-44.255-44.255-44.255-24.442 0-44.255 19.813-44.255 44.255 0 24.442 19.813 44.255 44.255 44.255Z"
      fill="#fff"
    />
    <path
      d="M162.714 48.293c0 23.71-19.221 42.93-42.932 42.93v8c28.129 0 50.932-22.802 50.932-50.93h-8ZM119.782 5.36c23.711 0 42.932 19.221 42.932 42.932h8c0-28.129-22.803-50.932-50.932-50.932v8Zm-42.93 42.932c0-23.71 19.22-42.932 42.93-42.932v-8c-28.128 0-50.93 22.803-50.93 50.932h8Zm42.93 42.93c-23.71 0-42.93-19.22-42.93-42.93h-8c0 28.128 22.802 50.93 50.93 50.93v-8Zm40.303-42.883c0 22.232-18.023 40.255-40.255 40.255v8c26.65 0 48.255-21.604 48.255-48.255h-8ZM119.83 8.085c22.232 0 40.255 18.023 40.255 40.255h8c0-26.65-21.605-48.255-48.255-48.255v8ZM79.575 48.34c0-22.232 18.022-40.255 40.255-40.255v-8c-26.65 0-48.255 21.604-48.255 48.255h8Zm40.255 40.255c-22.233 0-40.255-18.023-40.255-40.255h-8c0 26.65 21.604 48.255 48.255 48.255v-8Z"
      fill="#fff"
      mask="url(#PlayButtonGroup_svg__a)"
    />
    <path d="M113.702 66.138v-34.82l25.737 15.14-25.737 19.68Z" stroke="#fff" strokeWidth={4} />
  </svg>
);

export default SvgPlayButtonGroup;
