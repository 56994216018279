import * as React from 'react';

const SvgButton = (props) => (
  <svg viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90 180c49.706 0 90-40.294 90-90S139.706 0 90 0C41.6 0 2.124 38.205.083 86.102h63.696l-7.15-7.224 2.004-2.024 8.505 8.594.007-.007 2.003 2.024-.007.007.007.008-2.003 2.023-.007-.007-8.505 8.594-2.003-2.024 6.81-6.88H.004C0 89.455 0 89.727 0 90c0 49.706 40.294 90 90 90Z"
      fill="#CBCFCE"
    />
  </svg>
);

export default SvgButton;
