import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom SelectionBox
 * 
 * Selection Box
 */
function SelectionBox (props) {
  const {
    className = "",
    options = [],
    name,
    value = '',
    onChange,
    ...other
  } = props;

  const handleOnChange = (option) => {
    onChange({target: { name, value: option }});
  };

  return <div
    className={`${css["atom__selection-box-container"]} ${className}`}
    {...other}
  >
    {
      options.length > 0 &&
      options.map((option, index) => (
        <label
          key={index}
          className={css["label"]}
        >
          <input
            type="radio"
            value={value}
            checked={value === option}
            onChange={() => handleOnChange(option)}
            name={name}
          />
          <span>{option}</span>
        </label>
      ))
    }
  </div>;
}

export default SelectionBox;
