import * as React from 'react';

const SvgAbstractDiagonalGrid = (props) => (
  <svg viewBox="0 0 1197 184" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.835 175.29 3 179.122l18.835 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="M21.835 160.844 3 168.174l18.835 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="M21.835 139.248 3 150.153l18.835 10.913 18.835-10.913-18.835-10.905ZM21.835 110.229 3 124.683l18.835 14.446 18.835-14.446-18.835-14.454ZM21.835 73.716 3 91.968l18.835 18.261 18.835-18.26-18.835-18.253ZM21.835 8.655 40.67 4.823 21.835 1 3 4.823l18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="M21.835 23.093 40.67 15.77l-18.835-7.33L3 15.771l18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="M21.835 44.696 40.67 33.791 21.835 22.878 3 33.79l18.835 10.905ZM21.835 73.716 40.67 59.262 21.835 44.816 3 59.262l18.835 14.454Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m21.835 110.229 18.835-18.26-18.835-18.253L3 91.968l18.835 18.261ZM58.596 175.29l-18.835 3.832 18.835 3.823 18.827-3.823-18.827-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m58.596 160.844-18.835 7.33 18.835 7.33 18.827-7.33-18.827-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m58.596 139.248-18.835 10.905 18.835 10.913 18.827-10.913-18.827-10.905ZM58.596 110.229l-18.835 14.454 18.835 14.446 18.827-14.446-18.827-14.454Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="M58.596 73.716 39.761 91.968l18.835 18.261 18.827-18.26-18.827-18.253ZM58.596 8.655l18.827-3.832L58.596 1 39.761 4.823l18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m58.596 23.093 18.827-7.322-18.827-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m58.596 44.696 18.827-10.905-18.827-10.913L39.761 33.79l18.835 10.905ZM58.596 73.716l18.827-14.454-18.827-14.446-18.835 14.446 18.835 14.454ZM58.596 110.229l18.827-18.26-18.827-18.253-18.835 18.252 18.835 18.261ZM96.309 175.29l-18.835 3.832 18.835 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m96.309 160.844-18.835 7.33 18.835 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m96.309 139.248-18.835 10.905 18.835 10.913 18.835-10.913-18.835-10.905ZM96.309 110.229l-18.835 14.454 18.835 14.446 18.835-14.446-18.835-14.454Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="M96.309 73.716 77.474 91.968l18.835 18.261 18.835-18.26-18.835-18.253ZM96.309 8.655l18.835-3.832L96.309 1 77.474 4.823l18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m96.309 23.093 18.835-7.322-18.835-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m96.309 44.696 18.835-10.905-18.835-10.913L77.474 33.79l18.835 10.905ZM96.309 73.716l18.835-14.454-18.835-14.446-18.835 14.446 18.835 14.454ZM96.309 110.229l18.835-18.26-18.835-18.253-18.835 18.252 18.835 18.261ZM133.07 175.29l-18.835 3.832 18.835 3.823 18.826-3.823-18.826-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m133.07 160.844-18.835 7.33 18.835 7.33 18.826-7.33-18.826-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m133.07 139.248-18.835 10.905 18.835 10.913 18.826-10.913-18.826-10.905ZM133.07 110.229l-18.835 14.454 18.835 14.446 18.826-14.446-18.826-14.454ZM133.07 73.716l-18.835 18.252 18.835 18.261 18.826-18.26-18.826-18.253ZM133.07 8.655l18.826-3.832L133.07 1l-18.835 3.823 18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m133.07 23.093 18.826-7.322-18.826-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m133.07 44.696 18.826-10.905-18.826-10.913-18.835 10.913 18.835 10.905ZM133.07 73.716l18.826-14.454-18.826-14.446-18.835 14.446 18.835 14.454ZM133.07 110.229l18.826-18.26-18.826-18.253-18.835 18.252 18.835 18.261ZM170.783 175.29l-18.835 3.832 18.835 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m170.783 160.844-18.835 7.33 18.835 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m170.783 139.248-18.835 10.905 18.835 10.913 18.835-10.913-18.835-10.905ZM170.783 110.229l-18.835 14.454 18.835 14.446 18.835-14.446-18.835-14.454ZM170.783 73.716l-18.835 18.252 18.835 18.261 18.835-18.26-18.835-18.253ZM170.783 8.655l18.835-3.832L170.783 1l-18.835 3.823 18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m170.783 23.093 18.835-7.322-18.835-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m170.783 44.696 18.835-10.905-18.835-10.913-18.835 10.913 18.835 10.905ZM170.783 73.716l18.835-14.454-18.835-14.446-18.835 14.446 18.835 14.454ZM170.783 110.229l18.835-18.26-18.835-18.253-18.835 18.252 18.835 18.261ZM207.544 175.29l-18.835 3.832 18.835 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m207.544 160.844-18.835 7.33 18.835 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m207.544 139.248-18.835 10.905 18.835 10.913 18.835-10.913-18.835-10.905ZM207.544 110.229l-18.835 14.454 18.835 14.446 18.835-14.446-18.835-14.454ZM207.544 73.716l-18.835 18.252 18.835 18.261 18.835-18.26-18.835-18.253ZM207.544 8.655l18.835-3.832L207.544 1l-18.835 3.823 18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m207.544 23.093 18.835-7.322-18.835-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m207.544 44.696 18.835-10.905-18.835-10.913-18.835 10.913 18.835 10.905ZM207.544 73.716l18.835-14.454-18.835-14.446-18.835 14.446 18.835 14.454ZM207.544 110.229l18.835-18.26-18.835-18.253-18.835 18.252 18.835 18.261ZM245.257 175.29l-18.827 3.832 18.827 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m245.257 160.844-18.827 7.33 18.827 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m245.257 139.248-18.827 10.905 18.827 10.913 18.835-10.913-18.835-10.905ZM245.257 110.229l-18.827 14.454 18.827 14.446 18.835-14.446-18.835-14.454ZM245.257 73.716 226.43 91.968l18.827 18.261 18.835-18.26-18.835-18.253ZM245.257 8.655l18.835-3.832L245.257 1 226.43 4.823l18.827 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m245.257 23.093 18.835-7.322-18.835-7.33-18.827 7.33 18.827 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m245.257 44.696 18.835-10.905-18.835-10.913L226.43 33.79l18.827 10.905ZM245.257 73.716l18.835-14.454-18.835-14.446-18.827 14.446 18.827 14.454ZM245.257 110.229l18.835-18.26-18.835-18.253-18.827 18.252 18.827 18.261ZM282.018 175.29l-18.835 3.832 18.835 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m282.018 160.844-18.835 7.33 18.835 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m282.018 139.248-18.835 10.905 18.835 10.913 18.835-10.913-18.835-10.905ZM282.018 110.229l-18.835 14.454 18.835 14.446 18.835-14.446-18.835-14.454ZM282.018 73.716l-18.835 18.252 18.835 18.261 18.835-18.26-18.835-18.253ZM282.018 8.655l18.835-3.832L282.018 1l-18.835 3.823 18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m282.018 23.093 18.835-7.322-18.835-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m282.018 44.696 18.835-10.905-18.835-10.913-18.835 10.913 18.835 10.905ZM282.018 73.716l18.835-14.454-18.835-14.446-18.835 14.446 18.835 14.454ZM282.018 110.229l18.835-18.26-18.835-18.253-18.835 18.252 18.835 18.261ZM319.688 175.29l-18.835 3.832 18.835 3.823 18.827-3.823-18.827-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m319.688 160.844-18.835 7.33 18.835 7.33 18.827-7.33-18.827-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m319.688 139.248-18.835 10.905 18.835 10.913 18.827-10.913-18.827-10.905ZM319.688 110.229l-18.835 14.454 18.835 14.446 18.827-14.446-18.827-14.454ZM319.688 73.716l-18.835 18.252 18.835 18.261 18.827-18.26-18.827-18.253ZM319.688 8.655l18.827-3.832L319.688 1l-18.835 3.823 18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m319.688 23.093 18.827-7.322-18.827-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m319.688 44.696 18.827-10.905-18.827-10.913-18.835 10.913 18.835 10.905ZM319.688 73.716l18.827-14.454-18.827-14.446-18.835 14.446 18.835 14.454ZM319.688 110.229l18.827-18.26-18.827-18.253-18.835 18.252 18.835 18.261ZM356.441 175.29l-18.826 3.832 18.826 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m356.441 160.844-18.826 7.33 18.826 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m356.441 139.248-18.826 10.905 18.826 10.913 18.835-10.913-18.835-10.905ZM356.441 110.229l-18.826 14.454 18.826 14.446 18.835-14.446-18.835-14.454ZM356.441 73.716l-18.826 18.252 18.826 18.261 18.835-18.26-18.835-18.253ZM356.441 8.655l18.835-3.832L356.441 1l-18.826 3.823 18.826 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m356.441 23.093 18.835-7.322-18.835-7.33-18.826 7.33 18.826 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m356.441 44.696 18.835-10.905-18.835-10.913-18.826 10.913 18.826 10.905ZM356.441 73.716l18.835-14.454-18.835-14.446-18.826 14.446 18.826 14.454ZM356.441 110.229l18.835-18.26-18.835-18.253-18.826 18.252 18.826 18.261ZM394.162 175.29l-18.835 3.832 18.835 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m394.162 160.844-18.835 7.33 18.835 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m394.162 139.248-18.835 10.905 18.835 10.913 18.835-10.913-18.835-10.905ZM394.162 110.229l-18.835 14.454 18.835 14.446 18.835-14.446-18.835-14.454ZM394.162 73.716l-18.835 18.252 18.835 18.261 18.835-18.26-18.835-18.253ZM394.162 8.655l18.835-3.832L394.162 1l-18.835 3.823 18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m394.162 23.093 18.835-7.322-18.835-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m394.162 44.696 18.835-10.905-18.835-10.913-18.835 10.913 18.835 10.905ZM394.162 73.716l18.835-14.454-18.835-14.446-18.835 14.446 18.835 14.454ZM394.162 110.229l18.835-18.26-18.835-18.253-18.835 18.252 18.835 18.261ZM430.923 175.29l-18.835 3.832 18.835 3.823 18.826-3.823-18.826-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m430.923 160.844-18.835 7.33 18.835 7.33 18.826-7.33-18.826-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m430.923 139.248-18.835 10.905 18.835 10.913 18.826-10.913-18.826-10.905ZM430.923 110.229l-18.835 14.454 18.835 14.446 18.826-14.446-18.826-14.454ZM430.923 73.716l-18.835 18.252 18.835 18.261 18.826-18.26-18.826-18.253ZM430.923 8.655l18.826-3.832L430.923 1l-18.835 3.823 18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m430.923 23.093 18.826-7.322-18.826-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m430.923 44.696 18.826-10.905-18.826-10.913-18.835 10.913 18.835 10.905ZM430.923 73.716l18.826-14.454-18.826-14.446-18.835 14.446 18.835 14.454ZM430.923 110.229l18.826-18.26-18.826-18.253-18.835 18.252 18.835 18.261ZM468.637 175.29l-18.835 3.832 18.835 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m468.637 160.844-18.835 7.33 18.835 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m468.637 139.248-18.835 10.905 18.835 10.913 18.835-10.913-18.835-10.905ZM468.637 110.229l-18.835 14.454 18.835 14.446 18.835-14.446-18.835-14.454ZM468.637 73.716l-18.835 18.252 18.835 18.261 18.835-18.26-18.835-18.253ZM468.637 8.655l18.835-3.832L468.637 1l-18.835 3.823 18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m468.637 23.093 18.835-7.322-18.835-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m468.637 44.696 18.835-10.905-18.835-10.913-18.835 10.913 18.835 10.905ZM468.637 73.716l18.835-14.454-18.835-14.446-18.835 14.446 18.835 14.454ZM468.637 110.229l18.835-18.26-18.835-18.253-18.835 18.252 18.835 18.261ZM505.397 175.29l-18.835 3.832 18.835 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m505.397 160.844-18.835 7.33 18.835 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m505.397 139.248-18.835 10.905 18.835 10.913 18.835-10.913-18.835-10.905ZM505.397 110.229l-18.835 14.454 18.835 14.446 18.835-14.446-18.835-14.454ZM505.397 73.716l-18.835 18.252 18.835 18.261 18.835-18.26-18.835-18.253ZM505.397 8.655l18.835-3.832L505.397 1l-18.835 3.823 18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m505.397 23.093 18.835-7.322-18.835-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m505.397 44.696 18.835-10.905-18.835-10.913-18.835 10.913 18.835 10.905ZM505.397 73.716l18.835-14.454-18.835-14.446-18.835 14.446 18.835 14.454ZM505.397 110.229l18.835-18.26-18.835-18.253-18.835 18.252 18.835 18.261ZM543.11 175.29l-18.826 3.832 18.826 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m543.11 160.844-18.826 7.33 18.826 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m543.11 139.248-18.826 10.905 18.826 10.913 18.835-10.913-18.835-10.905ZM543.11 110.229l-18.826 14.454 18.826 14.446 18.835-14.446-18.835-14.454ZM543.11 73.716l-18.826 18.252 18.826 18.261 18.835-18.26-18.835-18.253ZM543.11 8.655l18.835-3.832L543.11 1l-18.826 3.823 18.826 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m543.11 23.093 18.835-7.322-18.835-7.33-18.826 7.33 18.826 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m543.11 44.696 18.835-10.905-18.835-10.913-18.826 10.913 18.826 10.905ZM543.11 73.716l18.835-14.454-18.835-14.446-18.826 14.446 18.826 14.454ZM543.11 110.229l18.835-18.26-18.835-18.253-18.826 18.252 18.826 18.261ZM579.871 175.29l-18.835 3.832 18.835 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m579.871 160.844-18.835 7.33 18.835 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m579.871 139.248-18.835 10.905 18.835 10.913 18.835-10.913-18.835-10.905ZM579.871 110.229l-18.835 14.454 18.835 14.446 18.835-14.446-18.835-14.454ZM579.871 73.716l-18.835 18.252 18.835 18.261 18.835-18.26-18.835-18.253ZM579.871 8.655l18.835-3.832L579.871 1l-18.835 3.823 18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m579.871 23.093 18.835-7.322-18.835-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m579.871 44.696 18.835-10.905-18.835-10.913-18.835 10.913 18.835 10.905ZM579.871 73.716l18.835-14.454-18.835-14.446-18.835 14.446 18.835 14.454ZM579.871 110.229l18.835-18.26-18.835-18.253-18.835 18.252 18.835 18.261ZM617.583 175.29l-18.826 3.832 18.826 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m617.583 160.844-18.826 7.33 18.826 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m617.583 139.248-18.826 10.905 18.826 10.913 18.835-10.913-18.835-10.905ZM617.583 110.229l-18.826 14.454 18.826 14.446 18.835-14.446-18.835-14.454ZM617.583 73.716l-18.826 18.252 18.826 18.261 18.835-18.26-18.835-18.253ZM617.583 8.655l18.835-3.832L617.583 1l-18.826 3.823 18.826 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m617.583 23.093 18.835-7.322-18.835-7.33-18.826 7.33 18.826 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m617.583 44.696 18.835-10.905-18.835-10.913-18.826 10.913 18.826 10.905ZM617.583 73.716l18.835-14.454-18.835-14.446-18.826 14.446 18.826 14.454ZM617.583 110.229l18.835-18.26-18.835-18.253-18.826 18.252 18.826 18.261ZM654.345 175.29l-18.827 3.832 18.827 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m654.345 160.844-18.827 7.33 18.827 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m654.345 139.248-18.827 10.905 18.827 10.913 18.835-10.913-18.835-10.905ZM654.345 110.229l-18.827 14.454 18.827 14.446 18.835-14.446-18.835-14.454ZM654.345 73.716l-18.827 18.252 18.827 18.261 18.835-18.26-18.835-18.253ZM654.345 8.655l18.835-3.832L654.345 1l-18.827 3.823 18.827 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m654.345 23.093 18.835-7.322-18.835-7.33-18.827 7.33 18.827 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m654.345 44.696 18.835-10.905-18.835-10.913-18.827 10.913 18.827 10.905ZM654.345 73.716l18.835-14.454-18.835-14.446-18.827 14.446 18.827 14.454ZM654.345 110.229l18.835-18.26-18.835-18.253-18.827 18.252 18.827 18.261ZM692.067 175.29l-18.835 3.832 18.835 3.823 18.826-3.823-18.826-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m692.067 160.844-18.835 7.33 18.835 7.33 18.826-7.33-18.826-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m692.067 139.248-18.835 10.905 18.835 10.913 18.826-10.913-18.826-10.905ZM692.067 110.229l-18.835 14.454 18.835 14.446 18.826-14.446-18.826-14.454ZM692.067 73.716l-18.835 18.252 18.835 18.261 18.826-18.26-18.826-18.253ZM692.067 8.655l18.826-3.832L692.067 1l-18.835 3.823 18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m692.067 23.093 18.826-7.322-18.826-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m692.067 44.696 18.826-10.905-18.826-10.913-18.835 10.913 18.835 10.905ZM692.067 73.716l18.826-14.454-18.826-14.446-18.835 14.446 18.835 14.454ZM692.067 110.229l18.826-18.26-18.826-18.253-18.835 18.252 18.835 18.261ZM728.82 175.29l-18.827 3.832 18.827 3.823 18.834-3.823-18.834-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m728.82 160.844-18.827 7.33 18.827 7.33 18.834-7.33-18.834-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m728.82 139.248-18.827 10.905 18.827 10.913 18.834-10.913-18.834-10.905ZM728.82 110.229l-18.827 14.454 18.827 14.446 18.834-14.446-18.834-14.454ZM728.82 73.716l-18.827 18.252 18.827 18.261 18.834-18.26-18.834-18.253ZM728.82 8.655l18.834-3.832L728.82 1l-18.827 3.823 18.827 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m728.82 23.093 18.834-7.322-18.834-7.33-18.827 7.33 18.827 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m728.82 44.696 18.834-10.905-18.834-10.913-18.827 10.913 18.827 10.905ZM728.82 73.716l18.834-14.454-18.834-14.446-18.827 14.446 18.827 14.454ZM728.82 110.229l18.834-18.26-18.834-18.253-18.827 18.252 18.827 18.261ZM766.54 175.29l-18.835 3.832 18.835 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m766.54 160.844-18.835 7.33 18.835 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m766.54 139.248-18.835 10.905 18.835 10.913 18.835-10.913-18.835-10.905ZM766.54 110.229l-18.835 14.454 18.835 14.446 18.835-14.446-18.835-14.454ZM766.54 73.716l-18.835 18.252 18.835 18.261 18.835-18.26-18.835-18.253ZM766.54 8.655l18.835-3.832L766.54 1l-18.835 3.823 18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m766.54 23.093 18.835-7.322-18.835-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m766.54 44.696 18.835-10.905-18.835-10.913-18.835 10.913 18.835 10.905ZM766.54 73.716l18.835-14.454-18.835-14.446-18.835 14.446 18.835 14.454ZM766.54 110.229l18.835-18.26-18.835-18.253-18.835 18.252 18.835 18.261ZM803.301 175.29l-18.835 3.832 18.835 3.823 18.827-3.823-18.827-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m803.301 160.844-18.835 7.33 18.835 7.33 18.827-7.33-18.827-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m803.301 139.248-18.835 10.905 18.835 10.913 18.827-10.913-18.827-10.905ZM803.301 110.229l-18.835 14.454 18.835 14.446 18.827-14.446-18.827-14.454ZM803.301 73.716l-18.835 18.252 18.835 18.261 18.827-18.26-18.827-18.253ZM803.301 8.655l18.827-3.832L803.301 1l-18.835 3.823 18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m803.301 23.093 18.827-7.322-18.827-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m803.301 44.696 18.827-10.905-18.827-10.913-18.835 10.913 18.835 10.905ZM803.301 73.716l18.827-14.454-18.827-14.446-18.835 14.446 18.835 14.454ZM803.301 110.229l18.827-18.26-18.827-18.253-18.835 18.252 18.835 18.261ZM841.015 175.29l-18.835 3.832 18.835 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m841.015 160.844-18.835 7.33 18.835 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m841.015 139.248-18.835 10.905 18.835 10.913 18.835-10.913-18.835-10.905ZM841.015 110.229l-18.835 14.454 18.835 14.446 18.835-14.446-18.835-14.454Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="M841.015 73.716 822.18 91.968l18.835 18.261 18.835-18.26-18.835-18.253ZM841.015 8.655l18.835-3.832L841.015 1 822.18 4.823l18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m841.015 23.093 18.835-7.322-18.835-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m841.015 44.696 18.835-10.905-18.835-10.913L822.18 33.79l18.835 10.905ZM841.015 73.716l18.835-14.454-18.835-14.446-18.835 14.446 18.835 14.454ZM841.015 110.229l18.835-18.26-18.835-18.253-18.835 18.252 18.835 18.261ZM877.775 175.29l-18.835 3.832 18.835 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m877.775 160.844-18.835 7.33 18.835 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m877.775 139.248-18.835 10.905 18.835 10.913 18.835-10.913-18.835-10.905ZM877.775 110.229l-18.835 14.454 18.835 14.446 18.835-14.446-18.835-14.454ZM877.775 73.716 858.94 91.968l18.835 18.261 18.835-18.26-18.835-18.253ZM877.775 8.655l18.835-3.832L877.775 1 858.94 4.823l18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m877.775 23.093 18.835-7.322-18.835-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m877.775 44.696 18.835-10.905-18.835-10.913L858.94 33.79l18.835 10.905ZM877.775 73.716l18.835-14.454-18.835-14.446-18.835 14.446 18.835 14.454ZM877.775 110.229l18.835-18.26-18.835-18.253-18.835 18.252 18.835 18.261ZM915.438 175.29l-18.827 3.832 18.827 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m915.438 160.844-18.827 7.33 18.827 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m915.438 139.248-18.827 10.905 18.827 10.913 18.835-10.913-18.835-10.905ZM915.438 110.229l-18.827 14.454 18.827 14.446 18.835-14.446-18.835-14.454ZM915.438 73.716l-18.827 18.252 18.827 18.261 18.835-18.26-18.835-18.253ZM915.438 8.655l18.835-3.832L915.438 1l-18.827 3.823 18.827 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m915.438 23.093 18.835-7.322-18.835-7.33-18.827 7.33 18.827 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m915.438 44.696 18.835-10.905-18.835-10.913-18.827 10.913 18.827 10.905ZM915.438 73.716l18.835-14.454-18.835-14.446-18.827 14.446 18.827 14.454ZM915.438 110.229l18.835-18.26-18.835-18.253-18.827 18.252 18.827 18.261ZM952.199 175.29l-18.835 3.832 18.835 3.823 18.835-3.823-18.835-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m952.199 160.844-18.835 7.33 18.835 7.33 18.835-7.33-18.835-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m952.199 139.248-18.835 10.905 18.835 10.913 18.835-10.913-18.835-10.905ZM952.199 110.229l-18.835 14.454 18.835 14.446 18.835-14.446-18.835-14.454ZM952.199 73.716l-18.835 18.252 18.835 18.261 18.835-18.26-18.835-18.253ZM952.199 8.655l18.835-3.832L952.199 1l-18.835 3.823 18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m952.199 23.093 18.835-7.322-18.835-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m952.199 44.696 18.835-10.905-18.835-10.913-18.835 10.913 18.835 10.905ZM952.199 73.716l18.835-14.454-18.835-14.446-18.835 14.446 18.835 14.454ZM952.199 110.229l18.835-18.26-18.835-18.253-18.835 18.252 18.835 18.261ZM989.919 175.29l-18.835 3.832 18.835 3.823 18.831-3.823-18.831-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m989.919 160.844-18.835 7.33 18.835 7.33 18.831-7.33-18.831-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m989.919 139.248-18.835 10.905 18.835 10.913 18.831-10.913-18.831-10.905ZM989.919 110.229l-18.835 14.454 18.835 14.446 18.831-14.446-18.831-14.454ZM989.919 73.716l-18.835 18.252 18.835 18.261 18.831-18.26-18.831-18.253ZM989.919 8.655l18.831-3.832L989.919 1l-18.835 3.823 18.835 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m989.919 23.093 18.831-7.322-18.831-7.33-18.835 7.33 18.835 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m989.919 44.696 18.831-10.905-18.831-10.913-18.835 10.913 18.835 10.905ZM989.919 73.716l18.831-14.454-18.831-14.446-18.835 14.446 18.835 14.454ZM989.919 110.229l18.831-18.26-18.831-18.253-18.835 18.252 18.835 18.261ZM1026.67 175.29l-18.82 3.832 18.82 3.823 18.84-3.823-18.84-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1026.67 160.844-18.82 7.33 18.82 7.33 18.84-7.33-18.84-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1026.67 139.248-18.82 10.905 18.82 10.913 18.84-10.913-18.84-10.905ZM1026.67 110.229l-18.82 14.454 18.82 14.446 18.84-14.446-18.84-14.454ZM1026.67 73.716l-18.82 18.252 18.82 18.261 18.84-18.26-18.84-18.253ZM1026.67 8.655l18.84-3.832L1026.67 1l-18.82 3.823 18.82 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1026.67 23.093 18.84-7.322-18.84-7.33-18.82 7.33 18.82 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1026.67 44.696 18.84-10.905-18.84-10.913-18.82 10.913 18.82 10.905ZM1026.67 73.716l18.84-14.454-18.84-14.446-18.82 14.446 18.82 14.454ZM1026.67 110.229l18.84-18.26-18.84-18.253-18.82 18.252 18.82 18.261ZM1064.39 175.29l-18.83 3.832 18.83 3.823 18.84-3.823-18.84-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1064.39 160.844-18.83 7.33 18.83 7.33 18.84-7.33-18.84-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1064.39 139.248-18.83 10.905 18.83 10.913 18.84-10.913-18.84-10.905ZM1064.39 110.229l-18.83 14.454 18.83 14.446 18.84-14.446-18.84-14.454ZM1064.39 73.716l-18.83 18.252 18.83 18.261 18.84-18.26-18.84-18.253ZM1064.39 8.655l18.84-3.832L1064.39 1l-18.83 3.823 18.83 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1064.39 23.093 18.84-7.322-18.84-7.33-18.83 7.33 18.83 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1064.39 44.696 18.84-10.905-18.84-10.913-18.83 10.913 18.83 10.905ZM1064.39 73.716l18.84-14.454-18.84-14.446-18.83 14.446 18.83 14.454ZM1064.39 110.229l18.84-18.26-18.84-18.253-18.83 18.252 18.83 18.261ZM1101.15 175.29l-18.83 3.832 18.83 3.823 18.83-3.823-18.83-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1101.15 160.844-18.83 7.33 18.83 7.33 18.83-7.33-18.83-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1101.15 139.248-18.83 10.905 18.83 10.913 18.83-10.913-18.83-10.905ZM1101.15 110.229l-18.83 14.454 18.83 14.446 18.83-14.446-18.83-14.454ZM1101.15 73.716l-18.83 18.252 18.83 18.261 18.83-18.26-18.83-18.253ZM1101.15 8.655l18.83-3.832L1101.15 1l-18.83 3.823 18.83 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1101.15 23.093 18.83-7.322-18.83-7.33-18.83 7.33 18.83 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1101.15 44.696 18.83-10.905-18.83-10.913-18.83 10.913 18.83 10.905ZM1101.15 73.716l18.83-14.454-18.83-14.446-18.83 14.446 18.83 14.454ZM1101.15 110.229l18.83-18.26-18.83-18.253-18.83 18.252 18.83 18.261ZM1138.87 175.29l-18.84 3.832 18.84 3.823 18.83-3.823-18.83-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1138.87 160.844-18.84 7.33 18.84 7.33 18.83-7.33-18.83-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1138.87 139.248-18.84 10.905 18.84 10.913 18.83-10.913-18.83-10.905ZM1138.87 110.229l-18.84 14.454 18.84 14.446 18.83-14.446-18.83-14.454ZM1138.87 73.716l-18.84 18.252 18.84 18.261 18.83-18.26-18.83-18.253ZM1138.87 8.655l18.83-3.832L1138.87 1l-18.84 3.823 18.84 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1138.87 23.093 18.83-7.322-18.83-7.33-18.84 7.33 18.84 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1138.87 44.696 18.83-10.905-18.83-10.913-18.84 10.913 18.84 10.905ZM1138.87 73.716l18.83-14.454-18.83-14.446-18.84 14.446 18.84 14.454ZM1138.87 110.229l18.83-18.26-18.83-18.253-18.84 18.252 18.84 18.261ZM1175.63 175.29l-18.84 3.832 18.84 3.823 18.83-3.823-18.83-3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1175.63 160.844-18.84 7.33 18.84 7.33 18.83-7.33-18.83-7.33Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1175.63 139.248-18.84 10.905 18.84 10.913 18.83-10.913-18.83-10.905ZM1175.63 110.229l-18.84 14.454 18.84 14.446 18.83-14.446-18.83-14.454ZM1175.63 73.716l-18.84 18.252 18.84 18.261 18.83-18.26-18.83-18.253ZM1175.63 8.655l18.83-3.832L1175.63 1l-18.84 3.823 18.84 3.832Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1175.63 23.093 18.83-7.322-18.83-7.33-18.84 7.33 18.84 7.322Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
    <path
      d="m1175.63 44.696 18.83-10.905-18.83-10.913-18.84 10.913 18.84 10.905ZM1175.63 73.716l18.83-14.454-18.83-14.446-18.84 14.446 18.84 14.454ZM1175.63 110.229l18.83-18.26-18.83-18.253-18.84 18.252 18.84 18.261Z"
      stroke="#CBCFCE"
      strokeMiterlimit={10}
    />
  </svg>
);

export default SvgAbstractDiagonalGrid;
