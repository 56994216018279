/* eslint-disable max-len */
import React from 'react';
import Head from 'next/head';

/**
 * Atom Head
 * 
 * Head
 */

function MainHead (props) {
  return <Head>
    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    <link rel="manifest" href="/site.webmanifest" />
    <link rel="icon" type="image/x-icon" href="/favicon.ico" />
    <meta name="keywords" content="Eyxo, conteúdo de marca, marketing, content marketing, digital influencer, marketing de influência, live marketing, agência de publicidade, diversidade na comunicação, sistema b, brand content, marketing de conteúdo" />

    <meta property="og:type" content="website" />
    <meta itemProp="inLanguage" content="pt-BR" />
    <meta property="og:locale" content="pt-BR" />

    {/* <meta itemProp="primaryImageOfPage" content="" />
    <meta itemProp="image" content="" />
    <meta property="og:image" content="" />
    <meta name="twitter:image:src" content="" />
    <meta name="twitter:image" content="" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" /> */}

    <meta name="description" content="Somos apaixonados por criar soluções para um mundo melhor através da experiência de conteúdo de marca. Empresa B Certificada." />
    <meta property="og:description" content="Somos apaixonados por criar soluções para um mundo melhor através da experiência de conteúdo de marca. Empresa B Certificada." />
    <meta property="twitter:description" content="Somos apaixonados por criar soluções para um mundo melhor através da experiência de conteúdo de marca. Empresa B Certificada." />

    <meta property="og:url" content={"https://www.eyxo.com.br"} />
  </Head>;
}

export default MainHead;
