/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import Button from 'components/atoms/button';
import Title from 'components/atoms/title';
import Typography from 'components/atoms/typography';
import Input from 'components/atoms/input';
import SelectionBox from 'components/atoms/selection-box';

import { isEmpty } from 'utils/functions';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule JobForm
 * 
 * Job Form
 */
function JobForm (props) {
  const {
    className = "",
    children,
    ...other
  } = props;

  const [showForm, setShowForm] = useState(false);
  const [consent, setConsent] = useState({
    consentName: '',
    consentCPF: '',
    checkConsent: '',
  });
  const [btnCheckConsentDisabled, setBtnCheckConsentDisabled] = useState(true);
  const [form, setForm] = useState({
    checkConsent: '',
    name: '',
    surename: '',
    whatsapp: '',
    email: '',
    cv: '',
    link: '',
    q1: '',
    q2: '',
    q3: '',
    q4: '',
    q5: '',
    q6: '',
    q7: '',
    q8: '',
    q9: '',
    q10: '',
  });

  useEffect(() => {
    const areValuesEmpty = Object.values(consent).some(x => isEmpty(x));
    setBtnCheckConsentDisabled(areValuesEmpty);
  }, [consent]);

  useEffect(() => {
    if(showForm) {
      document.getElementById("form-container").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start"
      });
    }
  }, [showForm]);

  const options = [
    'SIM',
    'NÃO',
  ];

  const url = "https://docs.google.com/forms/u/4/d/e/1FAIpQLSeMe27O0YKNuHi4dyGgrRmYD-IQkQnQTYkn5NmWhfO9RVmceA/formResponse";

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.info("Enviando resposta..");

    let dataToPost = new FormData();
    dataToPost.append("entry.168663752", form.name);
    dataToPost.append("entry.976392484", form.surename);
    dataToPost.append("entry.348410328", form.whatsapp);
    dataToPost.append("entry.46416376", form.email);
    dataToPost.append("entry.111245714", form.cv);
    dataToPost.append("entry.1892134786", form.link);
    dataToPost.append("entry.361208539", form.q1);
    dataToPost.append("entry.1280718696", form.q2);
    dataToPost.append("entry.1490019923", form.q3);
    dataToPost.append("entry.1854419231", form.q4);
    dataToPost.append("entry.541772147", form.q5);
    dataToPost.append("entry.1360391176", form.q6);
    dataToPost.append("entry.640761683", form.q7);
    dataToPost.append("entry.1311036305", form.q8);
    dataToPost.append("entry.1589148182", form.q9);
    dataToPost.append("entry.756688483", form.q10);

    fetch(url, {
      method: "POST",
      mode: "no-cors",
      header: {
        "Content-Type": "application/json"
      },
      body: dataToPost
    }).then(() => {
      toast.success("Resposta enviada com sucesso!");
      cleanForm();
    }).catch((err) => {
      console.error(err);
      toast.error("Ocorreu um erro ao enviar a resposta! Por favor tente mais tarde.");
    });
  };

  const cleanForm = () => {
    Object.keys(form).forEach(key =>
      setForm(oldState => ({ ...oldState, [key]: '' }))
    );
  };

  const handleChange = useCallback((event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  }, [form]);

  const handleAcceptConsent = () => setShowForm(true);

  const handleChangeConsent = useCallback((event) => {
    setConsent({
      ...consent,
      [event.target.name]: event.target.value,
    });
  }, [consent]);

  return <div
    className={`${css["molecule__job-form-container"]} ${className}`}
    id="form-container"
    {...other}>
    {
      showForm ? (
        <form
          className={css["form"]}
          onSubmit={handleSubmit}
        >
          <Typography bold>Nome completo*</Typography>
          <div className={css["fullname"]}>
            <Input
              required
              name="name"
              placeholder="Nome"
              type="text"
              value={form.name}
              onChange={handleChange}
              textTransform="capitalize"
            />

            <Input
              required
              name="surename"
              placeholder="Sobrenome"
              type="text"
              value={form.surename}
              onChange={handleChange}
              textTransform="capitalize"
            />
          </div>
          <Typography bold>What's App*</Typography>

          <Input
            required
            name="whatsapp"
            placeholder="What's App"
            type="tel"
            value={form.whatsapp}
            onChange={handleChange}
            textTransform="capitalize"
          />

          <Typography bold>E-mail*</Typography>
          <Input
            required
            name="email"
            placeholder="E-mail"
            type="email"
            value={form.email}
            onChange={handleChange}
          />

          <Typography bold>Currículo</Typography>
          <Typography type="inter">
            Dica: se precisar, suba seu arquivo PDF e coloque o link aqui :)
          </Typography>
          <Input
            name="cv"
            placeholder="http://"
            type="text"
            value={form.cv}
            onChange={handleChange}
            textTransform="lowercase"
          />

          <Typography bold>Deixe seus links</Typography>
          <Typography type="inter">Linkedin, Instagram e etc</Typography>
          <Input
            name="link"
            placeholder="http://"
            type="text"
            value={form.link}
            onChange={handleChange}
            textTransform="lowercase"
          />

          <Typography bold>
            A Eyxo tem compromisso com a diversidade, equidade e inclusão. Você se autodeclara como parte de algum grupo de diversidade? Se sim, qual? Buscamos ter um olhar cuidadoso para todas as questões, principalmente de raça, gênero, idade, orientação sexual e educação.
          </Typography>
          <Input
            name="q1"
            type="text"
            value={form.q1}
            onChange={handleChange}
            multiline
          />

          <Typography bold>Você conhece o SISTEMA B?</Typography>
          <SelectionBox className={css["selection-box"]} name="q2" value={form.q2} options={options} onChange={handleChange}/>

          <Typography bold>Se você pudesse escolher qualquer pessoa no mundo pra trocar uma ideia, quem seria?</Typography>
          <Input
            name="q3"
            type="text"
            value={form.q3}
            onChange={handleChange}
          />

          <Typography bold>Te apresenta pra gente! O que você acha massa sabermos sobre ti?</Typography>
          <Input
            name="q4"
            type="text"
            value={form.q4}
            onChange={handleChange}
            multiline
          />

          <Typography bold>Qual seu núcleo de interesse?</Typography>
          <Input
            name="q5"
            type="text"
            value={form.q5}
            onChange={handleChange}
          />

          <Typography bold>Liste algumas pessoas, marcas ou projetos que você curte e/ou tem como referência</Typography>
          <Input
            name="q6"
            type="text"
            value={form.q6}
            onChange={handleChange}
          />

          <Typography bold>Qual é sua pretensão salarial?</Typography>
          <Input
            name="q7"
            placeholder="R$"
            type="text"
            value={form.q7}
            onChange={handleChange}
          />

          <Typography bold>Você gostaria de receber a newsletter da Eyxo para ficar por dentro do que acontece no mercado de marketing e comunicação?</Typography>
          <SelectionBox className={css["selection-box"]} name="q8" value={form.q8} options={options} onChange={handleChange}/>

          <Typography bold>Caso você não seja a pessoa selecionada para uma vaga fixa na Eyxo, você gostaria de fazer freelas conosco?</Typography>
          <SelectionBox className={css["selection-box"]} name="q9" value={form.q9} options={options} onChange={handleChange}/>

          <Typography bold>Você é responsável pela veracidade das informações aqui prestadas. *</Typography>
          <SelectionBox className={css["selection-box"]} name="q10" value={form.q10} options={['Ok, concordo.']} onChange={handleChange}/>

          <Button type="submit" rounded parentTheme="white" alt="Enviar">
            Enviar
          </Button>
        </form>
      ) : (
        <div className={css["candidate-consent-terms"]}>
          <Title className={css["title"]} level="3">TERMO DE CONSENTIMENTO  PARA TRATAMENTO DE DADOS PESSOAIS NO RECEBIMENTO DE DADOS PESSOAIS DOS CANDIDATOS À VAGA DE EMPREGO/ESTÁGIO ( E CURRÍCULOS)</Title>
          <div className={css["grid"]}>
            <div className={css["grid-row"]}>
              <Typography bold>TITULAR:</Typography>
              <Typography>pessoa natural a quem se referem os dados pessoais que são objeto de tratamento. Aqui também denominado <strong>CANDIDATO</strong>.</Typography>
            </div>
            <div className={css["grid-row"]}>
              <Typography bold>CONTROLADOR:</Typography>
              <Typography>
              pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais; Aqui identificado por:<br/>
                <strong>EYXO ESTRATÉGIAS DE INOVAÇÃO LTDA.</strong>, inscrita no CNPJ n.º 09.055.999/0001-40, com sede na Avenida Doutor Nilo Peçanha, 1500, Salas 18 a 22, Bairro Boa Vista,  CEP 91.330-002, em Porto Alegre/RS.
              </Typography>
            </div>
          </div>

          <ul>
            <li>
              <Typography><strong>1 –</strong> Em conformidade com a Lei n.º 13.709/18 - Lei Geral de Proteção de Dados Pessoais (LGPD), com a finalidade de proteger os direitos fundamentais de liberdade, de intimidade, de privacidade e o livre desenvolvimento da personalidade da pessoa natural, o presente Termo visa registrar o CONSENTIMENTO LIVRE, INFORMADO e INEQUÍVOCO para que os  <strong>dados pessoais (e currículo)</strong> fornecidos pelo <strong>CANDIDATO</strong> sejam tratados pelo <strong>CONTROLADOR</strong>, única e exclusivamente na realização de ações relacionadas à avaliação, seleção e eventual recrutamento para participação em processo de admissão para compor o quadro de empregados / estagiários deste.</Typography>
            </li>
            <li>
              <Typography bold><strong>2 –</strong>Dados Pessoais solicitados pelo CONTROLADOR:</Typography>
              <ol>
                <li><Typography>Nome completo: Identificar e autenticar o <strong>CANDIDATO</strong>.</Typography></li>
                <li><Typography>E-mail/telefone: Entrar em contato com o <strong>CANDIDATO</strong> para que ele possa participar de processos seletivos;</Typography></li>
                <li><Typography>Endereço: Verificar a distância da oportunidade em relação à residência do <strong>CANDIDATO</strong>;</Typography></li>
                <li><Typography>Histórico profissional: Verificar a compatibilidade de seus conhecimentos e experiência em relação à(s) vaga(s) disponível(eis).</Typography></li>
              </ol>
            </li>
            <li>
              <Typography><strong>2.1 –</strong> O <strong>CONTROLADOR</strong> não tem a intenção em tratar dados sensíveis, mas, eventualmente, poderá ocorrer o processamento de tais dados dependendo das informações que o <strong>CANDIDATO</strong> voluntariamente fornecer.</Typography>
            </li>
            <li>
              <Typography><strong>2.2 –</strong> O <strong>CANDIDATO</strong> declara ser de sua inteira responsabilidade a precisão e a veracidade em relação aos Dados fornecidos, bem como a responsabilidade de garantir a atualização e exatidão dos Dados.</Typography>
            </li>
            <li>
              <Typography><strong>3 –</strong> O <strong>CANDIDATO</strong> manifesta a aceitação, consentindo e concordando livremente que o <strong>CONTROLADOR</strong> realize o tratamento dos seus <strong>dados pessoais (e currículo)</strong> nas operações de coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração. Em conformidade com a Lei n.º 13.709/2018 – Lei Geral de Proteção de Dados Pessoais (LGPD).</Typography>
            </li>
            <li>
              <Typography>
                <strong>4 –</strong> O <strong>CANDIDATO</strong> autoriza o <strong>CONTROLADOR</strong> a:
              </Typography>
              <ol>
                <li><Typography>compartilhar seus dados pessoais com outros agentes de tratamento de dados , caso seja necessário para as finalidades de processo seletivo;</Typography></li>
                <li><Typography>armazenar seus dados pessoais (e currículo) no banco do <strong>CONTROLADOR</strong>  pelo prazo de 10 (anos) anos;</Typography></li>
                <li><Typography>divulgar ao <strong>CANDIDATO</strong> a abertura de novos processos seletivos para qualquer vaga e/ou função durante o período em seus dados pessoais (e currículo) permanecerem armazenados no banco do <strong>CONTROLADOR</strong>.</Typography></li>
              </ol>
            </li>
            <li>
              <Typography>
                <strong>5 –</strong> O <strong>CONTROLADOR</strong> se responsabiliza por:
              </Typography>
              <ol>
                <li><Typography>adotar programas de segurança da informação que contemplam medidas de segurança, técnicas e administrativas aptos a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito, fazendo  uso de sistemas que atendem aos requisitos de segurança, aos padrões de boas práticas e de governança, aos princípios gerais da Lei n.º 13.709/18 - Lei Geral de Proteção de Dados Pessoais (LGPD), e às demais normas regulamentares aplicáveis;</Typography></li>
                <li><Typography>comunicar ao <strong>CANDIDATO</strong> e à Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar risco ou dano relevante ao <strong>CANDIDATO</strong>,  bem como informar as medidas técnicas que foram ou estão sendo adotadas para solucionar o ocorrido;</Typography></li>
                <li><Typography>não utilizar, compartilhar, comercializar, ou atribuir qualquer outra finalidade aos dados pessoais coletados, que seja diferente da finalidade aqui especificada para o integral cumprimento do objeto deste Termo de Consentimento, sob pena de arcar com eventuais danos e sanções previstas em lei;</Typography></li>
                <li><Typography>manter registro das operações de tratamento de dados pessoais que realizar em decorrência do presente Termo de Consentimento, contendo no mínimo a descrição dos tipos de dados coletados, bem como a metodologia e mecanismos de mitigação de riscos adotados para a garantia da segurança das informações;</Typography></li>
                <li><Typography>solicitar ao <strong>CANDIDATO</strong>, quando necessário, novo consentimento para finalidades específicas decorrentes da fase de contratação;</Typography></li>
                <li><Typography>disponibilizar canal(ais) de comunicação  que permita(m) ao <strong>CANDIDATO</strong> solicitar confirmação da existência de tratamento e livre acesso dos dados pessoais informados, bem como enviar solicitação para correção de dados incompletos, inexatos ou desatualizados, e ainda a possibilidade de revogação deste Termo de Consentimento;</Typography></li>
                <li><Typography>dispor de Encarregado pelo Tratamento de Dados Pessoais (DPO), cuja identidade e informações de contato estão referidos na Política de Privacidade do <strong>CONTROLADOR</strong>, disponibilizada no site https://www.eyxo.com.br,  sendo o Encarregado a pessoa responsável por receber, processar e adotar providências quanto eventuais solicitações realizadas pelo titular dos dados pessoais;</Typography></li>
                <li><Typography>adequar-se-á às novas regras vigentes quanto ao tratamento e proteção de dados pessoais caso ocorra a alteração ou revogação do texto legal supra indicado, de modo que influencie diretamente nas ações relacionadas neste termo;</Typography></li>
                <li><Typography>encerrar o tratamento e eliminar os seus dados pessoais (e currículo) armazenados quando da revogação e/ou extinção deste Termo de Consentimento, ressalvadas as hipóteses em que por obrigações legais e regulatórias, inclusive as previstas no art. 7º da Lei n.º 13.709/2018 – Lei Geral de Proteção de Dados Pessoais (LGPD), o <strong>CONTROLADOR</strong> deva mantê-los para a realização de todas as ações relacionadas ao tratamento após o seu término.</Typography></li>
              </ol>
            </li>
            <li>
              <Typography><strong>6 – Direito de Revogação do Consentimento:</strong> O <strong>CANDIDATO</strong> poderá revogar a qualquer tempo o presente consentimento, mediante manifestação expressa através dos canais de comunicação. Neste caso, o <strong>CONTROLADOR</strong> deverá encerrar o tratamento e eliminar os dados pessoais (e currículo) armazenados.</Typography>
            </li>
            <li>
              <Typography>
                <strong>7 – Canais de Comunicação:</strong> O <strong>CONTROLADOR</strong> poderá ser contatada por:<br/>
                <strong>Encarregado (DPO):</strong> Fabiana Einhardt<br/>
                <strong>Correio postal:</strong> Av, Dr. Nilo Peçanha, 1500, Sl 18 a 22, Boa Vista - CEP 91.330-002, Porto Alegre/RS<br/>
                <strong>Correio eletrônico:</strong> contato@eyxo.com.br
              </Typography>
            </li>
            <li>
              <Typography><strong>8 –</strong> O <strong>CANDIDATO</strong> ao fornecer os seus dados e currículo ao <strong>CONTROLADOR</strong>, DECLARA expressamente seu CONSENTIMENTO, dando plena ciência dos direitos e obrigações decorrentes, a fim de que seus dados sejam tratados na forma descrita acima, declarando ainda ter lido, compreendido e aceitado todos os termos e condições aqui descritos.</Typography>
            </li>
          </ul>

          <Input
            required
            name="consentName"
            placeholder="Nome Completo *"
            type="text"
            value={consent.consentName}
            onChange={handleChangeConsent}
            textTransform="capitalize"
            className={css["consent-input"]}
          />
          <Input
            required
            name="consentCPF"
            placeholder="CPF *"
            type="text"
            value={consent.consentCPF}
            onChange={handleChangeConsent}
            className={css["consent-input"]}
          />
          <SelectionBox className={css["consent-box"]} name="checkConsent" value={consent.checkConsent} options={['Eu li, entendi e aceito o Termo de Consentimento.']} onChange={handleChangeConsent}/>
          <Button
            type="button"
            rounded
            parentTheme="white"
            alt="Continuar"
            disabled={btnCheckConsentDisabled}
            onClick={handleAcceptConsent}
            className={css["consent-btn"]}
          >
          Continuar
          </Button>
        </div>)
    }
  </div>;
}

export default JobForm;
