import React from "react";

import * as Icons from 'components/icons';

import { Parallax } from "react-scroll-parallax";

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom Abstract
 * 
 * Abstract images
 */
function Abstract (props) {
  const {
    className = "",
    type = "0",
    alt = "Imagem abstrata",
    transform = 0, // rotate 0, 90, 180, 270
    position = "top", // top, bottom
    disabled = false, // parallax effect
    speed = 35,
    ...other
  } = props;

  const getIconName = (type) => ({
    '2': 'AbstractCircle',
    '3': 'AbstractCirclesGroup',
    '4': 'AbstractCirclesGrid',
    '5': 'AbstractCurves',
    '6': 'AbstractGroup',
    '7': 'AbstractHugeGrid',
    '8': 'AbstractGrid',
    '9': 'AbstractTwoDots',
    '10': 'AbstractGrayCirclesGroup',
    '11': 'AbstractVerticalGrid',
    '12': 'AbstractDiagonalGrid',
  })[type] || 'AbstractCircleBrand';

  const Icon = Icons[getIconName(type)];

  const direction = (currentPosition) => ({
    0: [-speed, speed],
    90: [-speed, speed],
    180: [speed, -speed],
    270: [speed, -speed],
  })[currentPosition] || [-speed, speed];

  const isHorizontal = transform === 90 || transform === 270;
  const parallaxProps = {
    x: isHorizontal ? direction(position) : [0, 0],
    y: isHorizontal ? [0, 0] : direction(position),
    tagOuter: "figure",
    disabled
  };

  const AbstractCircleBrand = Icons['AbstractCircleBrand'];
  const AbstractCircle = Icons['AbstractCircle'];
  return <div
    className={`${css["atom__abstract-container"]} ${className}`}
    data-type={type}
    alt={alt}
    style={{ transform: `rotate(${transform}deg)` }}
    {...other}
  >
    <Parallax {...parallaxProps}>
      {
        type === '0' ? (
          <>
            <AbstractCircleBrand className={css["brand"]} />
            <AbstractCircle className={css["circle"]} />
          </>
        ): (
          <Icon />
        )
      }
    </Parallax>
  </div>;
}

export default Abstract;
