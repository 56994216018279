import * as React from 'react';

const SvgAbstractVerticalGrid = (props) => (
  <svg viewBox="0 0 505 213" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M504.094 185.644v-26.357h-5.356v26.357h5.356ZM498.738 212v-26.356h-5.355V212h5.355ZM493.404 185.644v-26.357h-10.09v26.357h10.09ZM473.181 185.644v-26.357h-15.145v26.357h15.145ZM458.165 212v-26.356h-15.146V212h15.146ZM442.848 185.644v-26.357h-20.201v26.357h20.201ZM422.668 212v-26.356h-20.201V212h20.201ZM402.467 185.644v-26.357h-25.514v26.357h25.514ZM376.932 212v-26.356h-25.514V212h25.514Z"
      stroke="#000"
    />
    <path
      d="M493.426 185.644v-26.357h-10.09v26.357h10.09ZM483.293 212v-26.356h-10.09V212h10.09ZM249.792 185.622v26.357h5.355v-26.357h-5.355Z"
      stroke="#000"
    />
    <path
      d="M255.126 159.287v26.357h5.355v-26.357h-5.355ZM260.481 185.622v26.357h10.09v-26.357h-10.09ZM280.725 185.622v26.357h15.145v-26.357h-15.145Z"
      stroke="#000"
    />
    <path
      d="M295.72 159.287v26.357h15.146v-26.357H295.72ZM311.016 185.622v26.357h20.201v-26.357h-20.201ZM331.239 159.287v26.357h20.201v-26.357h-20.201Z"
      stroke="#000"
    />
    <path
      d="M351.418 185.622v26.357h25.514v-26.357h-25.514ZM376.932 159.287v26.357h25.514v-26.357h-25.514ZM260.46 185.622v26.357h10.09v-26.357h-10.09ZM270.571 159.287v26.357h10.09v-26.357h-10.09ZM504.094 132.845v-26.356h-5.356v26.356h5.356ZM498.738 159.202v-26.356h-5.355v26.356h5.355ZM493.404 132.845v-26.356h-10.09v26.356h10.09ZM473.181 132.845v-26.356h-15.145v26.356h15.145ZM458.165 159.202v-26.356h-15.146v26.356h15.146ZM442.848 132.845v-26.356h-20.201v26.356h20.201ZM422.668 159.202v-26.356h-20.201v26.356h20.201ZM402.467 132.845v-26.356h-25.514v26.356h25.514ZM376.932 159.202v-26.356h-25.514v26.356h25.514Z"
      stroke="#000"
    />
    <path
      d="M493.426 132.845v-26.356h-10.09v26.356h10.09ZM483.293 159.202v-26.356h-10.09v26.356h10.09ZM249.792 132.845v26.357h5.355v-26.357h-5.355Z"
      stroke="#000"
    />
    <path
      d="M255.126 106.489v26.357h5.355v-26.357h-5.355ZM260.481 132.845v26.357h10.09v-26.357h-10.09ZM280.725 132.845v26.357h15.145v-26.357h-15.145Z"
      stroke="#000"
    />
    <path
      d="M295.72 106.489v26.357h15.146v-26.357H295.72ZM311.016 132.845v26.357h20.201v-26.357h-20.201ZM331.239 106.489v26.357h20.201v-26.357h-20.201Z"
      stroke="#000"
    />
    <path
      d="M351.418 132.845v26.357h25.514v-26.357h-25.514ZM376.932 106.489v26.357h25.514v-26.357h-25.514ZM260.46 132.845v26.357h10.09v-26.357h-10.09ZM270.571 106.489v26.357h10.09v-26.357h-10.09ZM504.094 80.154V53.798h-5.356v26.356h5.356ZM498.738 106.489V80.133h-5.355v26.356h5.355Z"
      stroke="#000"
    />
    <path
      d="M493.404 80.154V53.798h-10.09v26.356h10.09ZM473.181 80.154V53.798h-15.145v26.356h15.145Z"
      stroke="#000"
    />
    <path
      d="M458.165 106.489V80.133h-15.146v26.356h15.146ZM442.848 80.154V53.798h-20.201v26.356h20.201Z"
      stroke="#000"
    />
    <path
      d="M422.668 106.489V80.133h-20.201v26.356h20.201ZM402.467 80.154V53.798h-25.514v26.356h25.514ZM376.932 106.489V80.133h-25.514v26.356h25.514ZM493.426 80.154V53.798h-10.09v26.356h10.09ZM483.293 106.489V80.133h-10.09v26.356h10.09ZM249.792 80.133v26.356h5.355V80.133h-5.355Z"
      stroke="#000"
    />
    <path
      d="M255.126 53.777v26.356h5.355V53.777h-5.355ZM260.481 80.133v26.356h10.09V80.133h-10.09ZM280.725 80.133v26.356h15.145V80.133h-15.145Z"
      stroke="#000"
    />
    <path
      d="M295.721 53.777v26.356h15.145V53.777h-15.145ZM311.016 80.133v26.356h20.201V80.133h-20.201ZM331.239 53.777v26.356h20.201V53.777h-20.201Z"
      stroke="#000"
    />
    <path
      d="M351.418 80.133v26.356h25.514V80.133h-25.514ZM376.932 53.777v26.356h25.514V53.777h-25.514ZM260.46 80.133v26.356h10.09V80.133h-10.09ZM270.571 53.777v26.356h10.09V53.777h-10.09ZM504.094 27.356V1h-5.356v26.356h5.356ZM498.738 53.712V27.356h-5.355v26.356h5.355ZM493.404 27.356V1h-10.09v26.356h10.09ZM473.181 27.356V1h-15.145v26.356h15.145ZM458.165 53.712V27.356h-15.146v26.356h15.146ZM442.848 27.356V1h-20.201v26.356h20.201ZM422.668 53.712V27.356h-20.201v26.356h20.201ZM402.467 27.356V1h-25.514v26.356h25.514ZM376.932 53.712V27.356h-25.514v26.356h25.514Z"
      stroke="#000"
    />
    <path
      d="M493.426 27.356V1h-10.09v26.356h10.09ZM483.293 53.712V27.356h-10.09v26.356h10.09ZM249.792 27.335V53.69h5.355V27.335h-5.355Z"
      stroke="#000"
    />
    <path
      d="M255.126 1v26.356h5.355V1h-5.355ZM260.481 27.335V53.69h10.09V27.335h-10.09ZM280.725 27.335V53.69h15.145V27.335h-15.145Z"
      stroke="#000"
    />
    <path
      d="M295.721 1v26.356h15.145V1h-15.145ZM311.016 27.335V53.69h20.201V27.335h-20.201ZM331.239 1v26.356h20.201V1h-20.201Z"
      stroke="#000"
    />
    <path
      d="M351.418 27.335V53.69h25.514V27.335h-25.514ZM376.932 1v26.356h25.514V1h-25.514ZM260.46 27.335V53.69h10.09V27.335h-10.09ZM270.571 1v26.356h10.09V1h-10.09ZM255.303 185.644v-26.357h-5.356v26.357h5.356ZM249.947 212v-26.356h-5.356V212h5.356ZM244.613 185.644v-26.357h-10.09v26.357h10.09ZM224.39 185.644v-26.357h-15.145v26.357h15.145ZM209.374 212v-26.356h-15.146V212h15.146ZM194.057 185.644v-26.357h-20.201v26.357h20.201ZM173.877 212v-26.356h-20.201V212h20.201ZM153.676 185.644v-26.357h-25.514v26.357h25.514ZM128.141 212v-26.356h-25.514V212h25.514Z"
      stroke="#000"
    />
    <path
      d="M244.634 185.644v-26.357h-10.089v26.357h10.089ZM234.502 212v-26.356h-10.09V212h10.09ZM1 185.622v26.357h5.356v-26.357H1Z"
      stroke="#000"
    />
    <path
      d="M6.334 159.287v26.357h5.356v-26.357H6.334ZM11.69 185.622v26.357h10.09v-26.357H11.69ZM31.934 185.622v26.357h15.145v-26.357H31.934Z"
      stroke="#000"
    />
    <path
      d="M46.93 159.287v26.357h15.145v-26.357H46.929ZM62.225 185.622v26.357h20.2v-26.357h-20.2ZM82.447 159.287v26.357h20.201v-26.357h-20.2Z"
      stroke="#000"
    />
    <path
      d="M102.627 185.622v26.357h25.514v-26.357h-25.514ZM128.141 159.287v26.357h25.513v-26.357h-25.513ZM11.668 185.622v26.357h10.09v-26.357h-10.09ZM21.78 159.287v26.357h10.09v-26.357H21.78ZM255.303 132.845v-26.356h-5.356v26.356h5.356ZM249.947 159.202v-26.356h-5.356v26.356h5.356ZM244.613 132.845v-26.356h-10.09v26.356h10.09ZM224.39 132.845v-26.356h-15.145v26.356h15.145ZM209.374 159.202v-26.356h-15.146v26.356h15.146ZM194.057 132.845v-26.356h-20.201v26.356h20.201ZM173.877 159.202v-26.356h-20.201v26.356h20.201ZM153.676 132.845v-26.356h-25.514v26.356h25.514ZM128.141 159.202v-26.356h-25.514v26.356h25.514Z"
      stroke="#000"
    />
    <path
      d="M244.634 132.845v-26.356h-10.089v26.356h10.089ZM234.502 159.202v-26.356h-10.09v26.356h10.09ZM1 132.845v26.357h5.356v-26.357H1Z"
      stroke="#000"
    />
    <path
      d="M6.334 106.489v26.357h5.356v-26.357H6.334ZM11.69 132.845v26.357h10.09v-26.357H11.69ZM31.934 132.845v26.357h15.145v-26.357H31.934Z"
      stroke="#000"
    />
    <path
      d="M46.93 106.489v26.357h15.145v-26.357H46.929ZM62.225 132.845v26.357h20.2v-26.357h-20.2ZM82.447 106.489v26.357h20.201v-26.357h-20.2Z"
      stroke="#000"
    />
    <path
      d="M102.627 132.845v26.357h25.514v-26.357h-25.514ZM128.141 106.489v26.357h25.513v-26.357h-25.513ZM11.668 132.845v26.357h10.09v-26.357h-10.09ZM21.78 106.489v26.357h10.09v-26.357H21.78ZM255.303 80.154V53.798h-5.356v26.356h5.356ZM249.947 106.489V80.133h-5.356v26.356h5.356Z"
      stroke="#000"
    />
    <path
      d="M244.613 80.154V53.798h-10.09v26.356h10.09ZM224.39 80.154V53.798h-15.145v26.356h15.145Z"
      stroke="#000"
    />
    <path
      d="M209.374 106.489V80.133h-15.146v26.356h15.146ZM194.057 80.154V53.798h-20.201v26.356h20.201Z"
      stroke="#000"
    />
    <path
      d="M173.877 106.489V80.133h-20.201v26.356h20.201ZM153.676 80.154V53.798h-25.514v26.356h25.514ZM128.141 106.489V80.133h-25.514v26.356h25.514ZM244.634 80.154V53.798h-10.089v26.356h10.089ZM234.502 106.489V80.133h-10.09v26.356h10.09ZM1 80.133v26.356h5.356V80.133H1Z"
      stroke="#000"
    />
    <path
      d="M6.334 53.777v26.356h5.356V53.777H6.334ZM11.69 80.133v26.356h10.09V80.133H11.69ZM31.934 80.133v26.356h15.145V80.133H31.934Z"
      stroke="#000"
    />
    <path
      d="M46.93 53.777v26.356h15.145V53.777H46.929ZM62.225 80.133v26.356h20.2V80.133h-20.2ZM82.447 53.777v26.356h20.201V53.777h-20.2Z"
      stroke="#000"
    />
    <path
      d="M102.627 80.133v26.356h25.514V80.133h-25.514ZM128.141 53.777v26.356h25.513V53.777h-25.513ZM11.668 80.133v26.356h10.09V80.133h-10.09ZM21.78 53.777v26.356h10.09V53.777H21.78ZM255.303 27.356V1h-5.356v26.356h5.356ZM249.947 53.712V27.356h-5.356v26.356h5.356ZM244.613 27.356V1h-10.09v26.356h10.09ZM224.39 27.356V1h-15.145v26.356h15.145ZM209.374 53.712V27.356h-15.146v26.356h15.146ZM194.057 27.356V1h-20.201v26.356h20.201ZM173.877 53.712V27.356h-20.201v26.356h20.201ZM153.676 27.356V1h-25.514v26.356h25.514ZM128.141 53.712V27.356h-25.514v26.356h25.514Z"
      stroke="#000"
    />
    <path
      d="M244.634 27.356V1h-10.089v26.356h10.089ZM234.502 53.712V27.356h-10.09v26.356h10.09ZM1 27.335V53.69h5.356V27.335H1Z"
      stroke="#000"
    />
    <path
      d="M6.334 1v26.356h5.356V1H6.334ZM11.69 27.335V53.69h10.09V27.335H11.69ZM31.934 27.335V53.69h15.145V27.335H31.934Z"
      stroke="#000"
    />
    <path
      d="M46.93 1v26.356h15.145V1H46.929ZM62.225 27.335V53.69h20.2V27.335h-20.2ZM82.447 1v26.356h20.201V1h-20.2Z"
      stroke="#000"
    />
    <path
      d="M102.627 27.335V53.69h25.514V27.335h-25.514ZM128.141 1v26.356h25.513V1h-25.513ZM11.668 27.335V53.69h10.09V27.335h-10.09ZM21.78 1v26.356h10.09V1H21.78Z"
      stroke="#000"
    />
  </svg>
);

export default SvgAbstractVerticalGrid;
