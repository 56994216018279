import * as React from 'react';

const SvgEyxoFeedback = (props) => (
  <svg viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#EyxoFeedback_svg__a)">
      <path
        d="M80.005 0A80.004 80.004 0 1 0 160 79.995 79.995 79.995 0 0 0 80.005 0Zm0 158.888a78.893 78.893 0 1 1 78.893-78.893 78.88 78.88 0 0 1-48.7 72.891 78.879 78.879 0 0 1-30.193 6.002Z"
        fill="#1A1A1A"
        stroke="#000"
      />
      <path
        d="M80.005 55.202a7.359 7.359 0 1 0 0 14.717 7.359 7.359 0 0 0 0-14.717Zm0 12.505a5.146 5.146 0 1 1-.02-10.292 5.146 5.146 0 0 1 .02 10.292ZM51.474 69.478a7.221 7.221 0 1 0-.019 14.442 7.221 7.221 0 0 0 .02-14.442Zm0 12.219a4.998 4.998 0 1 1 0-9.996 4.998 4.998 0 0 1 0 9.996ZM108.004 69.478a7.222 7.222 0 1 0-.018 14.443 7.222 7.222 0 0 0 .018-14.444Zm0 12.219a4.997 4.997 0 1 1 0-9.994 4.997 4.997 0 0 1 0 9.994ZM80.123 74.22a13.252 13.252 0 0 1 11.62 7.172H68.464a13.015 13.015 0 0 1 11.658-7.172Zm0-2.224a15.23 15.23 0 0 0-14.826 11.61h29.652a15.457 15.457 0 0 0-14.826-11.61Z"
        fill="#000"
      />
      <path
        d="M63.585 83.91h32.87l11.176 20.888h2.518l-12.366-23.1H62.257l-12.406 23.1h2.518l11.216-20.887Z"
        fill="#000"
      />
      <path
        d="M106.677 88.367a12.96 12.96 0 0 1 11.57 7.221H105.28l-3.385-6.316a12.798 12.798 0 0 1 4.782-.905Zm0-2.223a14.895 14.895 0 0 0-7.812 2.174l5.087 9.494h17.463a15.251 15.251 0 0 0-14.758-11.668h.02ZM53.314 88.367c1.637-.007 3.26.3 4.782.905l-3.385 6.316H41.744a12.958 12.958 0 0 1 11.57-7.22Zm0-2.223a15.249 15.249 0 0 0-14.757 11.668H56.02l5.086-9.494a14.895 14.895 0 0 0-7.812-2.174h.02Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="EyxoFeedback_svg__a">
        <path fill="#fff" d="M0 0h160v160H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEyxoFeedback;
