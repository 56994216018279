import React from 'react';

import FooterContact from 'components/molecules/footer-contact';
import FooterInfo from 'components/molecules/footer-info';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism SectionFooter
 * 
 * Footer
 */
function SectionFooter (props) {
  const {
    id = null,
    className = "",
    title = '',
    description = '',
    button = '',
    buttonUrl = '',
    list = [],
    showContactInfo = true,
    modalDescription,
    isModalOpen = false,
    handleOpenCloseModal,
    ...other
  } = props;

  return <div
    className={`${css["organism__section-footer-container"]} ${className}`}
    {...other}
  >
    <div
      className={css["content"]}
      data-content-wrapper="true"
    >
      {
        showContactInfo &&
        <FooterContact
          id={id}
          title={title}
          description={description}
          button={button}
          buttonUrl={buttonUrl}
          className={css["footer-contact"]}
          handleOpenCloseModal={handleOpenCloseModal}
        />
      }

      <FooterInfo
        list={list}
        modalDescription={modalDescription}
        className={css["footer-info"]}
        data-contact={!showContactInfo}
        isModalOpen={isModalOpen}
        handleOpenCloseModal={handleOpenCloseModal}
      />
    </div>
    <div className={css["base"]} />
  </div>;
}

export default SectionFooter;
