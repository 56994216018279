import * as React from 'react';

const SvgAbstractCirclesGroup = (props) => (
  <svg viewBox="0 0 410 793" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M409.006 204.503C409.006 91.559 317.447 0 204.503 0 91.559 0 0 91.559 0 204.503c0 112.944 91.559 204.503 204.503 204.503 112.944 0 204.503-91.559 204.503-204.503Z"
      fill="#000"
    />
    <path
      d="M348.514 540.867a196.373 196.373 0 0 0 7.995-8.485L68.975 244.877c-2.886 2.569-5.772 5.224-8.485 7.994-2.712 2.771-5.772 5.946-8.456 9.005L339.51 549.323a205.892 205.892 0 0 0 9.004-8.456ZM407.506 413.016c.89-11.317.841-22.688-.145-33.997L222.339 194.025a202.357 202.357 0 0 0-33.968 0l219.135 218.991ZM219.768 599.856 1.441 381.529a204.713 204.713 0 0 0 .288 34.171l183.868 183.868a204.785 204.785 0 0 0 34.171.288ZM10.39 458.442a203.93 203.93 0 0 0 132.438 132.439L10.389 458.442ZM264.793 202.336 399.05 336.593a203.746 203.746 0 0 0-134.257-134.257ZM299.337 577.143a197.636 197.636 0 0 0 21.067-12.785L37.027 280.981a197.486 197.486 0 0 0-12.67 21.039l274.98 275.123ZM249.465 595.528a206.401 206.401 0 0 0 26.292-7.822L13.678 325.686a206.332 206.332 0 0 0-7.821 26.291l243.608 243.551ZM395.066 468.831a204.635 204.635 0 0 0 7.908-26.234L158.76 198.412a207.306 207.306 0 0 0-26.234 7.908l262.54 262.511ZM371.602 513.276a207.279 207.279 0 0 0 12.842-20.981L109.032 216.883a205.77 205.77 0 0 0-21.01 12.842l283.58 283.551ZM92.554 759.508 34.257 701.21a206.19 206.19 0 0 0 58.297 58.298ZM153.622 786.524 7.244 640.261c1.039 4.011 2.164 7.994 3.463 11.948l130.967 130.967a300.764 300.764 0 0 0 11.948 3.348ZM193.795 792.67 1.096 599.856c.174 3.059.376 6.118.693 9.177l182.829 182.829c3.059.366 6.118.635 9.177.808ZM226.869 791.717 2.049 566.897c-.289 2.568-.52 5.166-.721 7.763l217.749 217.778c2.626-.202 5.224-.461 7.792-.721ZM255.642 786.438 7.445 538.24c-.577 2.28-1.154 4.56-1.645 6.84l243.089 243.118c2.193-.75 4.473-1.183 6.753-1.76ZM281.212 777.98 15.699 512.467a179.008 179.008 0 0 0-2.366 6.148l261.674 261.674c2.136-.692 4.185-1.5 6.205-2.309Z"
      fill="#000"
    />
    <path
      d="M304.301 766.898 26.955 489.581c-1.01 1.818-2.02 3.665-2.886 5.512l274.806 274.806a200.82 200.82 0 0 1 5.426-3.001ZM324.877 753.564 40.201 468.888a194.577 194.577 0 0 0-3.55 4.964l283.263 283.233c1.702-1.125 3.318-2.308 4.963-3.521ZM343.464 738.123 55.642 450.301c-1.356 1.472-2.713 2.886-4.04 4.445l287.331 287.418c1.587-1.328 3.059-2.684 4.531-4.041ZM360.059 720.663 73.074 433.707a192.265 192.265 0 0 0-4.53 3.954L356.25 725.223c1.212-1.501 2.511-3.001 3.809-4.56ZM374.633 701.214 92.555 419.106a198.788 198.788 0 0 0-5.08 3.463L371.2 706.293c1.183-1.674 2.367-3.377 3.434-5.079ZM387.07 679.595 114.169 406.694a516.743 516.743 0 0 0-5.628 2.886l275.614 275.614c.953-1.847 1.963-3.723 2.915-5.599ZM397.058 655.556 138.182 396.71a135.361 135.361 0 0 0-6.233 2.251l262.858 262.858c.779-2.078 1.529-4.242 2.251-6.263ZM404.271 628.715l-239.25-239.25c-2.338.462-4.676.952-6.984 1.501L402.77 635.7a226.91 226.91 0 0 0 1.501-6.985ZM407.909 598.326 195.412 385.858c-2.655.115-5.31.288-7.966.519L407.389 606.32c.231-2.655.404-5.31.52-7.994ZM406.381 562.771 230.998 387.388a211.927 211.927 0 0 0-9.524-1.011l185.917 185.917c-.26-3.174-.606-6.349-1.01-9.523ZM395.066 517.402 276.336 398.7c-4.243-1.587-8.514-2.886-12.843-4.329l136.046 135.902c-1.443-4.329-2.886-8.6-4.473-12.871Z"
      fill="#000"
    />
  </svg>
);

export default SvgAbstractCirclesGroup;
