import React from 'react';

import { Company } from 'components/icons';

import Typography from 'components/atoms/typography';
import SocialMedia from 'components/molecules/social-media';
import Modal from 'components/molecules/modal';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule FooterInfo
 * 
 * Footer info
 */
function FooterInfo (props) {
  const {
    className = "",
    modalDescription,
    list = [],
    isModalOpen = false,
    handleOpenCloseModal,
    ...other
  } = props;

  return <div
    className={`${css["molecule__footer-info-container"]} ${className}`}
    {...other}
  >
    <div className={css["content"]}>
      <Company className={css["icon"]} />
      <div className={css["info"]}>
        <Typography color="lightgray">
          Copyright ©{new Date().getFullYear()} Eyxo.&nbsp;
        </Typography>
        <Typography color="lightgray">All rights reserved.</Typography>
        <button
          onClick={() => handleOpenCloseModal("privacy-policies")}
          title="Política de Privacidade"
          className={css["btn"]}
          alt="Política de Privacidade"
        >
          <Typography color="lightgray" className={css["privacy-policies"]}>
            Política de Privacidade.
          </Typography>
        </button>
      </div>
    </div>
    <SocialMedia list={list} className={css["social"]}/>
    {
      isModalOpen && modalDescription &&
      <Modal onClick={handleOpenCloseModal} className={css["modal"]}>
        {modalDescription}
      </Modal>
    }
  </div>;
}

export default FooterInfo;
