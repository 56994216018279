import * as React from 'react';

const SvgAbstractGrid = (props) => (
  <svg viewBox="0 0 270 641" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M235.524 1h-33.476v6.802h33.476V1ZM269 7.802h-33.476v6.803H269V7.802ZM235.524 14.577h-33.476v12.816h33.476V14.577ZM235.524 40.263h-33.476V59.5h33.476V40.263ZM269 59.336h-33.476v19.237H269V59.336ZM235.524 78.791h-33.476v25.658h33.476V78.791ZM269 104.422h-33.476v25.658H269v-25.658ZM235.524 130.08h-33.476v32.406h33.476V130.08ZM269 162.514h-33.476v32.406H269v-32.406Z"
      stroke="#000"
    />
    <path
      d="M235.524 14.55h-33.476v12.816h33.476V14.55ZM269 27.42h-33.476v12.816H269V27.42ZM235.497 324h33.476v-6.802h-33.476V324Z"
      stroke="#000"
    />
    <path
      d="M202.048 317.225h33.476v-6.802h-33.476v6.802ZM235.497 310.423h33.476v-12.816h-33.476v12.816ZM235.497 284.71h33.476v-19.237h-33.476v19.237Z"
      stroke="#000"
    />
    <path
      d="M202.048 265.664h33.476v-19.237h-33.476v19.237ZM235.497 246.236h33.476v-25.658h-33.476v25.658ZM202.048 220.551h33.476v-25.659h-33.476v25.659Z"
      stroke="#000"
    />
    <path d="M235.497 194.92h33.476v-32.406h-33.476v32.406Z" stroke="#000" />
    <path
      d="M202.048 162.514h33.476v-32.406h-33.476v32.406ZM235.497 310.45h33.476v-12.816h-33.476v12.816ZM202.048 297.607h33.476v-12.815h-33.476v12.815ZM168.463 1h-33.477v6.802h33.477V1ZM201.939 7.802h-33.476v6.803h33.476V7.802ZM168.463 14.577h-33.477v12.816h33.477V14.577ZM168.463 40.263h-33.477V59.5h33.477V40.263ZM201.939 59.336h-33.476v19.237h33.476V59.336ZM168.463 78.791h-33.477v25.658h33.477V78.791ZM201.939 104.422h-33.476v25.658h33.476v-25.658ZM168.463 130.08h-33.477v32.406h33.477V130.08ZM201.939 162.514h-33.476v32.406h33.476v-32.406Z"
      stroke="#000"
    />
    <path
      d="M168.463 14.55h-33.477v12.816h33.477V14.55ZM201.939 27.42h-33.476v12.816h33.476V27.42ZM168.462 324h33.477v-6.802h-33.477V324Z"
      stroke="#000"
    />
    <path
      d="M134.986 317.225h33.477v-6.802h-33.477v6.802ZM168.462 310.423h33.477v-12.816h-33.477v12.816ZM168.462 284.71h33.477v-19.237h-33.477v19.237Z"
      stroke="#000"
    />
    <path
      d="M134.986 265.664h33.477v-19.237h-33.477v19.237ZM168.462 246.236h33.477v-25.658h-33.477v25.658ZM134.986 220.551h33.477v-25.659h-33.477v25.659Z"
      stroke="#000"
    />
    <path d="M168.462 194.92h33.477v-32.406h-33.477v32.406Z" stroke="#000" />
    <path
      d="M134.986 162.514h33.477v-32.406h-33.477v32.406ZM168.462 310.45h33.477v-12.816h-33.477v12.816ZM134.986 297.607h33.477v-12.815h-33.477v12.815ZM101.537 1H68.061v6.802h33.476V1ZM134.986 7.802H101.51v6.803h33.476V7.802Z"
      stroke="#000"
    />
    <path
      d="M101.537 14.577H68.061v12.816h33.476V14.577ZM101.537 40.263H68.061V59.5h33.476V40.263Z"
      stroke="#000"
    />
    <path
      d="M134.986 59.336H101.51v19.237h33.476V59.336ZM101.537 78.791H68.061v25.658h33.476V78.791Z"
      stroke="#000"
    />
    <path
      d="M134.986 104.422H101.51v25.658h33.476v-25.658ZM101.537 130.08H68.061v32.406h33.476V130.08ZM134.986 162.514H101.51v32.406h33.476v-32.406ZM101.537 14.55H68.061v12.816h33.476V14.55ZM134.986 27.42H101.51v12.816h33.476V27.42ZM101.51 324h33.476v-6.802H101.51V324ZM68.034 317.225h33.476v-6.802H68.034v6.802ZM101.51 310.423h33.476v-12.816H101.51v12.816ZM101.51 284.71h33.476v-19.237H101.51v19.237ZM68.034 265.664h33.476v-19.237H68.034v19.237ZM101.51 246.236h33.476v-25.658H101.51v25.658ZM68.034 220.551h33.476v-25.659H68.034v25.659Z"
      stroke="#000"
    />
    <path
      d="M101.51 194.92h33.476v-32.406H101.51v32.406ZM68.034 162.514h33.476v-32.406H68.034v32.406ZM101.51 310.45h33.476v-12.816H101.51v12.816ZM68.034 297.607h33.476v-12.815H68.034v12.815ZM34.476 1H1v6.802h33.476V1ZM67.952 7.802H34.476v6.803h33.476V7.802ZM34.476 14.577H1v12.816h33.476V14.577ZM34.476 40.263H1V59.5h33.476V40.263ZM67.952 59.336H34.476v19.237h33.476V59.336ZM34.476 78.791H1v25.658h33.476V78.791ZM67.952 104.422H34.476v25.658h33.476v-25.658ZM34.476 130.08H1v32.406h33.476V130.08ZM67.952 162.514H34.476v32.406h33.476v-32.406Z"
      stroke="#000"
    />
    <path
      d="M34.476 14.55H1v12.816h33.476V14.55ZM67.952 27.42H34.476v12.816h33.476V27.42ZM34.449 324h33.476v-6.802H34.45V324Z"
      stroke="#000"
    />
    <path
      d="M1 317.225h33.476v-6.802H1v6.802ZM34.449 310.423h33.476v-12.816H34.45v12.816ZM34.449 284.71h33.476v-19.237H34.45v19.237Z"
      stroke="#000"
    />
    <path
      d="M1 265.664h33.476v-19.237H1v19.237ZM34.449 246.236h33.476v-25.658H34.45v25.658ZM1 220.551h33.476v-25.659H1v25.659Z"
      stroke="#000"
    />
    <path d="M34.449 194.92h33.476v-32.406H34.45v32.406Z" stroke="#000" />
    <path
      d="M1 162.514h33.476v-32.406H1v32.406ZM34.449 310.45h33.476v-12.816H34.45v12.816ZM1 297.607h33.476v-12.815H1v12.815ZM235.524 317h-33.476v6.802h33.476V317ZM269 323.802h-33.476v6.803H269v-6.803ZM235.524 330.577h-33.476v12.816h33.476v-12.816ZM235.524 356.263h-33.476V375.5h33.476v-19.237ZM269 375.336h-33.476v19.237H269v-19.237ZM235.524 394.791h-33.476v25.658h33.476v-25.658ZM269 420.422h-33.476v25.658H269v-25.658ZM235.524 446.08h-33.476v32.406h33.476V446.08ZM269 478.514h-33.476v32.406H269v-32.406Z"
      stroke="#000"
    />
    <path
      d="M235.524 330.55h-33.476v12.816h33.476V330.55ZM269 343.42h-33.476v12.816H269V343.42ZM235.497 640h33.476v-6.802h-33.476V640Z"
      stroke="#000"
    />
    <path
      d="M202.048 633.225h33.476v-6.802h-33.476v6.802ZM235.497 626.423h33.476v-12.816h-33.476v12.816ZM235.497 600.71h33.476v-19.237h-33.476v19.237Z"
      stroke="#000"
    />
    <path
      d="M202.048 581.664h33.476v-19.237h-33.476v19.237ZM235.497 562.236h33.476v-25.658h-33.476v25.658ZM202.048 536.551h33.476v-25.659h-33.476v25.659Z"
      stroke="#000"
    />
    <path
      d="M235.497 510.92h33.476v-32.406h-33.476v32.406ZM202.048 478.514h33.476v-32.406h-33.476v32.406ZM235.497 626.45h33.476v-12.816h-33.476v12.816ZM202.048 613.607h33.476v-12.815h-33.476v12.815ZM168.463 317h-33.477v6.802h33.477V317ZM201.939 323.802h-33.476v6.803h33.476v-6.803ZM168.463 330.577h-33.477v12.816h33.477v-12.816ZM168.463 356.263h-33.477V375.5h33.477v-19.237ZM201.939 375.336h-33.476v19.237h33.476v-19.237ZM168.463 394.791h-33.477v25.658h33.477v-25.658ZM201.939 420.422h-33.476v25.658h33.476v-25.658ZM168.463 446.08h-33.477v32.406h33.477V446.08ZM201.939 478.514h-33.476v32.406h33.476v-32.406Z"
      stroke="#000"
    />
    <path
      d="M168.463 330.55h-33.477v12.816h33.477V330.55ZM201.939 343.42h-33.476v12.816h33.476V343.42ZM168.462 640h33.477v-6.802h-33.477V640Z"
      stroke="#000"
    />
    <path
      d="M134.986 633.225h33.477v-6.802h-33.477v6.802ZM168.462 626.423h33.477v-12.816h-33.477v12.816ZM168.462 600.71h33.477v-19.237h-33.477v19.237Z"
      stroke="#000"
    />
    <path
      d="M134.986 581.664h33.477v-19.237h-33.477v19.237ZM168.462 562.236h33.477v-25.658h-33.477v25.658ZM134.986 536.551h33.477v-25.659h-33.477v25.659Z"
      stroke="#000"
    />
    <path
      d="M168.462 510.92h33.477v-32.406h-33.477v32.406ZM134.986 478.514h33.477v-32.406h-33.477v32.406ZM168.462 626.45h33.477v-12.816h-33.477v12.816ZM134.986 613.607h33.477v-12.815h-33.477v12.815ZM101.537 317H68.061v6.802h33.476V317ZM134.986 323.802H101.51v6.803h33.476v-6.803Z"
      stroke="#000"
    />
    <path
      d="M101.537 330.577H68.061v12.816h33.476v-12.816ZM101.537 356.263H68.061V375.5h33.476v-19.237Z"
      stroke="#000"
    />
    <path
      d="M134.986 375.336H101.51v19.237h33.476v-19.237ZM101.537 394.791H68.061v25.658h33.476v-25.658Z"
      stroke="#000"
    />
    <path d="M134.986 420.422H101.51v25.658h33.476v-25.658Z" stroke="#000" />
    <path
      d="M101.537 446.08H68.061v32.406h33.476V446.08ZM134.986 478.514H101.51v32.406h33.476v-32.406ZM101.537 330.55H68.061v12.816h33.476V330.55ZM134.986 343.42H101.51v12.816h33.476V343.42ZM101.51 640h33.476v-6.802H101.51V640ZM68.034 633.225h33.476v-6.802H68.034v6.802ZM101.51 626.423h33.476v-12.816H101.51v12.816ZM101.51 600.71h33.476v-19.237H101.51v19.237ZM68.034 581.664h33.476v-19.237H68.034v19.237ZM101.51 562.236h33.476v-25.658H101.51v25.658ZM68.034 536.551h33.476v-25.659H68.034v25.659Z"
      stroke="#000"
    />
    <path
      d="M101.51 510.92h33.476v-32.406H101.51v32.406ZM68.034 478.514h33.476v-32.406H68.034v32.406ZM101.51 626.45h33.476v-12.816H101.51v12.816ZM68.034 613.607h33.476v-12.815H68.034v12.815ZM34.476 317H1v6.802h33.476V317ZM67.952 323.802H34.476v6.803h33.476v-6.803ZM34.476 330.577H1v12.816h33.476v-12.816ZM34.476 356.263H1V375.5h33.476v-19.237ZM67.952 375.336H34.476v19.237h33.476v-19.237ZM34.476 394.791H1v25.658h33.476v-25.658ZM67.952 420.422H34.476v25.658h33.476v-25.658ZM34.476 446.08H1v32.406h33.476V446.08ZM67.952 478.514H34.476v32.406h33.476v-32.406Z"
      stroke="#000"
    />
    <path
      d="M34.476 330.55H1v12.816h33.476V330.55ZM67.952 343.42H34.476v12.816h33.476V343.42ZM34.449 640h33.476v-6.802H34.45V640Z"
      stroke="#000"
    />
    <path
      d="M1 633.225h33.476v-6.802H1v6.802ZM34.449 626.423h33.476v-12.816H34.45v12.816ZM34.449 600.71h33.476v-19.237H34.45v19.237Z"
      stroke="#000"
    />
    <path
      d="M1 581.664h33.476v-19.237H1v19.237ZM34.449 562.236h33.476v-25.658H34.45v25.658ZM1 536.551h33.476v-25.659H1v25.659Z"
      stroke="#000"
    />
    <path
      d="M34.449 510.92h33.476v-32.406H34.45v32.406ZM1 478.514h33.476v-32.406H1v32.406ZM34.449 626.45h33.476v-12.816H34.45v12.816ZM1 613.607h33.476v-12.815H1v12.815Z"
      stroke="#000"
    />
  </svg>
);

export default SvgAbstractGrid;
