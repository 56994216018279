import * as React from 'react';

const SvgLogoHorizontal = (props) => (
  <svg viewBox="0 0 160 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m119.861 55.089-18.749-18.98 31.393-31.781L128.287.05l-31.393 31.78L65.444 0l-4.222 4.273L92.67 36.108 66.278 62.826 44.36 40.638c7.656-9.862 7.003-24.181-1.979-33.273a24.614 24.614 0 0 0-35.11 0c-9.695 9.814-9.695 25.729 0 35.543a24.601 24.601 0 0 0 32.868 2.003L62.056 67.1 33.777 95.727 37.998 100l58.893-59.619 18.749 18.98c-7.656 9.863-6.999 24.182 1.979 33.274a24.613 24.613 0 0 0 35.11 0c9.695-9.814 9.695-25.729 0-35.543a24.601 24.601 0 0 0-32.868-2.003ZM11.492 38.632c-6.647-6.73-7.29-17.228-1.94-24.692l26.331 26.657a18.688 18.688 0 0 1-24.39-1.965Zm28.61-2.305L13.768 9.67a18.692 18.692 0 0 1 24.391 1.965c6.647 6.726 7.291 17.227 1.941 24.692ZM148.51 88.362a18.696 18.696 0 0 1-26.668 0c-7.364-7.455-7.364-19.542 0-26.997a18.696 18.696 0 0 1 26.668 0c7.364 7.455 7.364 19.542 0 26.997Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default SvgLogoHorizontal;
