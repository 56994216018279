import React from 'react';

import Typography from 'components/atoms/typography';

import {
  Button as ButtonContainer,
  ButtonMobile,
  ArrowLeft,
  ArrowRight
} from 'components/icons';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom Button
 * 
 * Button
 */
function Button (props) {
  const {
    className = "",
    alt = "Button",
    theme = "dark", // white, gray and dark
    parentTheme = "gray", // white, gray and dark
    textColor = "white", // white, gray and dark
    rounded = null,
    arrow = "right",
    onClick,
    children,
    ...other
  } = props;

  return <button
    className={`${css["atom__button-container"]} ${className}`}
    data-rounded={rounded}
    onClick={onClick}
    alt={alt}
    title={alt}
    {...other}
  >
    {
      rounded ? (
        <>
          <ButtonMobile
            className={css["round-mobile"]}
            data-arrow={arrow}
            data-theme={theme}
            data-parent-theme={parentTheme}
          />
          <ButtonContainer
            className={css["round"]}
            data-arrow={arrow}
            data-theme={theme}
            data-parent-theme={parentTheme}
          />
        </>
      ) : (
        <>
          {
            arrow === "right" ?
              <ArrowRight className={css["icon"]} /> :
              <ArrowLeft className={css["icon"]} />
          }
        </>
      )
    }

    <Typography type="helvetica" data-theme={textColor} className={css["text"]}>
      {children}
    </Typography>
  </button>;
}

export default Button;
