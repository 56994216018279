import React, { useState, useEffect } from 'react';
import Link from 'next/link';

import { LogoHorizontal, Concept } from 'components/icons';

import MenuButton from 'components/atoms/menu-button';
import Title from 'components/atoms/title';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism SectionMenu
 * 
 * Menu containing page links
 */
function SectionMenu (props) {
  const {
    className = "",
    list = [],
    ...other
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(oldState => !oldState);
  };

  useEffect(() => {
    const [body] = document.getElementsByTagName('body');
    isOpen ?
      body.classList.add("modal-open") :
      body.classList.remove("modal-open");
  }, [isOpen]);

  return <div
    className={`${css["organism__section-menu-container"]} ${className}`}
    data-menu={isOpen}
    {...other}
  >
    <div
      className={css["content"]}
      data-content-wrapper="true"
      data-menu={isOpen}
    >
      <div className={css["nav"]}>
        <Link href="/" passHref>
          <a title="Eyxo">
            <LogoHorizontal
              onClick={() => isOpen ? handleClick() : null}
              className={css["logo"]}
              data-menu={isOpen}/>
          </a>
        </Link>
        <Concept className={css["concept"]} data-menu={isOpen}/>
        <ul className={css["list"]} data-menu={isOpen}>
          {
            list.length > 0 &&
            list.map(({url,title}, index) => (
              <li key={index}>
                <Link href={url} passHref>
                  <a title={title}>
                    <Title level="2" onClick={handleClick}>{title}</Title>
                  </a>
                </Link>
              </li>
            ))
          }
        </ul>
      </div>
      <MenuButton
        onClick={handleClick}
        data-menu={isOpen}
      />
    </div>
  </div>;
}

export default SectionMenu;
