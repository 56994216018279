import * as React from 'react';

const SvgArrowRight = (props) => (
  <svg viewBox="0 0 69 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="#1A1A1A" d="M57.981 0 68.38 10.507l-1.981 2.002L56 2.002z" />
    <path fill="#1A1A1A" d="M68.38 10.492 57.98 21l-1.98-2.001L66.398 8.49z" />
    <path fill="#1A1A1A" d="M0 9.146h66v3.048H0z" />
  </svg>
);

export default SvgArrowRight;
