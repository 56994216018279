import React from 'react';
import Link from 'next/link';

import * as Icons from 'components/icons';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule SocialMedia
 * 
 * Group of social media links
 */
function SocialMedia (props) {
  const {
    className = "",
    list = [],
    ...other
  } = props;

  return <div
    className={`${css["molecule__social-media-container"]} ${className}`}
    {...other}
  >
    {
      list.length > 0 &&
      list.map(({iconName, alt = '', link}, index) => {
        const Icon = Icons[iconName];

        if (!Icon) {
          console.warn('No icon found for ' + iconName, list[index]);
          return null;
        }

        return <Link passHref href={link} key={index}>
          <a
            title={alt || iconName}
            target="_blank"
            rel="noreferrer"
          >
            <Icon className={css["icon"]}/>
          </a>
        </Link>;
      })
    }
  </div>;
}

export default SocialMedia;
