import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom Title
 * 
 * The titles for the page.
 */
function Title (props) {
  const {
    className = "",
    level = "1",
    color = "dark",
    bold = null,
    children,
    ...other
  } = props;

  const El = `h${level}`;

  return <El
    className={`${css["atom__title-container"]} ${className}`}
    data-level={level}
    data-bold={bold}
    data-color={color}
    {...other}
  >
    {children}
  </El>;
}

export default Title;
