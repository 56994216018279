import * as React from 'react';

const SvgAbstractHugeGrid = (props) => (
  <svg viewBox="0 0 587 700" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M257.178 1h-36.567v7.43h36.567V1ZM293.745 8.43h-36.567v7.43h36.567V8.43ZM257.178 15.831h-36.567V29.83h36.567V15.83ZM257.178 43.888h-36.567v21.013h36.567V43.888ZM293.745 64.723h-36.567v21.013h36.567V64.723ZM257.178 85.974h-36.567V114h36.567V85.973ZM293.745 113.971h-36.567v28.028h36.567v-28.028ZM257.178 141.998h-36.567v35.399h36.567v-35.399ZM293.745 177.426h-36.567v35.399h36.567v-35.399Z"
      stroke="#CBCFCE"
    />
    <path
      d="M257.178 15.801h-36.567V29.8h36.567V15.801ZM293.745 29.86h-36.567v13.998h36.567V29.86ZM257.148 353.823h36.567v-7.43h-36.567v7.43Z"
      stroke="#CBCFCE"
    />
    <path
      d="M220.611 346.423h36.567v-7.431h-36.567v7.431ZM257.148 338.992h36.567v-13.999h-36.567v13.999ZM257.148 310.906h36.567v-21.014h-36.567v21.014Z"
      stroke="#CBCFCE"
    />
    <path
      d="M220.611 290.101h36.567v-21.014h-36.567v21.014ZM257.148 268.879h36.567v-28.027h-36.567v28.027ZM220.611 240.822h36.567v-28.027h-36.567v28.027Z"
      stroke="#CBCFCE"
    />
    <path
      d="M257.148 212.825h36.567v-35.398h-36.567v35.398ZM220.611 177.426h36.567v-35.398h-36.567v35.398ZM257.148 339.022h36.567v-13.999h-36.567v13.999ZM220.611 324.994h36.567v-13.999h-36.567v13.999ZM183.925 1h-36.567v7.43h36.567V1ZM220.492 8.43h-36.567v7.43h36.567V8.43Z"
      stroke="#CBCFCE"
    />
    <path
      d="M183.925 15.831h-36.567V29.83h36.567V15.83ZM183.925 43.888h-36.567v21.013h36.567V43.888Z"
      stroke="#CBCFCE"
    />
    <path
      d="M220.492 64.723h-36.567v21.013h36.567V64.723ZM183.925 85.974h-36.567V114h36.567V85.973Z"
      stroke="#CBCFCE"
    />
    <path d="M220.492 113.971h-36.567v28.028h36.567v-28.028Z" stroke="#CBCFCE" />
    <path
      d="M183.925 141.998h-36.567v35.399h36.567v-35.399ZM220.492 177.426h-36.567v35.399h36.567v-35.399ZM183.925 15.801h-36.567V29.8h36.567V15.801ZM220.492 29.86h-36.567v13.998h36.567V29.86ZM183.925 353.823h36.567v-7.43h-36.567v7.43ZM147.358 346.423h36.567v-7.431h-36.567v7.431ZM183.925 338.992h36.567v-13.999h-36.567v13.999ZM183.925 310.906h36.567v-21.014h-36.567v21.014ZM147.358 290.101h36.567v-21.014h-36.567v21.014ZM183.925 268.879h36.567v-28.027h-36.567v28.027Z"
      stroke="#CBCFCE"
    />
    <path
      d="M147.358 240.822h36.567v-28.027h-36.567v28.027ZM183.925 212.825h36.567v-35.398h-36.567v35.398ZM147.358 177.426h36.567v-35.398h-36.567v35.398ZM183.925 339.022h36.567v-13.999h-36.567v13.999ZM147.358 324.994h36.567v-13.999h-36.567v13.999ZM110.82 1H74.253v7.43h36.567V1ZM147.358 8.43H110.79v7.43h36.568V8.43Z"
      stroke="#CBCFCE"
    />
    <path
      d="M110.82 15.831H74.253V29.83h36.567V15.83ZM110.82 43.888H74.253v21.013h36.567V43.888Z"
      stroke="#CBCFCE"
    />
    <path
      d="M147.358 64.723H110.79v21.013h36.568V64.723ZM110.82 85.974H74.253V114h36.567V85.973Z"
      stroke="#CBCFCE"
    />
    <path d="M147.358 113.971H110.79v28.028h36.568v-28.028Z" stroke="#CBCFCE" />
    <path
      d="M110.82 141.998H74.253v35.399h36.567v-35.399ZM147.358 177.426H110.79v35.399h36.568v-35.399ZM110.82 15.801H74.253V29.8h36.567V15.801ZM147.358 29.86H110.79v13.998h36.568V29.86ZM110.79 353.823h36.568v-7.43H110.79v7.43Z"
      stroke="#CBCFCE"
    />
    <path
      d="M74.224 346.423h36.567v-7.431H74.224v7.431ZM110.79 338.992h36.568v-13.999H110.79v13.999ZM110.79 310.906h36.568v-21.014H110.79v21.014Z"
      stroke="#CBCFCE"
    />
    <path
      d="M74.224 290.101h36.567v-21.014H74.224v21.014ZM110.79 268.879h36.568v-28.027H110.79v28.027ZM74.224 240.822h36.567v-28.027H74.224v28.027Z"
      stroke="#CBCFCE"
    />
    <path
      d="M110.79 212.825h36.568v-35.398H110.79v35.398ZM74.224 177.426h36.567v-35.398H74.224v35.398ZM110.79 339.022h36.568v-13.999H110.79v13.999ZM74.224 324.994h36.567v-13.999H74.224v13.999ZM37.567 1H1v7.43h36.567V1ZM74.134 8.43H37.567v7.43h36.567V8.43ZM37.567 15.831H1V29.83h36.567V15.83ZM37.567 43.888H1v21.013h36.567V43.888ZM74.134 64.723H37.567v21.013h36.567V64.723ZM37.567 85.974H1V114h36.567V85.973ZM74.134 113.971H37.567v28.028h36.567v-28.028ZM37.567 141.998H1v35.399h36.567v-35.399ZM74.134 177.426H37.567v35.399h36.567v-35.399Z"
      stroke="#CBCFCE"
    />
    <path
      d="M37.567 15.801H1V29.8h36.567V15.801ZM74.134 29.86H37.567v13.998h36.567V29.86ZM37.537 353.823h36.567v-7.43H37.537v7.43Z"
      stroke="#CBCFCE"
    />
    <path
      d="M1 346.423h36.567v-7.431H1v7.431ZM37.537 338.992h36.567v-13.999H37.537v13.999ZM37.537 310.906h36.567v-21.014H37.537v21.014Z"
      stroke="#CBCFCE"
    />
    <path
      d="M1 290.101h36.567v-21.014H1v21.014ZM37.537 268.879h36.567v-28.027H37.537v28.027ZM1 240.822h36.567v-28.027H1v28.027Z"
      stroke="#CBCFCE"
    />
    <path
      d="M37.537 212.825h36.567v-35.398H37.537v35.398ZM1 177.426h36.567v-35.398H1v35.398ZM37.537 339.022h36.567v-13.999H37.537v13.999ZM1 324.994h36.567v-13.999H1v13.999ZM257.178 346.177h-36.567v7.43h36.567v-7.43ZM293.745 353.607h-36.567v7.431h36.567v-7.431ZM257.178 361.008h-36.567v13.999h36.567v-13.999ZM257.178 389.065h-36.567v21.013h36.567v-21.013ZM293.745 409.899h-36.567v21.014h36.567v-21.014ZM257.178 431.15h-36.567v28.028h36.567V431.15ZM293.745 459.148h-36.567v28.027h36.567v-28.027ZM257.178 487.175h-36.567v35.398h36.567v-35.398ZM293.745 522.603h-36.567v35.399h36.567v-35.399Z"
      stroke="#CBCFCE"
    />
    <path
      d="M257.178 360.978h-36.567v13.999h36.567v-13.999ZM293.745 375.036h-36.567v13.999h36.567v-13.999ZM257.148 699h36.567v-7.43h-36.567V699Z"
      stroke="#CBCFCE"
    />
    <path
      d="M220.611 691.599h36.567v-7.43h-36.567v7.43ZM257.148 684.169h36.567V670.17h-36.567v13.999ZM257.148 656.082h36.567v-21.013h-36.567v21.013Z"
      stroke="#CBCFCE"
    />
    <path
      d="M220.611 635.277h36.567v-21.013h-36.567v21.013ZM257.148 614.056h36.567v-28.027h-36.567v28.027ZM220.611 585.999h36.567v-28.027h-36.567v28.027Z"
      stroke="#CBCFCE"
    />
    <path
      d="M257.148 558.002h36.567v-35.399h-36.567v35.399ZM220.611 522.603h36.567v-35.398h-36.567v35.398ZM257.148 684.199h36.567V670.2h-36.567v13.999ZM220.611 670.17h36.567v-13.998h-36.567v13.998ZM183.925 346.177h-36.567v7.43h36.567v-7.43ZM220.492 353.607h-36.567v7.431h36.567v-7.431Z"
      stroke="#CBCFCE"
    />
    <path
      d="M183.925 361.008h-36.567v13.999h36.567v-13.999ZM183.925 389.065h-36.567v21.013h36.567v-21.013Z"
      stroke="#CBCFCE"
    />
    <path
      d="M220.492 409.899h-36.567v21.014h36.567v-21.014ZM183.925 431.15h-36.567v28.028h36.567V431.15Z"
      stroke="#CBCFCE"
    />
    <path d="M220.492 459.148h-36.567v28.027h36.567v-28.027Z" stroke="#CBCFCE" />
    <path
      d="M183.925 487.175h-36.567v35.398h36.567v-35.398ZM220.492 522.603h-36.567v35.399h36.567v-35.399ZM183.925 360.978h-36.567v13.999h36.567v-13.999ZM220.492 375.036h-36.567v13.999h36.567v-13.999ZM183.925 699h36.567v-7.43h-36.567V699ZM147.358 691.599h36.567v-7.43h-36.567v7.43ZM183.925 684.169h36.567V670.17h-36.567v13.999ZM183.925 656.082h36.567v-21.013h-36.567v21.013ZM147.358 635.277h36.567v-21.013h-36.567v21.013ZM183.925 614.056h36.567v-28.027h-36.567v28.027Z"
      stroke="#CBCFCE"
    />
    <path
      d="M147.358 585.999h36.567v-28.027h-36.567v28.027ZM183.925 558.002h36.567v-35.399h-36.567v35.399ZM147.358 522.603h36.567v-35.398h-36.567v35.398ZM183.925 684.199h36.567V670.2h-36.567v13.999ZM147.358 670.17h36.567v-13.998h-36.567v13.998ZM110.82 346.177H74.253v7.43h36.567v-7.43ZM147.358 353.607H110.79v7.431h36.568v-7.431Z"
      stroke="#CBCFCE"
    />
    <path
      d="M110.82 361.008H74.253v13.999h36.567v-13.999ZM110.82 389.065H74.253v21.013h36.567v-21.013Z"
      stroke="#CBCFCE"
    />
    <path
      d="M147.358 409.899H110.79v21.014h36.568v-21.014ZM110.82 431.15H74.253v28.028h36.567V431.15Z"
      stroke="#CBCFCE"
    />
    <path d="M147.358 459.148H110.79v28.027h36.568v-28.027Z" stroke="#CBCFCE" />
    <path
      d="M110.82 487.175H74.253v35.398h36.567v-35.398ZM147.358 522.603H110.79v35.399h36.568v-35.399ZM110.82 360.978H74.253v13.999h36.567v-13.999ZM147.358 375.036H110.79v13.999h36.568v-13.999ZM110.79 699h36.568v-7.43H110.79V699Z"
      stroke="#CBCFCE"
    />
    <path
      d="M74.224 691.599h36.567v-7.43H74.224v7.43ZM110.79 684.169h36.568V670.17H110.79v13.999ZM110.79 656.082h36.568v-21.013H110.79v21.013Z"
      stroke="#CBCFCE"
    />
    <path
      d="M74.224 635.277h36.567v-21.013H74.224v21.013ZM110.79 614.056h36.568v-28.027H110.79v28.027ZM74.224 585.999h36.567v-28.027H74.224v28.027Z"
      stroke="#CBCFCE"
    />
    <path
      d="M110.79 558.002h36.568v-35.399H110.79v35.399ZM74.224 522.603h36.567v-35.398H74.224v35.398ZM110.79 684.199h36.568V670.2H110.79v13.999ZM74.224 670.17h36.567v-13.998H74.224v13.998ZM37.567 346.177H1v7.43h36.567v-7.43ZM74.134 353.607H37.567v7.431h36.567v-7.431ZM37.567 361.008H1v13.999h36.567v-13.999ZM37.567 389.065H1v21.013h36.567v-21.013ZM74.134 409.899H37.567v21.014h36.567v-21.014ZM37.567 431.15H1v28.028h36.567V431.15ZM74.134 459.148H37.567v28.027h36.567v-28.027ZM37.567 487.175H1v35.398h36.567v-35.398ZM74.134 522.603H37.567v35.399h36.567v-35.399Z"
      stroke="#CBCFCE"
    />
    <path
      d="M37.567 360.978H1v13.999h36.567v-13.999ZM74.134 375.036H37.567v13.999h36.567v-13.999ZM37.537 699h36.567v-7.43H37.537V699Z"
      stroke="#CBCFCE"
    />
    <path
      d="M1 691.599h36.567v-7.43H1v7.43ZM37.537 684.169h36.567V670.17H37.537v13.999ZM37.537 656.082h36.567v-21.013H37.537v21.013Z"
      stroke="#CBCFCE"
    />
    <path
      d="M1 635.277h36.567v-21.013H1v21.013ZM37.537 614.056h36.567v-28.027H37.537v28.027ZM1 585.999h36.567v-28.027H1v28.027Z"
      stroke="#CBCFCE"
    />
    <path
      d="M37.537 558.002h36.567v-35.399H37.537v35.399ZM1 522.603h36.567v-35.398H1v35.398ZM37.537 684.199h36.567V670.2H37.537v13.999ZM1 670.17h36.567v-13.998H1v13.998ZM549.923 1h-36.567v7.43h36.567V1ZM586.49 8.43h-36.567v7.43h36.567V8.43ZM549.923 15.831h-36.567V29.83h36.567V15.83ZM549.923 43.888h-36.567v21.013h36.567V43.888ZM586.49 64.723h-36.567v21.013h36.567V64.723ZM549.923 85.974h-36.567V114h36.567V85.973ZM586.49 113.971h-36.567v28.028h36.567v-28.028ZM549.923 141.998h-36.567v35.399h36.567v-35.399ZM586.49 177.426h-36.567v35.399h36.567v-35.399Z"
      stroke="#CBCFCE"
    />
    <path
      d="M549.923 15.801h-36.567V29.8h36.567V15.801ZM586.49 29.86h-36.567v13.998h36.567V29.86ZM549.893 353.823h36.567v-7.43h-36.567v7.43Z"
      stroke="#CBCFCE"
    />
    <path
      d="M513.356 346.423h36.567v-7.431h-36.567v7.431ZM549.893 338.992h36.567v-13.999h-36.567v13.999ZM549.893 310.906h36.567v-21.014h-36.567v21.014Z"
      stroke="#CBCFCE"
    />
    <path
      d="M513.356 290.101h36.567v-21.014h-36.567v21.014ZM549.893 268.879h36.567v-28.027h-36.567v28.027ZM513.356 240.822h36.567v-28.027h-36.567v28.027Z"
      stroke="#CBCFCE"
    />
    <path
      d="M549.893 212.825h36.567v-35.398h-36.567v35.398ZM513.356 177.426h36.567v-35.398h-36.567v35.398ZM549.893 339.022h36.567v-13.999h-36.567v13.999ZM513.356 324.994h36.567v-13.999h-36.567v13.999ZM476.67 1h-36.567v7.43h36.567V1ZM513.237 8.43H476.67v7.43h36.567V8.43ZM476.67 15.831h-36.567V29.83h36.567V15.83ZM476.67 43.888h-36.567v21.013h36.567V43.888ZM513.237 64.723H476.67v21.013h36.567V64.723ZM476.67 85.974h-36.567V114h36.567V85.973ZM513.237 113.971H476.67v28.028h36.567v-28.028ZM476.67 141.998h-36.567v35.399h36.567v-35.399ZM513.237 177.426H476.67v35.399h36.567v-35.399Z"
      stroke="#CBCFCE"
    />
    <path
      d="M476.67 15.801h-36.567V29.8h36.567V15.801ZM513.237 29.86H476.67v13.998h36.567V29.86ZM476.67 353.823h36.567v-7.43H476.67v7.43ZM440.103 346.423h36.567v-7.431h-36.567v7.431ZM476.67 338.992h36.567v-13.999H476.67v13.999ZM476.67 310.906h36.567v-21.014H476.67v21.014ZM440.103 290.101h36.567v-21.014h-36.567v21.014ZM476.67 268.879h36.567v-28.027H476.67v28.027ZM440.103 240.822h36.567v-28.027h-36.567v28.027ZM476.67 212.825h36.567v-35.398H476.67v35.398ZM440.103 177.426h36.567v-35.398h-36.567v35.398Z"
      stroke="#CBCFCE"
    />
    <path
      d="M476.67 339.022h36.567v-13.999H476.67v13.999ZM440.103 324.994h36.567v-13.999h-36.567v13.999ZM403.565 1h-36.567v7.43h36.567V1ZM440.103 8.43h-36.567v7.43h36.567V8.43Z"
      stroke="#CBCFCE"
    />
    <path
      d="M403.565 15.831h-36.567V29.83h36.567V15.83ZM403.565 43.888h-36.567v21.013h36.567V43.888Z"
      stroke="#CBCFCE"
    />
    <path
      d="M440.103 64.723h-36.567v21.013h36.567V64.723ZM403.565 85.974h-36.567V114h36.567V85.973Z"
      stroke="#CBCFCE"
    />
    <path d="M440.103 113.971h-36.567v28.028h36.567v-28.028Z" stroke="#CBCFCE" />
    <path
      d="M403.565 141.998h-36.567v35.399h36.567v-35.399ZM440.103 177.426h-36.567v35.399h36.567v-35.399ZM403.565 15.801h-36.567V29.8h36.567V15.801ZM440.103 29.86h-36.567v13.998h36.567V29.86ZM403.536 353.823h36.567v-7.43h-36.567v7.43ZM366.969 346.423h36.567v-7.431h-36.567v7.431ZM403.536 338.992h36.567v-13.999h-36.567v13.999ZM403.536 310.906h36.567v-21.014h-36.567v21.014ZM366.969 290.101h36.567v-21.014h-36.567v21.014ZM403.536 268.879h36.567v-28.027h-36.567v28.027ZM366.969 240.822h36.567v-28.027h-36.567v28.027Z"
      stroke="#CBCFCE"
    />
    <path
      d="M403.536 212.825h36.567v-35.398h-36.567v35.398ZM366.969 177.426h36.567v-35.398h-36.567v35.398ZM403.536 339.022h36.567v-13.999h-36.567v13.999ZM366.969 324.994h36.567v-13.999h-36.567v13.999ZM330.312 1h-36.567v7.43h36.567V1ZM366.879 8.43h-36.567v7.43h36.567V8.43ZM330.312 15.831h-36.567V29.83h36.567V15.83ZM330.312 43.888h-36.567v21.013h36.567V43.888ZM366.879 64.723h-36.567v21.013h36.567V64.723ZM330.312 85.974h-36.567V114h36.567V85.973ZM366.879 113.971h-36.567v28.028h36.567v-28.028ZM330.312 141.998h-36.567v35.399h36.567v-35.399ZM366.879 177.426h-36.567v35.399h36.567v-35.399Z"
      stroke="#CBCFCE"
    />
    <path
      d="M330.312 15.801h-36.567V29.8h36.567V15.801ZM366.879 29.86h-36.567v13.998h36.567V29.86ZM330.282 353.823h36.568v-7.43h-36.568v7.43Z"
      stroke="#CBCFCE"
    />
    <path
      d="M293.745 346.423h36.567v-7.431h-36.567v7.431ZM330.282 338.992h36.568v-13.999h-36.568v13.999ZM330.282 310.906h36.568v-21.014h-36.568v21.014Z"
      stroke="#CBCFCE"
    />
    <path
      d="M293.745 290.101h36.567v-21.014h-36.567v21.014ZM330.282 268.879h36.568v-28.027h-36.568v28.027ZM293.745 240.822h36.567v-28.027h-36.567v28.027Z"
      stroke="#CBCFCE"
    />
    <path
      d="M330.282 212.825h36.568v-35.398h-36.568v35.398ZM293.745 177.426h36.567v-35.398h-36.567v35.398ZM330.282 339.022h36.568v-13.999h-36.568v13.999ZM293.745 324.994h36.567v-13.999h-36.567v13.999ZM549.923 346.177h-36.567v7.43h36.567v-7.43ZM586.49 353.607h-36.567v7.431h36.567v-7.431ZM549.923 361.008h-36.567v13.999h36.567v-13.999ZM549.923 389.065h-36.567v21.013h36.567v-21.013ZM586.49 409.899h-36.567v21.014h36.567v-21.014ZM549.923 431.15h-36.567v28.028h36.567V431.15ZM586.49 459.148h-36.567v28.027h36.567v-28.027ZM549.923 487.175h-36.567v35.398h36.567v-35.398ZM586.49 522.603h-36.567v35.399h36.567v-35.399Z"
      stroke="#CBCFCE"
    />
    <path
      d="M549.923 360.978h-36.567v13.999h36.567v-13.999ZM586.49 375.036h-36.567v13.999h36.567v-13.999ZM549.893 699h36.567v-7.43h-36.567V699Z"
      stroke="#CBCFCE"
    />
    <path
      d="M513.356 691.599h36.567v-7.43h-36.567v7.43ZM549.893 684.169h36.567V670.17h-36.567v13.999ZM549.893 656.082h36.567v-21.013h-36.567v21.013Z"
      stroke="#CBCFCE"
    />
    <path
      d="M513.356 635.277h36.567v-21.013h-36.567v21.013ZM549.893 614.056h36.567v-28.027h-36.567v28.027ZM513.356 585.999h36.567v-28.027h-36.567v28.027Z"
      stroke="#CBCFCE"
    />
    <path
      d="M549.893 558.002h36.567v-35.399h-36.567v35.399ZM513.356 522.603h36.567v-35.398h-36.567v35.398ZM549.893 684.199h36.567V670.2h-36.567v13.999ZM513.356 670.17h36.567v-13.998h-36.567v13.998ZM476.67 346.177h-36.568v7.43h36.568v-7.43ZM513.237 353.607H476.67v7.431h36.567v-7.431ZM476.67 361.008h-36.568v13.999h36.568v-13.999ZM476.67 389.065h-36.568v21.013h36.568v-21.013ZM513.237 409.899H476.67v21.014h36.567v-21.014ZM476.67 431.15h-36.568v28.028h36.568V431.15ZM513.237 459.148H476.67v28.027h36.567v-28.027ZM476.67 487.175h-36.568v35.398h36.568v-35.398ZM513.237 522.603H476.67v35.399h36.567v-35.399Z"
      stroke="#CBCFCE"
    />
    <path
      d="M476.67 360.978h-36.568v13.999h36.568v-13.999ZM513.237 375.036H476.67v13.999h36.567v-13.999ZM476.669 699h36.568v-7.43h-36.568V699Z"
      stroke="#CBCFCE"
    />
    <path
      d="M440.102 691.599h36.568v-7.43h-36.568v7.43ZM476.669 684.169h36.568V670.17h-36.568v13.999ZM476.669 656.082h36.568v-21.013h-36.568v21.013Z"
      stroke="#CBCFCE"
    />
    <path
      d="M440.102 635.277h36.568v-21.013h-36.568v21.013ZM476.669 614.056h36.568v-28.027h-36.568v28.027ZM440.102 585.999h36.568v-28.027h-36.568v28.027Z"
      stroke="#CBCFCE"
    />
    <path
      d="M476.669 558.002h36.568v-35.399h-36.568v35.399ZM440.102 522.603h36.568v-35.398h-36.568v35.398ZM476.669 684.199h36.568V670.2h-36.568v13.999ZM440.102 670.17h36.568v-13.998h-36.568v13.998ZM403.565 346.177h-36.567v7.43h36.567v-7.43ZM440.102 353.607h-36.567v7.431h36.567v-7.431Z"
      stroke="#CBCFCE"
    />
    <path
      d="M403.565 361.008h-36.567v13.999h36.567v-13.999ZM403.565 389.065h-36.567v21.013h36.567v-21.013Z"
      stroke="#CBCFCE"
    />
    <path
      d="M440.102 409.899h-36.567v21.014h36.567v-21.014ZM403.565 431.15h-36.567v28.028h36.567V431.15Z"
      stroke="#CBCFCE"
    />
    <path d="M440.102 459.148h-36.567v28.027h36.567v-28.027Z" stroke="#CBCFCE" />
    <path
      d="M403.565 487.175h-36.567v35.398h36.567v-35.398ZM440.102 522.603h-36.567v35.399h36.567v-35.399ZM403.565 360.978h-36.567v13.999h36.567v-13.999ZM440.102 375.036h-36.567v13.999h36.567v-13.999ZM403.535 699h36.567v-7.43h-36.567V699ZM366.968 691.599h36.567v-7.43h-36.567v7.43ZM403.535 684.169h36.567V670.17h-36.567v13.999ZM403.535 656.082h36.567v-21.013h-36.567v21.013ZM366.968 635.277h36.567v-21.013h-36.567v21.013ZM403.535 614.056h36.567v-28.027h-36.567v28.027Z"
      stroke="#CBCFCE"
    />
    <path
      d="M366.968 585.999h36.567v-28.027h-36.567v28.027ZM403.535 558.002h36.567v-35.399h-36.567v35.399ZM366.968 522.603h36.567v-35.398h-36.567v35.398ZM403.535 684.199h36.567V670.2h-36.567v13.999ZM366.968 670.17h36.567v-13.998h-36.567v13.998ZM330.312 346.177h-36.567v7.43h36.567v-7.43ZM366.879 353.607h-36.567v7.431h36.567v-7.431ZM330.312 361.008h-36.567v13.999h36.567v-13.999ZM330.312 389.065h-36.567v21.013h36.567v-21.013ZM366.879 409.899h-36.567v21.014h36.567v-21.014ZM330.312 431.15h-36.567v28.028h36.567V431.15ZM366.879 459.148h-36.567v28.027h36.567v-28.027ZM330.312 487.175h-36.567v35.398h36.567v-35.398ZM366.879 522.603h-36.567v35.399h36.567v-35.399Z"
      stroke="#CBCFCE"
    />
    <path
      d="M330.312 360.978h-36.567v13.999h36.567v-13.999ZM366.879 375.036h-36.567v13.999h36.567v-13.999ZM330.282 699h36.567v-7.43h-36.567V699Z"
      stroke="#CBCFCE"
    />
    <path
      d="M293.745 691.599h36.567v-7.43h-36.567v7.43ZM330.282 684.169h36.567V670.17h-36.567v13.999ZM330.282 656.082h36.567v-21.013h-36.567v21.013Z"
      stroke="#CBCFCE"
    />
    <path
      d="M293.745 635.277h36.567v-21.013h-36.567v21.013ZM330.282 614.056h36.567v-28.027h-36.567v28.027ZM293.745 585.999h36.567v-28.027h-36.567v28.027Z"
      stroke="#CBCFCE"
    />
    <path
      d="M330.282 558.002h36.567v-35.399h-36.567v35.399ZM293.745 522.603h36.567v-35.398h-36.567v35.398ZM330.282 684.199h36.567V670.2h-36.567v13.999ZM293.745 670.17h36.567v-13.998h-36.567v13.998Z"
      stroke="#CBCFCE"
    />
  </svg>
);

export default SvgAbstractHugeGrid;
