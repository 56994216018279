import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom Typography
 * 
 * Typography
 */
function Typography (props) {
  const {
    className = "",
    type = "adobe",
    color = "dark",
    bold = null,
    children,
    ...other
  } = props;

  return <span
    className={`${css["atom__typography-container"]} ${className}`}
    data-type={type}
    data-bold={bold}
    data-color={color}
    {...other}
  >
    {children}
  </span>;
}

export default Typography;
