import * as React from 'react';

const SvgPlayButtonRound = (props) => (
  <svg viewBox="0 0 94 95" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="PlayButtonRound_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.931 94.224c25.92 0 46.932-21.012 46.932-46.931C93.863 21.373 72.85.36 46.93.36S0 21.373 0 47.293s21.012 46.93 46.931 46.93Zm.048-2.629c24.441 0 44.255-19.813 44.255-44.255 0-24.442-19.814-44.255-44.255-44.255-24.442 0-44.256 19.813-44.256 44.255 0 24.442 19.814 44.255 44.256 44.255Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.931 94.224c25.92 0 46.932-21.012 46.932-46.931C93.863 21.373 72.85.36 46.93.36S0 21.373 0 47.293s21.012 46.93 46.931 46.93Zm.048-2.629c24.441 0 44.255-19.813 44.255-44.255 0-24.442-19.814-44.255-44.255-44.255-24.442 0-44.256 19.813-44.256 44.255 0 24.442 19.814 44.255 44.256 44.255Z"
      fill="#fff"
    />
    <path
      d="M89.863 47.293c0 23.71-19.221 42.93-42.932 42.93v8c28.129 0 50.932-22.802 50.932-50.93h-8ZM46.93 4.36c23.71 0 42.932 19.221 42.932 42.932h8C97.863 19.164 75.06-3.64 46.93-3.64v8ZM4 47.293C4 23.583 23.221 4.36 46.931 4.36v-8C18.803-3.639-4 19.164-4 47.293h8Zm42.931 42.93C23.221 90.224 4 71.004 4 47.294h-8c0 28.128 22.803 50.93 50.931 50.93v-8ZM87.234 47.34c0 22.232-18.023 40.255-40.255 40.255v8c26.65 0 48.255-21.604 48.255-48.255h-8ZM46.979 7.085c22.232 0 40.255 18.023 40.255 40.255h8c0-26.65-21.605-48.255-48.255-48.255v8ZM6.723 47.34c0-22.232 18.023-40.255 40.256-40.255v-8C20.328-.915-1.277 20.689-1.277 47.34h8ZM46.98 87.595c-22.233 0-40.256-18.023-40.256-40.255h-8c0 26.65 21.605 48.255 48.256 48.255v-8Z"
      fill="#fff"
      mask="url(#PlayButtonRound_svg__a)"
    />
    <path d="M40.851 65.138v-34.82l25.737 15.14-25.737 19.68Z" stroke="#fff" strokeWidth={4} />
  </svg>
);

export default SvgPlayButtonRound;
