import * as React from 'react';

const SvgEyxoTalk = (props) => (
  <svg viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#EyxoTalk_svg__a)">
      <path
        d="M80.004 0a80.005 80.005 0 1 0-.079 160.01A80.005 80.005 0 0 0 80.005 0Zm0 158.888A78.89 78.89 0 0 1 2.616 64.61a78.893 78.893 0 1 1 77.388 94.278Z"
        fill="#1A1A1A"
        stroke="#000"
      />
      <path
        d="M40.475 53.688v45.128h79.05V53.687h-79.05Zm77.083 43.042H42.58V55.783h74.978V96.73Z"
        fill="#000"
      />
      <path
        d="M81.058 104.217v-6.189h-2.105v6.189h-11.58v2.095h25.255v-2.095h-11.57Z"
        fill="#000"
      />
      <path
        d="M87.345 76.257 72.666 66.32v19.864l14.679-9.927ZM74.752 69.99l8.785 6.267-8.785 5.657V69.99Z"
        fill="#1D1D1B"
      />
    </g>
    <defs>
      <clipPath id="EyxoTalk_svg__a">
        <path fill="#fff" d="M0 0h160v160H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEyxoTalk;
