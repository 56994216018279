import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom Input
 * 
 * Input
 */
function Input (props) {
  const {
    className = "",
    placeholder = "",
    type = "text",
    name = "",
    textTransform = null,
    multiline = null,
    children,
    ...other
  } = props;

  const El = multiline ? 'textarea' : 'input';

  return <El
    className={`${css["atom__input-container"]} ${className}`}
    type={type}
    name={name}
    placeholder={placeholder}
    data-text={textTransform}
    {...other}
  />;
}

export default Input;
