import * as React from 'react';

const SvgEyxoEdu = (props) => (
  <svg viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#EyxoEdu_svg__a)" fill="#1A1A1A">
      <path
        d="M79.965 0a80.005 80.005 0 1 0-.02 160.01A80.005 80.005 0 0 0 79.965 0Zm0 158.888A78.89 78.89 0 0 1 2.577 64.61a78.894 78.894 0 1 1 77.388 94.278Z"
        stroke="#000"
      />
      <path d="M113.898 61.4v-1.643h-7.142v-6.02c-.748 0-1.486.058-2.224.108a70.46 70.46 0 0 0-24.418 6.1 70.46 70.46 0 0 0-24.42-6.1c-.737-.05-1.485-.08-2.223-.109v6.021h-7.368v46.506h67.805V61.4h-.01Zm-31.148-.196a68.437 68.437 0 0 1 21.812-5.136v41.429a70.577 70.577 0 0 0-23.307 5.608V61.932h-.108c.511-.246 1.052-.502 1.603-.68v-.048ZM55.695 81.608v-25.58a68.433 68.433 0 0 1 21.782 5.225c.54.226 1.082.482 1.623.728h-.118v41.163a70.941 70.941 0 0 0-23.307-5.598l.02-15.938Zm-7.379-19.627h5.155v37.641c.738 0 1.486.069 2.224.118a68.779 68.779 0 0 1 19.676 4.309H48.316V61.981Zm36.5 42.058a68.634 68.634 0 0 1 19.677-4.309c.738-.049 1.475-.098 2.223-.118V61.981h4.919v42.058H84.816Z" />
    </g>
    <defs>
      <clipPath id="EyxoEdu_svg__a">
        <path fill="#fff" d="M0 0h160v160H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEyxoEdu;
