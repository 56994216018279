import * as React from 'react';

const SvgNext = (props) => (
  <svg viewBox="0 0 264 264" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="#fff" d="M0 0h263.49v263.49H0z" />
    <g
      style={{
        mixBlendMode: 'luminosity'
      }}
      fill="#1A1A1A">
      <path d="M153.692 183.021h-3.716a.258.258 0 0 1-.258-.258v-13.176c0-1.47.722-2.217 2.142-2.604 2.322-.645 3.948-.799 5.419-.799 4.877 0 6.787 2.501 6.787 5.982v10.597a.258.258 0 0 1-.258.258h-3.665a.258.258 0 0 1-.258-.258v-10.237c0-1.547-.671-2.758-2.838-2.758-.697 0-1.42.051-2.916.438a.247.247 0 0 0-.181.232v12.325c0 .155-.103.258-.258.258ZM173.924 169.149c-2.116 0-3.432 1.444-3.665 3.764-.025.155.104.284.258.284h6.839a.258.258 0 0 0 .258-.258c-.103-2.114-1.213-3.79-3.69-3.79Zm4.129 13.769a.236.236 0 0 1-.207.103h-3.329c-4.98 0-8.361-2.656-8.361-8.303 0-5.956 3.639-8.612 7.871-8.612 4.619 0 7.639 2.605 7.639 8.174 0 1.186-.542 1.753-1.755 1.753h-9.368c-.155 0-.258.129-.258.284.31 2.372 2.116 3.3 4.542 3.3h4.955c.206 0 .309.232.206.387l-1.935 2.914Z" />
      <path d="m192.633 182.892-3.149-5.079c-.103-.155-.335-.155-.413 0l-3.174 5.079c-.051.078-.129.129-.206.129h-4.232c-.207 0-.31-.232-.207-.387l5.239-7.838c.051-.077.051-.18 0-.284l-5.135-7.786c-.104-.155 0-.387.206-.387h4.258c.077 0 .155.051.206.129l3.045 4.847a.265.265 0 0 0 .439 0l2.89-4.847c.052-.078.129-.129.207-.129h4.232c.206 0 .31.232.206.387l-4.954 7.632c-.052.077-.052.18 0 .283l5.238 8.019c.104.155 0 .387-.206.387h-4.31c-.051-.026-.155-.077-.18-.155ZM208.477 182.325a.237.237 0 0 1-.18.283c-1.497.387-2.684.593-3.845.593-3.845 0-5.626-2.088-5.626-6.162v-13.898c0-.129.077-.232.206-.258l3.613-.67c.155-.026.284.077.284.258v3.61c0 .128.103.257.258.257h4.568a.26.26 0 0 1 .258.258v2.708a.258.258 0 0 1-.258.257h-4.568a.26.26 0 0 0-.258.258v7.529c0 1.496.542 2.553 2.245 2.553.723 0 1.6-.181 2.555-.413.155-.026.284.052.31.207l.438 2.63ZM178.517 157.133c-.8 0-1.625-.18-2.374-.541-2.735-1.315-3.896-4.616-2.554-7.349l23.044-47.752c1.316-2.733 4.619-3.894 7.355-2.553 2.735 1.315 3.896 4.616 2.555 7.349l-23.045 47.752a5.57 5.57 0 0 1-4.981 3.094Z" />
      <path d="M178.516 157.133a5.487 5.487 0 0 1-3.561-1.315l-76.901-65.13c-2.323-1.96-2.606-5.441-.645-7.736 1.961-2.32 5.445-2.604 7.742-.644l76.901 65.13c2.322 1.96 2.606 5.441.645 7.736a5.38 5.38 0 0 1-4.181 1.959Z" />
      <path d="M60.507 173.97a5.504 5.504 0 0 1-5.006-3.197 5.508 5.508 0 0 1 2.71-7.297l141.054-64.589c2.761-1.263 6.038-.052 7.303 2.707a5.509 5.509 0 0 1-2.71 7.297L62.804 173.455a5.226 5.226 0 0 1-2.297.515Z" />
      <path d="M60.507 173.97c-.826 0-1.677-.18-2.452-.593-2.71-1.366-3.819-4.667-2.451-7.374l41.108-81.968c1.368-2.707 4.671-3.816 7.381-2.45 2.709 1.367 3.819 4.668 2.451 7.375l-41.108 81.968a5.52 5.52 0 0 1-4.93 3.042Z" />
    </g>
  </svg>
);

export default SvgNext;
