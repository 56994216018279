import * as React from 'react';

const SvgButtonMobile = (props) => (
  <svg viewBox="0 0 139 139" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.5 139c38.384 0 69.5-31.116 69.5-69.5S107.884 0 69.5 0C32.241 0 1.83 29.32.08 66.145h28.608l-6.64-6.71 1.9-1.918 8.062 8.147.007-.007 1.899 1.919-.007.007.007.007-1.899 1.918-.007-.007-8.063 8.147-1.898-1.918 6.64-6.71H.001L0 69.5C0 107.884 31.116 139 69.5 139Z"
      fill="#CBCFCE"
    />
  </svg>
);

export default SvgButtonMobile;
