/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Transition from '../transition';

import MainHead from 'components/atoms/head';
import JobForm from 'components/molecules/job-form';
import Cookies from 'components/molecules/cookies';
import Menu from 'components/organisms/section-menu';
import Footer from 'components/organisms/section-footer';

import css from './styles.module.scss';

import {
  MenuInfo,
  FooterInfo,
  FooterWorkAttrs,
} from 'data/global';

const Layout = ({children}) => {
  const router = useRouter();
  const { asPath } = router;

  const [areCookiesConsented, setAreCookiesConsented] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(true);
  const [workWithAttrs, setWorkWithAttrs] = useState({});
  const [modalDescription, setModalDescription] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setShowContactInfo(asPath !== '/contact-us');
    setWorkWithAttrs(asPath.match(/work-with-us/g) ? FooterWorkAttrs : {});
  }, [asPath]);

  useEffect(() => {
    const cookiesConsent = localStorage.getItem('hasCookieConsent');
    if (cookiesConsent) {
      setAreCookiesConsented(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    setAreCookiesConsented(true);
    window.localStorage.setItem('hasCookieConsent', 'ok');
  };

  const handleOpenCloseModal = (type = "privacy-policies") => {
    type === 'form' ?
      setModalDescription(<JobForm />) :
      setModalDescription(FooterInfo.modalDescription);

    setIsModalOpen(oldState => !oldState);
  };

  return (
    <>
      <MainHead/>
      <Menu {...MenuInfo}/>
      <Transition location={asPath}>
        <div className={css["layout"]}>
          {children}
        </div>
      </Transition>
      <Cookies
        areCookiesConsented={areCookiesConsented}
        handleAcceptCookies={handleAcceptCookies}
      />
      <Footer
        {...FooterInfo}
        showContactInfo={showContactInfo}
        {...workWithAttrs}
        modalDescription={modalDescription}
        isModalOpen={isModalOpen}
        handleOpenCloseModal={handleOpenCloseModal}
      />
    </>
  );
};

export default Layout;