import * as React from 'react';

const SvgEyxoDiversity = (props) => (
  <svg viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#EyxoDiversity_svg__a)">
      <path
        d="M79.995 0a80.005 80.005 0 1 0 80.015 80.005A79.993 79.993 0 0 0 79.995 0Zm0 158.888a78.896 78.896 0 0 1-72.889-48.704 78.893 78.893 0 1 1 151.782-30.18 78.886 78.886 0 0 1-23.107 55.783 78.892 78.892 0 0 1-55.786 23.101Z"
        fill="#1A1A1A"
        stroke="#000"
      />
      <path
        d="M79.995 80.704a10.006 10.006 0 1 0-9.238-6.178 9.993 9.993 0 0 0 9.238 6.178Zm0-17.778a7.782 7.782 0 1 1 0 15.563 7.782 7.782 0 0 1 0-15.563ZM54.504 83.665a6.62 6.62 0 1 0 0-13.241 6.62 6.62 0 0 0 0 13.241Zm0-11.019a4.397 4.397 0 1 1-4.398 4.398 4.398 4.398 0 0 1 4.398-4.378v-.02ZM105.349 83.665a6.62 6.62 0 1 0 0-13.241 6.62 6.62 0 0 0 0 13.241Zm0-11.019a4.398 4.398 0 1 1-4.398 4.398 4.398 4.398 0 0 1 4.398-4.378v-.02ZM54.504 85.78a13.773 13.773 0 0 0-13.537 10.556h17.177a21.882 21.882 0 0 1 4.792-7.742 14.757 14.757 0 0 0-8.432-2.814Zm2.175 8.343H44.163a11.62 11.62 0 0 1 10.34-6.13c1.741.023 3.456.422 5.028 1.171a25.347 25.347 0 0 0-2.824 4.959h-.03ZM80.143 83.487A20.66 20.66 0 0 0 59.994 99.23h40.337a20.994 20.994 0 0 0-20.188-15.742Zm-17.08 13.548A18.477 18.477 0 0 1 80.144 85.7a18.84 18.84 0 0 1 17.04 11.334h-34.12ZM105.486 85.78a13.497 13.497 0 0 0-8.166 2.676 23.173 23.173 0 0 1 4.919 8.028h16.726a13.995 13.995 0 0 0-13.479-10.704Zm-4.791 3.236a11.566 11.566 0 0 1 4.791-.983 11.69 11.69 0 0 1 10.311 6.267h-12.043a25.63 25.63 0 0 0-3.059-5.284Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="EyxoDiversity_svg__a">
        <path fill="#fff" d="M0 0h160v160H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEyxoDiversity;
