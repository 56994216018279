import * as React from 'react';

const SvgAbstractTwoDots = (props) => (
  <svg viewBox="0 0 906 547" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m408.729 121.566 77.542-77.503a273.42 273.42 0 0 0-77.465 77.503h-.077ZM372.835 202.673 567.34 8.015c-5.33 1.38-10.623 2.876-15.876 4.602L377.437 186.644a309.173 309.173 0 0 0-4.602 16.029ZM364.667 256.055 620.722 0c-4.065.23-8.13.537-12.195.92l-242.94 242.94c-.383 4.065-.69 8.13-.92 12.195ZM365.932 300.041 664.708 1.265c-3.452-.345-6.903-.69-10.355-.92l-289.38 289.342c.269 3.451.614 6.903.959 10.354ZM372.95 338.236l329.8-329.8-9.089-2.185-323.05 323.05c.805 2.876 1.572 5.906 2.339 8.935ZM384.186 372.329l352.809-352.81a237.947 237.947 0 0 0-8.168-3.144L381.118 364.084c.921 2.722 1.956 5.445 3.068 8.245ZM398.912 402.739 767.406 34.245c-2.416-1.38-4.871-2.722-7.325-3.834L394.924 395.338a151.254 151.254 0 0 0 3.988 7.401ZM416.629 430.235 794.902 51.963a257.852 257.852 0 0 0-6.596-4.679L411.951 423.639c1.457 2.378 3.068 4.41 4.678 6.596ZM437.146 454.932 819.599 72.479c-1.956-1.802-3.835-3.605-5.906-5.369L431.777 449.026c1.764 1.994 3.567 3.835 5.369 5.906ZM460.347 476.982 841.649 95.68a190.196 190.196 0 0 0-5.254-6.059L454.288 471.73a190.657 190.657 0 0 0 6.059 5.253ZM486.271 496.387l374.859-374.86a258.915 258.915 0 0 0-4.602-6.711L479.56 491.785c2.109 1.572 4.371 3.26 6.711 4.602ZM514.917 512.877l362.512-362.626c-1.266-2.493-2.531-4.986-3.835-7.44L507.401 509.004c2.531 1.38 5.024 2.607 7.516 3.873ZM546.862 526.145l343.95-343.95a165.6 165.6 0 0 0-2.991-8.322L538.54 523.154a164.892 164.892 0 0 0 8.322 2.991ZM582.488 535.733l317.911-317.912a247.41 247.41 0 0 0-1.994-9.28L573.207 533.739c3.068.728 6.175 1.38 9.281 1.994ZM622.907 540.565l282.324-282.324c0-3.567-.383-7.095-.69-10.623L612.285 539.874c3.528.307 7.056.537 10.622.691ZM670.345 538.532l233.046-233.045a284.3 284.3 0 0 0 1.342-12.656L657.69 539.874c4.027-.345 8.245-.805 12.655-1.342ZM730.438 523.5l157.728-157.729c2.109-5.637 4.027-11.505 5.752-17.104L713.334 529.252c5.752-1.726 11.466-3.528 17.104-5.752Z"
      fill="#000"
    />
    <path
      d="M80.145 466.915a262.93 262.93 0 0 0 11.397 10.738L477.946 91.445c-3.489-3.876-7.055-7.753-10.777-11.397-3.721-3.644-7.753-7.752-12.055-11.358L68.981 454.821c3.45 4.109 7.21 8.14 11.164 12.094ZM251.872 546.15a276.405 276.405 0 0 0 45.664-.194l248.519-248.518a275.822 275.822 0 0 0 0-45.625L251.872 546.15ZM.794 293.988 294.047.775a274.45 274.45 0 0 0-45.858.388L1.143 248.091a274.529 274.529 0 0 0-.349 45.897ZM190.701 12.754A273.758 273.758 0 0 0 12.773 190.643L190.7 12.753ZM534.891 354.46 354.52 534.792A273.831 273.831 0 0 0 534.891 354.46ZM31.418 400.861a279.151 279.151 0 0 0 17.173 28.298L429.258 48.533A265.89 265.89 0 0 0 400.96 31.36L31.418 400.861ZM6.764 333.877c2.679 12 6.19 23.8 10.505 35.314L369.405 17.173a277.756 277.756 0 0 0-35.353-10.505L6.764 333.877ZM176.901 529.442a276.721 276.721 0 0 0 35.237 10.622l327.831-328.023a272.08 272.08 0 0 0-10.583-35.237L176.901 529.442ZM117.126 497.927a274.817 274.817 0 0 0 28.221 17.25l369.89-369.927a269.639 269.639 0 0 0-17.25-28.221L117.126 497.927Z"
      fill="#000"
    />
  </svg>
);

export default SvgAbstractTwoDots;
