import * as React from 'react';

const SvgLogoVertical = (props) => (
  <svg viewBox="0 0 162 263" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m96.123 190.149-30.737-30.828 96.542-96.824-6.912-6.953-46.35 46.509-35.942-36.047a40.902 40.902 0 0 0 8.507-28.868 40.86 40.86 0 0 0-13.537-26.866A40.627 40.627 0 0 0 39.474.018 40.642 40.642 0 0 0 11.92 11.955 40.875 40.875 0 0 0 .018 39.59a40.89 40.89 0 0 0 10.223 28.302A40.657 40.657 0 0 0 37.03 81.469a40.615 40.615 0 0 0 28.784-8.532l35.92 36.047-43.258 43.385-51.491-51.771-6.933 6.931 51.556 51.707-51.47 51.621 6.932 6.931 51.47-51.621 30.737 30.827a40.903 40.903 0 0 0-8.507 28.868 40.857 40.857 0 0 0 13.537 26.866 40.631 40.631 0 0 0 28.22 10.254 40.644 40.644 0 0 0 27.554-11.937 40.88 40.88 0 0 0 11.902-27.635 40.888 40.888 0 0 0-10.224-28.302 40.654 40.654 0 0 0-26.787-13.577 40.613 40.613 0 0 0-28.784 8.532l-.064.086ZM18.843 62.754a31.065 31.065 0 0 1-3.178-40.112l43.151 43.3a30.842 30.842 0 0 1-20.572 5.79 30.868 30.868 0 0 1-19.4-8.978ZM65.77 59.01 22.597 15.711a30.863 30.863 0 0 1 39.945 3.237 31.06 31.06 0 0 1 8.959 19.436 31.086 31.086 0 0 1-5.73 20.626Zm77.344 185.177a30.908 30.908 0 0 1-15.836 8.495c-6 1.198-12.22.585-17.872-1.762a30.957 30.957 0 0 1-13.884-11.425 31.087 31.087 0 0 1-5.214-17.236 31.081 31.081 0 0 1 5.214-17.235 30.944 30.944 0 0 1 13.884-11.425 30.848 30.848 0 0 1 17.872-1.762 30.9 30.9 0 0 1 15.836 8.495 31.062 31.062 0 0 1 9.042 21.932 31.065 31.065 0 0 1-9.063 21.923h.021Z"
      fill="#000"
    />
  </svg>
);

export default SvgLogoVertical;
