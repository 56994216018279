import React from 'react';
import Link from 'next/link';

import Title from 'components/atoms/title';
import Typography from 'components/atoms/typography';
import Button from 'components/atoms/button';
import Abstract from 'components/atoms/abstract';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule FooterContact
 * 
 * Footer contact
 */
function FooterContact (props) {
  const {
    id = null,
    className = "",
    title = '',
    description = '',
    button = '',
    buttonUrl = '',
    handleOpenCloseModal,
    ...other
  } = props;

  const handleOnClick = (e) => {
    if(id) {
      e.preventDefault();
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
      handleOpenCloseModal('form');
    }
  };

  return <div
    className={`${css["molecule__footer-contact-container"]} ${className}`}
    id={id}
    {...other}
  >
    <div className={css["content"]}>
      {title &&
        <Title level="3" color="lightgray" className={css["title"]}>
          {title}
        </Title>
      }
      {description &&
        <Typography color="lightgray" className={css["desc"]}>
          {description}
        </Typography>
      }
    </div>
    {button && buttonUrl &&
      <Link href={buttonUrl} passHref>
        <a
          title={button}
          className={css["btn"]}
        >
          <Button
            rounded
            theme="gray"
            parentTheme="dark"
            textColor="dark"
            alt={button}
            onClick={handleOnClick}
          >
            {button}
          </Button>
        </a>
      </Link>
    }
    <Abstract type="9" className={css["abstract"]} transform="0"/>
  </div>;
}

export default FooterContact;
