import * as React from 'react';

const SvgArrowLeft = (props) => (
  <svg viewBox="0 0 69 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="#1A1A1A" d="M11.019 21 .62 10.493 2.601 8.49 13 18.998z" />
    <path fill="#1A1A1A" d="M.62 10.508 11.02 0l1.98 2.001L2.602 12.51z" />
    <path fill="#1A1A1A" d="M69 11.855H3V8.807h66z" />
  </svg>
);

export default SvgArrowLeft;
