import * as React from 'react';

const SvgEyxoGym = (props) => (
  <svg viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#EyxoGym_svg__a)">
      <path
        d="M80.004 0a79.995 79.995 0 1 0-.059 159.99A79.995 79.995 0 0 0 80.005 0Zm0 158.888A78.89 78.89 0 0 1 2.616 64.61a78.893 78.893 0 1 1 77.388 94.278Z"
        fill="#1A1A1A"
        stroke="#000"
      />
      <path d="m51.602 77.19 25.963-12.474.984 2.135-25.924 12.494-1.023-2.154Z" fill="#000" />
      <path
        d="m79.12 67.038-26.682 12.79-1.368-2.854 26.682-12.79 1.367 2.854ZM52.811 78.844l25.255-12.121-.69-1.427-25.254 12.082.689 1.466Z"
        fill="#000"
      />
      <path d="m73.522 56.294 2.075-.983 9.12 18.987-2.075.984-9.12-18.988Z" fill="#000" />
      <path
        d="m85.2 74.427-2.784 1.338-9.465-19.677 2.794-1.348L85.2 74.427Zm-2.41.285 1.357-.66-8.776-18.269-1.357.66 8.776 18.269ZM77.91 56.717l2.075-.984 7.182 14.876-2.086.984-7.172-14.876Z"
        fill="#000"
      />
      <path
        d="m87.679 70.796-2.785 1.338-7.516-15.633 2.794-1.348 7.507 15.643Zm-2.42.285 1.367-.659-6.828-14.206-1.367.65 6.827 14.216ZM83.035 57.85l1.476-.72 4.339 9.042-1.476.709-4.339-9.032Z"
        fill="#000"
      />
      <path
        d="m89.38 66.359-2.213 1.053-4.663-9.71 2.194-1.054 4.683 9.71Zm-1.82 0 .768-.374-3.994-8.284-.777.374 4.004 8.284ZM45.434 69.773l2.115-.984 9.12 18.988-2.115.984-9.12-18.988Z"
        fill="#000"
      />
      <path
        d="m57.2 87.934-2.833 1.358-9.455-19.677 2.824-1.357L57.2 87.934Zm-2.499.266 1.397-.669-8.776-18.28-1.397.67L54.701 88.2ZM42.944 73.443l2.116-.984 7.162 14.925-2.115.984-7.163-14.925Z"
        fill="#000"
      />
      <path
        d="m52.734 87.57-2.824 1.358-7.516-15.643 2.823-1.357 7.517 15.643Zm-2.46.306 1.397-.67-6.808-14.216-1.407.67 6.818 14.216ZM41.183 77.939l1.515-.718 4.338 9.031-1.515.728-4.338-9.041Z"
        fill="#000"
      />
      <path
        d="m47.558 86.44-2.214 1.062-4.683-9.75 2.233-1.063 4.664 9.75Zm-1.85 0 .797-.385-3.994-8.323-.797.384 3.994 8.323ZM81.647 94.457l25.964-12.475 1.033 2.135-25.973 12.475-1.024-2.135Z"
        fill="#000"
      />
      <path
        d="m109.165 84.265-26.681 12.79-1.368-2.843 26.682-12.79 1.367 2.843ZM82.858 96.071l25.254-12.13-.688-1.417-25.255 12.12.689 1.427Z"
        fill="#000"
      />
      <path d="m103.567 73.561 2.076-.984 9.12 18.988-2.076.984-9.12-18.988Z" fill="#000" />
      <path
        d="m115.295 91.732-2.794 1.387-9.465-19.676 2.794-1.348 9.465 19.637Zm-2.421.285 1.358-.659-8.766-18.27-1.367.65 8.775 18.28ZM107.955 73.945l2.076-.984 7.172 14.925-2.076.983-7.172-14.924Z"
        fill="#000"
      />
      <path
        d="M117.725 88.063 114.94 89.4l-7.516-15.643 2.794-1.338 7.507 15.643Zm-2.421.285 1.368-.66-6.828-14.245-1.358.65 6.818 14.255ZM113.081 75.116l1.486-.719 4.338 9.042-1.485.708-4.339-9.031Z"
        fill="#000"
      />
      <path
        d="m119.427 83.626-2.194 1.052-4.683-9.75 2.204-1.052 4.673 9.75Zm-1.82 0 .767-.364-3.994-8.323-.768.373 3.995 8.314ZM75.49 87.04l2.115-.984 9.11 18.988-2.106.983-9.12-18.987Z"
        fill="#000"
      />
      <path
        d="m87.246 105.201-2.824 1.358-9.464-19.677 2.824-1.358 9.464 19.677Zm-2.46.305 1.407-.679-8.775-18.27-1.407.67 8.776 18.279ZM72.99 90.739l2.116-.984 7.142 14.895-2.115.984-7.143-14.895Z"
        fill="#000"
      />
      <path
        d="m82.799 104.837-2.824 1.358-7.516-15.643 2.833-1.358 7.507 15.643Zm-2.46.305 1.407-.679-6.827-14.206-1.397.669 6.817 14.216ZM71.24 95.205l1.505-.728 4.338 9.041-1.505.728-4.339-9.041Z"
        fill="#000"
      />
      <path
        d="m77.615 103.706-2.253 1.062-4.683-9.75 2.223-1.062 4.713 9.75Zm-1.85 0 .787-.384-3.994-8.323-.797.384 4.004 8.323Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="EyxoGym_svg__a">
        <path fill="#fff" d="M0 0h160v160H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEyxoGym;
