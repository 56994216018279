import * as React from 'react';

const SvgAbstractGrayCirclesGroup = (props) => (
  <svg viewBox="0 0 351 678" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.625 503.082C.625 599.687 78.94 678 175.544 678c96.604 0 174.918-78.313 174.918-174.918s-78.314-174.918-174.918-174.918C78.939 328.164.625 406.477.625 503.082Z"
      fill="#CBCFCE"
    />
    <path
      d="M52.364 215.377a167.71 167.71 0 0 0-6.837 7.257l245.937 245.913c2.468-2.197 4.937-4.468 7.257-6.838 2.321-2.37 4.937-5.085 7.233-7.702L60.066 208.144a175.646 175.646 0 0 0-7.702 7.233ZM1.907 324.731a175.98 175.98 0 0 0 .124 29.079l158.256 158.231a172.93 172.93 0 0 0 29.054 0L1.907 324.731ZM162.484 164.921l186.743 186.742c.732-9.732.649-19.509-.247-29.227L191.711 165.167a175.105 175.105 0 0 0-29.227-.246ZM341.575 285.877a174.429 174.429 0 0 0-113.28-113.28l113.28 113.28ZM123.976 504.933 9.141 390.098a174.28 174.28 0 0 0 114.835 114.835ZM94.429 184.347a169.233 169.233 0 0 0-18.02 10.936L318.79 437.665a169.093 169.093 0 0 0 10.837-17.995l-235.2-235.323ZM137.084 168.623a176.576 176.576 0 0 0-22.488 6.69l224.165 224.115a176.572 176.572 0 0 0 6.689-22.488L137.084 168.623ZM12.548 276.991a174.957 174.957 0 0 0-6.764 22.439L214.67 508.29a177.396 177.396 0 0 0 22.439-6.764L12.548 276.991ZM32.617 238.977a177.28 177.28 0 0 0-10.984 17.946l235.569 235.569a175.802 175.802 0 0 0 17.971-10.985L32.617 238.977ZM271.297 28.363l49.864 49.864a176.341 176.341 0 0 0-49.864-49.864ZM219.063 5.257 344.266 130.36c-.889-3.431-1.852-6.837-2.962-10.219L229.283 8.12a258.461 258.461 0 0 0-10.22-2.863ZM184.703 0l164.822 164.921c-.148-2.617-.321-5.233-.593-7.85L192.552.691A126.27 126.27 0 0 0 184.703 0ZM156.412.815l192.296 192.297c.247-2.197.445-4.419.618-6.641L163.077.198a277.75 277.75 0 0 0-6.665.617ZM131.801 5.332l212.291 212.291c.494-1.95.988-3.9 1.407-5.85L137.577 3.826c-1.876.642-3.826 1.012-5.776 1.506ZM109.931 12.565l227.102 227.103a153.226 153.226 0 0 0 2.024-5.258L115.238 10.59c-1.827.593-3.579 1.284-5.307 1.975Z"
      fill="#CBCFCE"
    />
    <path
      d="m90.183 22.044 237.223 237.198c.864-1.555 1.728-3.135 2.469-4.715L94.824 19.477a171.283 171.283 0 0 1-4.641 2.567ZM72.583 33.45l243.493 243.493a164.498 164.498 0 0 0 3.036-4.246L76.828 30.438c-1.456.963-2.838 1.975-4.245 3.012ZM56.685 46.655 302.87 292.84c1.161-1.259 2.321-2.468 3.456-3.801L60.561 43.199c-1.358 1.136-2.617 2.296-3.876 3.456ZM42.491 61.589 287.96 307.032a163.016 163.016 0 0 0 3.876-3.382L45.75 57.689c-1.037 1.283-2.148 2.567-3.259 3.9ZM30.026 78.227l241.272 241.296c1.456-.962 2.913-1.95 4.344-2.962L32.964 73.882c-1.012 1.432-2.025 2.888-2.938 4.345ZM19.386 96.715l233.422 233.422c1.604-.815 3.209-1.63 4.813-2.469L21.879 91.926c-.815 1.58-1.679 3.184-2.493 4.789ZM10.844 117.279l221.425 221.4a114.635 114.635 0 0 0 5.332-1.925L12.77 111.922c-.667 1.778-1.309 3.629-1.926 5.357ZM4.676 140.234l204.639 204.64c1.999-.395 3.999-.815 5.974-1.284L5.959 134.261c-.444 1.95-.888 3.949-1.283 5.973ZM1.562 166.229l181.756 181.732c2.271-.099 4.542-.247 6.813-.445L2.006 159.392a156.353 156.353 0 0 0-.444 6.837ZM2.871 196.64l150.011 150.011c2.715.346 5.431.642 8.146.864L2.007 188.494c.222 2.716.518 5.431.864 8.146ZM12.55 235.447l101.554 101.529c3.628 1.358 7.282 2.469 10.985 3.703L8.723 224.437c1.234 3.703 2.469 7.356 3.826 11.01Z"
      fill="#CBCFCE"
    />
  </svg>
);

export default SvgAbstractGrayCirclesGroup;
