import * as React from 'react';

const SvgClose = (props) => (
  <svg viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="#1A1A1A" d="m2 0 49.497 49.497-1.414 1.415L.586 1.414z" />
    <path fill="#1A1A1A" d="M1 49.498 50.497.001l1.415 1.414L2.414 50.912z" />
  </svg>
);

export default SvgClose;
