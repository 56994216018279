/* eslint-disable max-len */
import React from 'react';

import Title from 'components/atoms/title';
import Typography from 'components/atoms/typography';

export const MenuInfo = {
  list: [
    { url: '/about', title: 'Sobre a Eyxo' },
    { url: '/cases', title: 'Cases' },
    { url: '/work-with-us', title: 'Trabalhe Conosco' },
    { url: '/contact-us', title: 'Contato' },
  ]
};

export const FooterWorkAttrs = {
  id: 'footer-work-form',
  title: "INSCREVA-SE",
  description: <>Quer fazer a diferença junto com a gente?<br/>Vamos adorar conhecer você!</>,
  button: "VENHA FAZER PARTE",
  buttonUrl: "/work-with-us",
};

export const FooterInfo = {
  title: 'Contato',
  description: 'Vamos iniciar uma nova história?',
  button: 'Entre em contato',
  buttonUrl: '/contact-us',
  list: [
    {
      iconName: 'Instagram',
      link: 'https://www.instagram.com/somoseyxo/'
    },
    {
      iconName: 'Linkedin',
      link: 'https://www.linkedin.com/company/eyxo-estrat%C3%A9gias-de-inova%C3%A7%C3%A3o/'
    },
  ],
  modalDescription: <>
    <Title level="3">POLÍTICA DE PRIVACIDADE DA EYXO</Title>
    <Typography>
      Nós, da Eyxo Estratégias de Inovação LTDA. (referida como “Eyxo”, “nós” ou “nossa”), estamos comprometidos em resguardar e respeitar a sua privacidade, assim como proteger os seus dados pessoais. Esta política de privacidade informa como coletamos, tratamos e protegemos os seus dados quando você visita nosso website ou é impactado por nossas comunicações; e fala sobre direitos de privacidade.<br/>
      <strong>Para a Eyxo, a sua privacidade é muito importante. Por isso, tomamos todas as medidas para protegê-la.</strong>
    </Typography>
    <Title level="3">OBJETIVO DA POLÍTICA</Title>
    <Typography>
      Esta Política de Privacidade tem a intenção de informar como a Eyxo coleta e processa seus dados pessoais através do acesso ao nosso website, incluindo dados que você pode nos dar através da navegação ou então quando você se inscreve na nossa newsletter (Eyxonews). Para oferecer nossos serviços e estabelecer uma comunicação com você, a Eyxo recolhe somente dados e informações que são necessários para as finalidades informadas pela Eyxo e o nosso intuito é, sobretudo, de trazer uma experiência cada vez melhor para o nosso público. Este documento foi escrito da forma mais acessível possível para que você saiba de forma fácil como nós utilizamos os seus dados e sinta-se tranquilo para utilizar os nossos serviços.
    </Typography>
    <Title level="3">LINKS DE TERCEIROS</Title>
    <Typography>
      Se você clicar em links de terceiros, plug-ins e aplicações, você estabelecerá conexões fora da esfera de atuação da Eyxo. A Eyxo não compartilha seus dados pessoais com esses terceiros e também não controla estes terceiros ou suas ações, e por isso não se responsabiliza pelas suas políticas de privacidade.
    </Typography>
    <Title level="3">DADOS QUE COLETAMOS SOBRE VOCÊ</Title>
    <Typography>
      A Eyxo coleta somente os dados que você nos fornece e que são necessários, de acordo com as finalidades informadas pela Eyxo. Eles podem ser:
      <ul>
        <li><strong>Dados pessoais:</strong> primeiro nome, nome do meio, sobrenome, nome de usuário, e-mail, cargo e organização na qual você trabalha.</li>
        <li><strong>Documentos:</strong> anexos enviados na aba “vagas” do site.</li>
      </ul>
      Nós também coletamos “aggregated data” como estatísticas e demográficos para finalidades técnicas sem identificar a titularidade de tais dados. Utilizamos estes dados para estatísticas como, por exemplo, do acesso nas nossas redes sociais ou newsletter.<br/>
      Não é de nosso interesse coletar dados de categorias especiais como raça, etnia, religião, crenças, orientação sexual, opinião política, informações sobre saúde e genética ou dados biométricos, entre outros. Todavia, se você tiver interesse em compartilhar com a Eyxo dados pessoais em respeito à diversidade, equidade e inclusão, a Eyxo tratara esses dados respeitando a sua privacidade e respeitando os preceitos da LGPD, bem como observando as medidas de segurança física e digital adequadas.<br/>
      Nós não coletamos nenhuma informação sobre ofensas ou convicções criminais.
    </Typography>
    <Title level="3">COMO SEUS DADOS PESSOAIS SÃO COLETADOS</Title>
    <Typography>
      Nós utilizamos diferentes meios para coletar dados:
      <ul>
        <li><strong>Interações diretas:</strong> você pode nos fornecer a sua identidade e contato ao preencher formulários ou entrando em contato conosco por e-mail, telefone, mensagem ou outros meios. Isso inclui dados pessoais fornecidos quando:
          <ul>
            <li>você contrata um serviço nosso;</li>
            <li>você fornece informações para participar de um processo seletivo nosso;</li>
            <li>você se inscreve na nossa newsletter;</li>
            <li>você manda uma mensagem de formulário no nosso site;</li>
            <li>você nos traz um feedback por algum canal de comunicação.</li>
          </ul>
        </li>
        <li><strong>Tecnologias automatizadas ou interações:</strong> quando você interage com o nosso website, coletamos dados técnicos sobre o seu equipamento, browser e padrões.</li>
        <li><strong>Dados de terceiros ou dados públicos:</strong> nós podemos receber dados pessoais seus, advindos de diferentes terceiros, como por exemplo:
          <ul>
            <li>provedores de analytics, como por exemplo o Google;</li>
            <li>empresas de publicidade;</li>
            <li>informações de buscadores.</li>
          </ul>
        </li>
        <li><strong>Acesso ao site::</strong> quando você visita o nosso site, pode ser inserido um “cookie” no navegador através do Google Analytics, com o intuito de saber quantas vezes você visitou o nosso website. São recolhidas informações como sua localização, tipo de navegador, tempo de visita, páginas visitadas e endereço IP.</li>
      </ul>
    </Typography>
    <Title level="3">COMO UTILIZAMOS OS SEUS DADOS PESSOAIS</Title>
    <Typography>
      Nós utilizamos os seus dados pessoais somente quando a lei nos permite e, quando requisito de lei, após obter o seu consentimento. Geralmente, utilizamos os seus dados nas seguintes ocasiões:
      <ul>
        <li>Quando é necessário por interesses legítimos e quando os seus interesses fundamentais não se sobrepõem a estes interesses.</li>
        <li>Quando precisamos compilar os dados com fins regulatórios ou legais.</li>
      </ul>
    </Typography>
    <Title level="3">RAZÕES PARA UTILIZARMOS OS SEUS DADOS PESSOAIS</Title>
    <Typography>
      Segue uma descrição das formas que planejamos utilizar os seus dados. Também identificamos o que são interesses legítimos e formas apropriadas de utilização:
      <ul>
        <li>Para estarmos em contato com você (e-mail, nome, organização e cargo) - envio de newsletter, convite para participar de pesquisas, notificar você sobre mudanças na nossa privacidade.</li>
        <li>Para entregar conteúdos relevantes e publicidades que possamos medir e entender a efetividade delas para você.</li>
        <li>Para melhorar o nosso site, newsletter e outros produtos/serviços, marketing, relação com clientes e experiências.</li>
      </ul>
    </Typography>
    <Title level="3">MARKETING</Title>
    <Typography>
      Se você fornecer o seu consentimento, você pode receber nossas comunicações de marketing.<br/>
      <strong>Opting out:</strong>Você pode nos pedir para parar de receber mensagens de marketing nossas a qualquer momento, seguindo opt-out links ou qualquer mensagem de marketing enviada para você ou nos contatando a qualquer momento no endereço contato@eyxo.com.br.<br/>
      <strong>Finalidade do tratamento:</strong> nós utilizaremos os seus dados somente para finalidade determinada e informada prévio consentimento, exceto quando necessário para cumprimento de requisitos de lei ou para cumprir os requisitos do Sistema B.<br/>
    </Typography>
    <Title level="3">SEGURANÇA DOS DADOS</Title>
    <Typography>
      Nós tomamos as medidas de segurança apropriadas para prevenção de vazamentos. Além disso, limitamos o acesso aos dados pessoais para os funcionários, agentes ou terceiros a somente o que for estritamente necessário à execução das finalidades. Eles somente vão processar os seus dados pessoais com nossas instruções e sabem que estão sujeitos a confidencialidade.<br/>
      Nenhum dado pessoal poderá ser divulgado publicamente e todas as nossas informações são protegidas com senha. Isso é importante para evitar acessos não autorizados. Lembramos que não existem métodos de transmissão ou armazenamento na internet completamente seguros; portanto, não podemos garantir absoluta segurança, porém tomamos as medidas adequadas, de acordo com uma avaliação dos riscos.
    </Typography>
    <Title level="3">ARMAZENAMENTO DE DADOS</Title>
    <Typography>
      Nós armazenamos os seus dados pelo tempo necessário para cumprir com os propósitos que os coletamos, inclusive com propósitos legais. Para determinar o tempo necessário, considerarmos o tempo, natureza, potencial risco e aplicações legais.
    </Typography>
    <Title level="3">ATUALIZAÇÃO</Title>
    <Typography>
      A nossa Política de Privacidade pode eventualmente passar por atualizações. Por isso, recomendamos a visita com frequência desta página. Caso seja necessário fazer alterações relevantes, que necessitem de outras autorizações da sua parte, publicaremos um novo documento de política de privacidade.
    </Typography>
    <Title level="3">RESTRIÇÃO</Title>
    <Typography>
      A Eyxo é uma empresa de comunicação que trabalha com clientes de diferentes tamanhos e segmentos. Esta política se restringe às comunicações que utilizem a marca da Eyxo e não as de seus clientes que são de responsabilidade destes.
    </Typography>
    <Title level="3">LEI APLICÁVEL</Title>
    <Typography>
      Este documento é regido e deve ser interpretado de acordo com as leis da República Federativa do Brasil. Fica eleito o Foro da Comarca de Porto Alegre, Rio Grande do Sul, como competente para dirimir eventuais questões oriundas do presente documento, com expressa renúncia a qualquer outro.
    </Typography>
    <Title level="3">RESGUARDO E PROTEÇÃO DOS NOSSOS DIREITOS</Title>
    <Typography>
      Reservamo-nos o direito de acessar, ler, preservar e divulgar quaisquer Dados que acreditamos serem necessários para cumprir uma obrigação legal ou uma ordem judicial; fazer cumprir esta Política de Privacidade e outros acordos; ou proteger os direitos, propriedade ou segurança da Eyxo, nossos funcionários, usuários ou outros.
    </Typography>
    <Title level="3">SEUS DIREITOS</Title>
    <Typography>
      Você sempre poderá optar em não divulgar seus Dados Pessoais para a Eyxo, mas tenha ciência que alguns desses Dados podem ser necessários para efetivamente usufruir de nossos serviços. Independentemente disso, você sempre possuirá direitos relativos à privacidade e à proteção dos seus Dados Pessoais. Além de nos preocuparmos com a segurança desses Dados, também nos preocupamos que você tenha acesso e conhecimento de todos os direitos relativos ao tratamento de seus Dados Pessoais.<br/>
      Nesse sentido, expomos abaixo uma breve explicação e exemplos sobre alguns desses direitos:
      <ul>
        <li><strong>Requisição de acesso aos seus Dados Pessoais</strong> - Este direito permite que você possa requisitar e receber uma cópia dos seus Dados Pessoais que possuímos sobre você.</li>
        <li><strong>Requisição de retificação dos seus Dados Pessoais</strong> - Este direito permite que você, a qualquer momento, solicite a correção e/ou retificação dos seus Dados Pessoais, caso você identifique que alguns deles estão incorretos. Contudo, para ser efetivada essa correção, possivelmente, precisaremos checar a validade dos Dados que você nos fornece.</li>
        <li><strong>Requisição de exclusão ou cancelamento dos seus Dados Pessoais</strong> - Este direito permite que você solicite a exclusão dos seus Dados Pessoais da nossa base de dados. Todos os Dados coletados serão excluídos de nossos servidores quando você assim requisitar ou quando estes não forem mais necessários ou relevantes para lhe oferecermos os nossos serviços, salvo se houver qualquer outra razão para a sua manutenção, como eventual obrigação legal de retenção de Dados ou necessidade de preservação destes para resguardo de nossos direitos.</li>
        <li><strong>Direito de objeção ao tratamento de Dados Pessoais</strong> - Você também tem o direito de contestar onde e em que contexto estamos tratando seus Dados Pessoais para diferentes finalidades. Em determinadas situações, podemos demonstrar que temos motivos legítimos para tratar seus Dados, os quais se sobrepõem aos seus direitos.</li>
        <li><strong>Direito de revogar o consentimento a qualquer momento</strong> - Você tem o direito de revogar o seu consentimento, no entanto, isso não afetará a legalidade de qualquer processamento realizado antes de você retirar o seu consentimento. Se você retirar o seu consentimento, talvez não possamos fornecer determinados serviços. E se for este o caso, iremos avisá-lo quando isso ocorrer. Talvez seja necessário solicitar informações específicas suas para nos ajudar a confirmar sua identidade e garantir seu direito de acessar seus Dados Pessoais (ou de exercer seus outros direitos). Esta é uma medida de segurança para garantir que os Dados Pessoais não sejam divulgados a qualquer pessoa que não tenha direito de recebê-los.</li>
      </ul>
      Tentamos responder a todas as solicitações legítimas dentro de 20 (vinte) dias. Ocasionalmente, esse prazo poderá ser estendido se sua solicitação for particularmente complexa ou se você tiver feito várias solicitações. Neste caso, poderá ser solicitada atualização sobre o andamento de sua solicitação ou você poderá ser contatado para obtermos mais informações em relação sua solicitação e acelerarmos nossa resposta.<br/>
      Para exercer quaisquer destes direitos você poderá nos contatar por meio dos canais disponíveis nesta “Política”.
    </Typography>
    <Title level="3">CANAIS DE CONTATO</Title>
    <Typography>
      O usuário poderá esclarecer qualquer dúvida em relação à presente “Política”, bem como solicitar quaisquer de seus direitos, por meio:<br/>
      <strong>Correio eletrônico:</strong> contato@eyxo.com.br<br/>
      <strong>Correio postal:</strong> Avenida Doutor Nilo Peçanha, 1500, Salas 18 a 22, Bairro Boa Vista – CEP
      91.330-002, Porto Alegre/RS.
    </Typography>

  </>
};