import * as React from 'react';

const SvgEyxoPlace = (props) => (
  <svg viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#EyxoPlace_svg__a)">
      <path
        d="M80.004 0a80.005 80.005 0 1 0-.079 160.01A80.005 80.005 0 0 0 80.005 0Zm0 158.888A78.89 78.89 0 0 1 2.616 64.61a78.893 78.893 0 1 1 77.388 94.278Z"
        fill="#1A1A1A"
        stroke="#000"
      />
      <path
        d="M68.484 60.565h-2.223v2.223h2.223v-2.223ZM61.824 60.565h-2.223v2.223h2.223v-2.223ZM95.028 65.7v2.155h9.809v38.654h2.214V65.7H95.028Z"
        fill="#000"
      />
      <path d="M52.94 106.509h2.223v-50.48h37.641v50.48h2.224V53.874H52.939v52.635Z" fill="#000" />
      <path
        d="M68.484 67.225h-2.223v2.223h2.223v-2.223ZM61.824 67.225h-2.223v2.223h2.223v-2.223ZM88.347 60.565h-2.223v2.223h2.224v-2.223ZM88.347 67.225h-2.223v2.223h2.224v-2.223ZM81.687 60.565h-2.223v2.223h2.223v-2.223ZM81.687 67.225h-2.223v2.223h2.223v-2.223ZM68.484 73.886h-2.223v2.223h2.223v-2.223ZM61.824 73.886h-2.223v2.223h2.223v-2.223ZM68.484 80.546h-2.223v2.223h2.223v-2.223ZM61.824 80.546h-2.223v2.223h2.223v-2.223ZM88.347 73.886h-2.223v2.223h2.224v-2.223ZM88.347 80.546h-2.223v2.223h2.224v-2.223ZM81.687 73.886h-2.223v2.223h2.223v-2.223ZM81.687 80.546h-2.223v2.223h2.223v-2.223ZM68.484 87.207h-2.223v2.224h2.223v-2.224ZM61.824 87.207h-2.223v2.224h2.223v-2.224ZM68.484 93.867h-2.223v2.224h2.223v-2.224ZM61.824 93.867h-2.223v2.224h2.223v-2.224ZM88.347 87.207h-2.223v2.224h2.224v-2.224ZM88.347 93.867h-2.223v2.224h2.224v-2.224ZM81.687 87.207h-2.223v2.224h2.223v-2.224ZM81.687 93.867h-2.223v2.224h2.223v-2.224Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="EyxoPlace_svg__a">
        <path fill="#fff" d="M0 0h160v160H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEyxoPlace;
