import * as React from 'react';

const SvgAbstractCircle = (props) => (
  <svg viewBox="0 0 188 188" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.267 42.27 42.134 15.32a94.908 94.908 0 0 0-26.84 26.949h-.027ZM2.83 70.472 70.224 2.787c-1.847.48-3.68 1-5.501 1.6L4.425 64.898a107.768 107.768 0 0 0-1.595 5.574ZM0 89.034 88.72 0c-1.409.08-2.817.187-4.225.32L.319 84.793C.186 86.207.079 87.62 0 89.033ZM.438 104.328 103.961.44a108.56 108.56 0 0 0-3.588-.32L.106 100.728c.093 1.2.213 2.4.332 3.6ZM2.87 117.609 117.142 2.934l-3.149-.76L2.06 114.501c.279 1 .544 2.054.81 3.107ZM6.763 129.463 129.007 6.787a81.45 81.45 0 0 0-2.83-1.093L5.7 126.596c.32.947.678 1.894 1.063 2.867ZM11.866 140.037l127.678-128.13c-.837-.48-1.687-.946-2.538-1.333L10.484 137.464c.438.88.903 1.733 1.382 2.573ZM18.004 149.598l131.067-131.53a87.8 87.8 0 0 0-2.285-1.627L16.383 147.305c.505.826 1.063 1.533 1.621 2.293ZM25.113 158.185 157.628 25.202c-.677-.627-1.328-1.254-2.046-1.867L23.252 156.132c.612.693 1.237 1.333 1.861 2.053ZM33.152 165.853 165.27 33.269a66.354 66.354 0 0 0-1.821-2.107L31.053 164.026c.69.627 1.395 1.24 2.1 1.827ZM42.134 172.6 172.02 42.257a91.93 91.93 0 0 0-1.595-2.334L39.809 171c.73.546 1.515 1.133 2.325 1.6ZM52.06 178.334l125.606-126.09c-.439-.867-.877-1.733-1.329-2.587L49.456 176.987c.877.48 1.74.907 2.604 1.347ZM63.128 182.947 182.303 63.351a57.911 57.911 0 0 0-1.036-2.893L60.245 181.907c.957.374 1.913.72 2.883 1.04ZM75.472 186.281 185.625 75.739a86.65 86.65 0 0 0-.691-3.227L72.257 185.588c1.063.253 2.14.48 3.215.693ZM89.477 187.961 187.3 89.793c0-1.24-.133-2.466-.239-3.693L85.797 187.72c1.222.107 2.445.187 3.68.24ZM105.914 187.254l80.747-81.033c.186-1.466.346-2.933.465-4.4l-85.597 85.9c1.395-.12 2.857-.28 4.385-.467ZM126.735 182.027l54.651-54.844c.731-1.96 1.395-4 1.993-5.947l-62.57 62.791c1.993-.6 3.973-1.226 5.926-2Z"
      fill="#1D1D1B"
    />
  </svg>
);

export default SvgAbstractCircle;
